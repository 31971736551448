import type { FC } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContainer } from "../hooks/auth";

const AuthGuard: FC = (props) => {
  const { children } = props;
  const auth = AuthContainer.useContainer();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
