import { FC, useState } from "react";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import { styled } from "@mui/material/styles";
import { SharedStylesRoot } from "../../theme/sharedStyles";
import DashboardOverviewContent from "./components/DashboardOverviewContent";
import { useGetDashboardDataQuery, useGetOwnUserQuery } from "../../graphql/generated/generatedGraphQL";
import useUserContext from "../../hooks/useUserContext";

const classes = {
  page: "dashboard-page",
};

const StyledPageRootDashboard = styled(SharedStylesRoot)(() => ({
  [`& .${classes.page}`]: {
    paddingTop: 0,
    paddingLeft: 23,
    paddingRight: 23,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
}));

const nowPlusDays = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

const Overview: FC = () => {
  const userContext = useUserContext();
  const [from] = useState(nowPlusDays(-7));
  const [to] = useState(nowPlusDays(1));
  const mavIds = userContext.inspectingTenant ? [userContext.inspectingTenant] : [];
  const queryResults = {
    user: useGetOwnUserQuery(),
    dashboard: useGetDashboardDataQuery({
      mavIds: mavIds,
      from: from,
      to: to,
    }),
  };

  return (
    <StyledPageRootDashboard>
      <Scrollbar data-testid="overview-scrollbar">
        <Page Content={DashboardOverviewContent} queryResults={queryResults} className={classes.page} />
      </Scrollbar>
    </StyledPageRootDashboard>
  );
};

export default Overview;
