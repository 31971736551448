import { FC } from "react";
import { FormControl, InputLabel, Select as MaterialUiSelect, SelectProps as MuiSelectProps } from "@mui/material";
import { FormikProps, getIn } from "formik";

type SelectFieldProps<FormValues> = MuiSelectProps & {
  formik: FormikProps<FormValues>;
  label: string;
  name: string;
};

const SelectField = <FormValues,>(props: SelectFieldProps<FormValues>): ReturnType<FC> => {
  const { formik, label, name, children, ...rest } = props;
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <MaterialUiSelect
        label={label}
        name={name}
        variant="outlined"
        value={getIn(formik.values, name)}
        onChange={formik.handleChange}
        error={getIn(formik.touched, name) && !!getIn(formik.errors, name)}
        {...rest}
      >
        {children}
      </MaterialUiSelect>
    </FormControl>
  );
};

export default SelectField;
