import { Button, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyledClientDownloadCard } from "./ClientDownloadCard";

interface ClientDownloadCardProps {
  title: string;
  subtitle: string;
  icon: ReactNode;
  description: string;
  // eslint-disable-next-line react/no-unused-prop-types
  downloadUrl: string | undefined;
}

const RequestClientDownloadCard: FC<ClientDownloadCardProps> = ({ title, subtitle, icon, description }) => {
  const { t } = useTranslation();
  return (
    <StyledClientDownloadCard>
      <CardHeader sx={{ pt: 2, pb: 0 }} title={title} titleTypographyProps={{ variant: "h3", align: "center" }} />
      <CardContent sx={{ p: 4, pt: 1 }}>
        {icon}
        <Typography align="center" variant="body2" fontWeight={700} sx={{ pt: 4 }}>
          {subtitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ pt: 2 }} style={{ whiteSpace: "pre-line" }}>
          {description}
        </Typography>
      </CardContent>
      <div style={{ flexGrow: 1 }} />
      <CardActions sx={{ p: 4, pt: 0 }}>
        <Button disabled startIcon={<GetAppIcon />} variant="contained" href="#">
          {t("Clients.Download")}
        </Button>
      </CardActions>
    </StyledClientDownloadCard>
  );
};

export default RequestClientDownloadCard;
