import { MenuItem } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionTemplateViewModel } from "../../../types/RecommendedActionTemplate";
import useDeleteTemplate from "../hooks/useDeleteTemplate";

type DeleteProps = ActionMenuItemsProps<RecommendedActionTemplateViewModel>;

const Delete = ({ selectedFlatRows, onClose }: DeleteProps): ReturnType<VFC> => {
  const { t } = useTranslation();
  const deleteTemplate = useDeleteTemplate();
  const disabled =
    selectedFlatRows.length === 0 ||
    selectedFlatRows.filter((item) => !item.original.accessRights.canDelete).length !== 0;
  return (
    <MenuItem
      data-testid="recommendedActionTemplates-DeleteButton"
      key={"EnhancedTable.Delete"}
      disabled={disabled}
      onClick={() => {
        onClose();
        if (selectedFlatRows.length) {
          const deleteTemplateInput = { id: selectedFlatRows.map((x) => x.original.id) };
          deleteTemplate(deleteTemplateInput);
        }
      }}
    >
      {t("EnhancedTable.Delete")}
    </MenuItem>
  );
};

export default Delete;
