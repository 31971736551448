import { FC, ReactNode } from "react";
import { InputAdornment, TextField as MaterialUiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { FormikProps, getIn } from "formik";

type TextFieldProps<FormValues> = MuiTextFieldProps & {
  formik: FormikProps<FormValues>;
  name: string;
  icon?: ReactNode;
  dataTestid?: string;
  hideHelperText?: boolean;
};

const TextField = <FormValues,>(props: TextFieldProps<FormValues>): ReturnType<FC> => {
  const { formik, type, name, icon, dataTestid, hideHelperText, ...rest } = props;
  return (
    <MaterialUiTextField
      fullWidth
      name={name}
      type={type ?? "text"}
      variant="outlined"
      value={getIn(formik.values, name)}
      onChange={formik.handleChange}
      error={getIn(formik.touched, name) && !!getIn(formik.errors, name)}
      helperText={!hideHelperText && getIn(formik.touched, name) && getIn(formik.errors, name)}
      inputProps={{ "data-testid": dataTestid }}
      // Yes, there are really 2 props that differ only in casing...
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={
        icon
          ? {
              endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
            }
          : undefined
      }
      {...rest}
    />
  );
};

export default TextField;
