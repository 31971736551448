import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  recommendedActionsTableClasses,
  GoodJobImage,
} from "../../recommendedActions/components/RecommendedActionsTableStyles";

const AllRecommendedActionsSolved = () => {
  const { t } = useTranslation();
  return (
    <Box className={recommendedActionsTableClasses.statusGrid}>
      <GoodJobImage />
      <Typography variant="h6" align="center">
        {t("Dashboard.AllDone")}
      </Typography>
      <Typography maxWidth="50%" variant="body2" align="center" color="textSecondary">
        {t("Dashboard.AllDoneDescription")}
      </Typography>
    </Box>
  );
};
export default AllRecommendedActionsSolved;
