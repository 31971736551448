import React, { VFC } from "react";
import { Row } from "react-table";
import UnknownObject from "../../types/UnknownObject";
import { styled } from "@mui/material/styles";

const InRowMenuRoot = styled("div")(() => ({
  width: "60%",
  textAlign: "center",
  whiteSpace: "nowrap",
}));

export type InRowMenuItemsProps<T extends UnknownObject> = {
  row: Row<T>;
};

export type InRowMenuProps<T extends UnknownObject> = InRowMenuItemsProps<T> & {
  isLoading?: boolean;
  Items: VFC<InRowMenuItemsProps<T>>;
};

interface MenuItemProps<T extends UnknownObject> {
  Items: VFC<InRowMenuItemsProps<T>>;
  row: Row<T>;
}

const InRowMenu = <T extends UnknownObject>({ Items, row }: MenuItemProps<T>): ReturnType<VFC> => {
  return (
    <InRowMenuRoot>
      <Items row={row} />
    </InRowMenuRoot>
  );
};

export default InRowMenu;
