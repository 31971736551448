import { FC } from "react";
import { UseQueryResult } from "react-query";
import { ErrorAlert, NoDataAlert } from "./ErrorAlert";

const EnsureLoadingOrData: FC<{ queryResult: UseQueryResult<unknown> }> = ({ queryResult, children }) => {
  if (!queryResult.isLoading && !queryResult.data) {
    return (
      <>
        {queryResult.error ? <ErrorAlert retry={queryResult.refetch} /> : <NoDataAlert retry={queryResult.refetch} />}
      </>
    );
  }

  return <>{children}</>;
};

export default EnsureLoadingOrData;
