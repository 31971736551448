import { VFC } from "react";
import { Priority } from "../graphql/generated/generatedGraphQL";
import { IRecommendedActionTemplatesTableFilter } from "../pages/recommendedActionTemplates/hooks/useTableFilter";
import { IRecommendedActionTableFilter } from "../pages/recommendedActionsAnalyst/hooks/useTableFilter";
import { useTranslation } from "react-i18next";
import StyledToggleButtonWithCheckIcon from "./CheckedStyledToggleButton";
import { PriorityIcon } from "../pages/recommendedActions/components/RecommendedActionsTableStyles";
import { styled } from "@mui/material";
import { Box } from "@mui/system";

const StyledPriorityContainer = styled(Box, { name: "StyledPriorityContainer" })(() => ({
  marginLeft: "4px",
  height: "20px",
  width: "20px",
  display: "inline-block",
  verticalAlign: "baseline",
  svg: {
    height: "20px",
    width: "20px",
    verticalAlign: "middle",
  },
}));

type PriorityToggleButtonsProps = { filter: IRecommendedActionTableFilter | IRecommendedActionTemplatesTableFilter };
const PriorityToggleButtons: VFC<PriorityToggleButtonsProps> = ({ filter }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledToggleButtonWithCheckIcon
        value="high"
        selected={filter.wherePriority.HIGH}
        onChange={() => filter.includePriority(Priority.High, !filter.wherePriority.HIGH)}
        containerclassname="priority"
      >
        {t("RecommendedActions.Priorities.HIGH")}
        <StyledPriorityContainer>
          <PriorityIcon value={"High"} />
        </StyledPriorityContainer>
      </StyledToggleButtonWithCheckIcon>
      <StyledToggleButtonWithCheckIcon
        value="medium"
        selected={filter.wherePriority.MEDIUM}
        onChange={() => filter.includePriority(Priority.Medium, !filter.wherePriority.MEDIUM)}
        containerclassname="priority"
      >
        {t("RecommendedActions.Priorities.MEDIUM")}
        <StyledPriorityContainer>
          <PriorityIcon value={"Medium"} />
        </StyledPriorityContainer>
      </StyledToggleButtonWithCheckIcon>
      <StyledToggleButtonWithCheckIcon
        value="low"
        selected={filter.wherePriority.LOW}
        onChange={() => filter.includePriority(Priority.Low, !filter.wherePriority.LOW)}
        containerclassname="priority"
      >
        {t("RecommendedActions.Priorities.LOW")}
        <StyledPriorityContainer>
          <PriorityIcon value={"Low"} />
        </StyledPriorityContainer>
      </StyledToggleButtonWithCheckIcon>
    </>
  );
};

export default PriorityToggleButtons;
