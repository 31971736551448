import { useState, VFC } from "react";
import { Divider, IconButton, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ThumbUpAltSharpIcon from "@mui/icons-material/ThumbUpAltSharp";
import UndoSharpIcon from "@mui/icons-material/UndoSharp";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import useResolveRecommendedAction from "../../pages/recommendedActions/hooks/useResolveRecommendedAction";
import { RecommendedActionViewModel } from "../../types/RecommendedAction";
import { InRowMenuItemsProps } from "./InRowMenu";
import DoneIcon from "@mui/icons-material/Done";
import useComponentBehavior from "../../hooks/useComponentBehavior";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: "24px",
}));

type InRowResolveWithConfirmationProps = InRowMenuItemsProps<RecommendedActionViewModel>;

const InRowResolveWithConfirmation = ({ row }: InRowResolveWithConfirmationProps): ReturnType<VFC> => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  const resolveRecommendedAction = useResolveRecommendedAction();
  const behavior = useComponentBehavior();
  const disabled = row.original.state === "Solved" || behavior.pages.recommendedActions.inRowMenuDisableUnresolve;

  return (
    <>
      <IconButton
        data-testid="InRowResolveWithConfirmation-ResolveTableAction"
        disabled={disabled}
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleClickOpen();
        }}
        style={{ paddingInlineStart: "0px" }}
      >
        <DoneIcon fontSize="small" />
      </IconButton>
      <Dialog open={open}>
        <StyledDialogTitle>{t("ResolveDialog.HeaderMessage")}</StyledDialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>{t("ResolveDialog.DescriptionMessage")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            startIcon={<UndoSharpIcon fontSize="small" />}
          >
            {t("ResolveDialog.Revert")}
          </Button>
          <Button
            data-testid="InRowResolveWithConfirmation-ConfirmResolveTableAction"
            onClick={() => {
              handleClose();
              resolveRecommendedAction(row.original.id);
            }}
            autoFocus
            color="primary"
            variant="contained"
            startIcon={<ThumbUpAltSharpIcon fontSize="small" />}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InRowResolveWithConfirmation;
