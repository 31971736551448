import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTableDefaultMessageSubtitle, StyledTableDefaultMessageTitle } from "../../../theme/sharedStyles";

const NoRecommendedActions = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledTableDefaultMessageTitle variant="h3">
        {t("RecommendedActions.DefaultMessageTitle")}
      </StyledTableDefaultMessageTitle>
      <StyledTableDefaultMessageSubtitle variant="h4">
        {t("RecommendedActions.DefaultMessageSubTitle")}
      </StyledTableDefaultMessageSubtitle>
    </Container>
  );
};

export default NoRecommendedActions;
