import { UseQueryResult } from "react-query";
import {
  RecommendedActionsCountQuery,
  RecommendedActionsCountQueryVariables,
  useRecommendedActionsCountQuery as useQuery,
} from "../../../graphql/generated/generatedGraphQL";
import useUserContext from "../../../hooks/useUserContext";

export default function useRecommendedActionsCountQuery(
  variables?: RecommendedActionsCountQueryVariables
): UseQueryResult<RecommendedActionsCountQuery> {
  const userContext = useUserContext();

  let v = variables;

  if (userContext.inspectingTenant) {
    v = {
      ...v,
      where: { mavId: { eq: userContext.inspectingTenant } },
    };
  }

  return useQuery(v);
}
