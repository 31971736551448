import { VFC } from "react";
import { Column, SortingRule } from "react-table";
import SearchField from "../../../components/SearchField";
import StyledTableHeaderBarRightOrientation from "../../../components/StyledTableHeaderBarRightOrientation";
import EnhancedTable from "../../../components/table/EnhancedTable";
import ITablePagination from "../../../components/table/ITablePagination";
import { ClientSortInput } from "../../../graphql/generated/generatedGraphQL";
import useDebouncedInputValueHandler from "../../../hooks/useDebouncedInputValueHandler";
import { Client } from "../../../types/client";
import { IClientsTableFilter } from "../hooks/useTableFilter";

interface ContentTableProps {
  data: Client[] | undefined;
  columns: Column<Client>[];
  onSortByChanged: (sortBy: SortingRule<Client>[]) => void;
  order: ClientSortInput[];
  filter: IClientsTableFilter;
  pagination: ITablePagination;
}

type ClientsTableActionBarProps = { filter: IClientsTableFilter };

const ClientsActionBar: VFC<ClientsTableActionBarProps> = ({ filter }) => {
  const handleSearchChange = (value: string) => filter.setWhereFullTextContains(value);
  const handleSearchChangeDebounced = useDebouncedInputValueHandler(handleSearchChange);

  return (
    <StyledTableHeaderBarRightOrientation>
      <SearchField onChange={handleSearchChangeDebounced} defaultValue={filter.whereFullTextContains ?? ""} />
    </StyledTableHeaderBarRightOrientation>
  );
};

export const ClientsIndexTable: VFC<ContentTableProps> = ({
  data,
  columns,
  order,
  filter,
  pagination,
  onSortByChanged,
}) => {
  const actionBar = <ClientsActionBar filter={filter} />;
  const sortBy = [{ id: "name" }];

  return (
    <>
      {actionBar && actionBar}
      <EnhancedTable
        onSortByChanged={onSortByChanged}
        pagination={pagination}
        columns={columns}
        data={data ?? []}
        order={order}
        filter={filter}
        manualSortBy
        initialState={{ sortBy }}
      />
    </>
  );
};
