import { Grid } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IContentProps } from "../../../components/Page";
import PageTitle from "../../../components/PageTitle";
import { GetDashboardDataQuery, GetOwnUserQuery } from "../../../graphql/generated/generatedGraphQL";
import DashboardGridContent from "./DashboardGridContent";

const DashboardOverviewContent: FC<IContentProps<{ user: GetOwnUserQuery; dashboard: GetDashboardDataQuery }>> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={2} item xs={12}>
          <Grid item>
            <PageTitle
              title={t("Dashboard.WelcomeBack", {
                defaultValue: "Welcome back, {{firstName}} {{lastName}}.",
                firstName: data.user.selfUser.firstName,
                lastName: data.user.selfUser.lastName,
              })}
              subtitle={data.user.selfUser.company?.name}
            />
          </Grid>
          <Grid item>{/* Placeholder for Export-Report Button */}</Grid>
        </Grid>
      </Grid>
      <DashboardGridContent data={data.dashboard} />
    </>
  );
};

export default DashboardOverviewContent;
