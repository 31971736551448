import { FC } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Page, { IContentProps } from "../../components/Page";
import PageTitle from "../../components/PageTitle";
import {
  CreateRecommendedActionTemplateInput,
  Language,
  Priority,
  Tag,
  TemplateRecommendedActionType,
  useCreateRecommendedActionTemplateMutation,
  useRecommendedActionTemplatesWithPagingQuery,
} from "../../graphql/generated/generatedGraphQL";
import RecommendedActionTemplateForm from "./components/RecommendedActionTemplateForm";
import Scrollbar from "../../components/Scrollbar";

const initialValues: CreateRecommendedActionTemplateInput = {
  name: "",
  type: TemplateRecommendedActionType.None,
  tag: Tag.None,
  priority: Priority.Low,
  isPublic: false,
  cultureSpecifics: [
    {
      language: Language.En,
      summary: "",
      description: "",
    },
    {
      language: Language.De,
      summary: "",
      description: "",
    },
    {
      language: Language.Pl,
      summary: "",
      description: "",
    },
  ],
};

const useSubmitChanges = () => {
  const { mutateAsync } = useCreateRecommendedActionTemplateMutation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return async (templateValues: CreateRecommendedActionTemplateInput): Promise<void> => {
    const input = templateValues;
    templateValues.isPublic = JSON.parse(templateValues.isPublic.toString());
    try {
      await mutateAsync({ input });
      queryClient.invalidateQueries(useRecommendedActionTemplatesWithPagingQuery.getKey());
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), {
        variant: "error",
      });
    }
  };
};

const Title: FC = () => {
  const { t } = useTranslation();
  return (
    <PageTitle
      title={`${t("Sidebar.AnalysisAndAdministration")} > ${t("Sidebar.TemplatesOverview")} > ${t(
        "RecommendedActions.Templates.EditTemplate"
      )}`}
    />
  );
};

const Content: FC<IContentProps<unknown>> = () => {
  const submitChanges = useSubmitChanges();
  return (
    <RecommendedActionTemplateForm
      Title={Title}
      recommendedActionTemplate={initialValues}
      mutateRecommendedActionTemplate={submitChanges}
    />
  );
};

const EmptyTitle = () => <></>;

const NewTemplate: FC = () => {
  const queryResults = {};
  return (
    <Scrollbar data-testid="TemplateForm-Scrollbar">
      <Page Title={EmptyTitle} Content={Content} queryResults={queryResults} />
    </Scrollbar>
  );
};

export default NewTemplate;
