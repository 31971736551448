import { styled } from "@mui/material";

const StyledTableHeaderBar = styled("div", { name: "TableHeaderBar" })(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  display: "flex",
  flexFlow: "row wrap",
  gap: theme.spacing(2),
}));

export default StyledTableHeaderBar;
