import { Tag } from "../graphql/generated/generatedGraphQL";

const tagSortOrder: Record<Tag, number> = {
  NONE: 0,
  WELCOME: 1,
  GENERAL: 2,
};

function compareTag(a: Tag, b: Tag): number {
  return Math.sign(tagSortOrder[a] - tagSortOrder[b]);
}

export function sortTags(tags: Tag[]): Tag[] {
  return [...tags].sort((a, b) => compareTag(a, b));
}
