import type { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContainer } from "../hooks/auth";

const GuestGuard: FC = ({ children }) => {
  const { isAuthenticated } = AuthContainer.useContainer();
  const location = useLocation();

  const { from } = (location.state as { from: string }) || {
    from: { pathname: "/dashboard" },
  };

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
