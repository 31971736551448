import clsx from "clsx";
import type { FC, ReactNode } from "react";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";

interface DashboardWidgetLink {
  title: string;
  to: string;
}

interface DashboardWidgetProps {
  title?: string;
  subtitle?: string | ReactNode;
  size: "1x1" | "1x2" | "2x1" | "2x2" | "3x1" | "3x2" | "4x1";
  children: ReactNode;
  link?: DashboardWidgetLink;
  renderRawContent?: boolean;
  noContentCentering?: boolean;
  mobileRowNumber?: number;
  dataTestid?: string;
}

const PREFIX = "DashboardWidget";
const classes = {
  content: `${PREFIX}-content`,
};
const StyledCard = styled(Card)(({ theme }) => ({
  "& .MuiCardActions-root": {
    justifyContent: "flex-end",
  },
  "& .MuiCardContent-root:last-child": {
    paddingBottom: theme.spacing(2),
  },
  gridRowEnd: "span 1",
  gridColumn: "span 4",
  [theme.breakpoints.down("md")]: {
    "&.mobile-row-1": {
      gridRowStart: "1",
    },
    "&.mobile-row-2": {
      gridRowStart: "2",
    },
    "&.mobile-row-3": {
      gridRowStart: "3",
    },
  },
  [theme.breakpoints.only("sm")]: {
    "&.tile-1x1": {
      gridColumn: "span 2",
      "&.flex-sb": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
  },
  [theme.breakpoints.up("md")]: {
    height: "100%",
    "&.flex-sb": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "&.tile-1x1": {
      gridColumn: "span 1",
      gridRowEnd: "span 1",
    },
    "&.tile-2x1": {
      gridColumn: "span 2",
      gridRowEnd: "span 1",
    },
    "&.tile-2x2": {
      gridColumn: "span 2",
      gridRowEnd: "span 2",
    },
    "&.tile-1x2": {
      gridColumn: "span 1",
      gridRowEnd: "span 2",
    },
    "&.tile-3x1": {
      gridColumn: "span 3",
      gridRowEnd: "span 1",
    },
    "&.tile-3x2": {
      gridColumn: "span 3",
      gridRowEnd: "span 2",
    },
  },
  [`& .${classes.content}`]: {
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    "&.h-100": {
      height: "100%",
    },
  },
}));

const DashboardWidget: FC<DashboardWidgetProps> = ({
  children,
  link,
  title,
  subtitle,
  size,
  renderRawContent,
  noContentCentering,
  mobileRowNumber,
  dataTestid,
}) => {
  return (
    <StyledCard
      className={clsx(`tile-${size}`, link && "flex-sb", mobileRowNumber && `mobile-row-${mobileRowNumber}`)}
      data-testid={dataTestid}
    >
      {renderRawContent === true ? (
        children
      ) : (
        <>
          {title && (
            <CardHeader
              title={title}
              subheader={subtitle}
              titleTypographyProps={{ variant: "h3", align: "center" }}
              subheaderTypographyProps={{ align: "center", variant: "body2" }}
            />
          )}
          <CardContent className={clsx(classes.content, noContentCentering && "h-100")}>{children}</CardContent>
          {link && (
            <CardActions>
              <Button
                data-testid={"DashboardWidget-ActionTo-" + link.to}
                component={Link}
                color="primary"
                endIcon={<ArrowForwardIcon fontSize="small" color="primary" />}
                variant="text"
                to={link.to}
              >
                {link.title}
              </Button>
            </CardActions>
          )}
        </>
      )}
    </StyledCard>
  );
};

export default DashboardWidget;
