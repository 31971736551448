import { useCallback, useMemo, useState } from "react";
import { ClientStateSortInput, InputMaybe, SortEnumType } from "../../graphql/generated/generatedGraphQL";
import { SortingRule } from "react-table";

type GenericSortInput = Record<string, InputMaybe<SortEnumType> | undefined | InputMaybe<ClientStateSortInput>>;

export type MapColumnToField<T extends GenericSortInput> = {
  [key: string]: keyof T;
};

type ITableSortBy<TSortInput> = {
  order: TSortInput[];
  onSortByChanged: (sortBy: SortingRule<string>[]) => void;
};

const useTableSortBy = <TSortInput extends GenericSortInput>(
  mapColumnsToField: MapColumnToField<TSortInput>,
  defaultSortOrder: TSortInput[]
): ITableSortBy<TSortInput> => {
  const [state, setState] = useState<TSortInput[]>(defaultSortOrder);

  const onSortByChanged = useCallback(
    (sortBy: SortingRule<string>[]) => {
      if (sortBy.length === 0) {
        setState(defaultSortOrder);
        return;
      }

      const newOrder = sortBy.map((e) => {
        if (!mapColumnsToField.hasOwnProperty(e.id)) {
          console.warn(`useTableSortBy: Can't map column "${e.id}"`);
          return {} as TSortInput;
        }
        return {
          [mapColumnsToField[e.id]]: e.desc ? SortEnumType.Desc : SortEnumType.Asc,
        } as TSortInput;
      });
      setState(newOrder);
    },
    [mapColumnsToField, defaultSortOrder]
  );

  return useMemo(
    () => ({
      order: state,
      onSortByChanged,
    }),
    [state, onSortByChanged]
  );
};
export default useTableSortBy;
