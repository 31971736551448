import type { FC } from "react";
import { Outlet } from "react-router";
import useComponentBehavior from "../hooks/useComponentBehavior";
import EmptyState from "../pages/emptyState/EmptyState";

const SelectedTenantGuard: FC = () => {
  const behavior = useComponentBehavior();

  if (behavior.pages.dashboard.showLoginToPartnerPortal) {
    return <EmptyState />;
  }
  return <Outlet />;
};

export default SelectedTenantGuard;
