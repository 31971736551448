import {
  BrowserHistory,
  BrowserHistoryOptions,
  createBrowserHistory as createUnwrappedBrowserHistory,
  parsePath,
  To,
  Location,
} from "history";
import { getRegNumber, deleteRegNumber, setRegNumber } from "../types/URLSearchParams";

export type { BrowserHistory };

function getNextSearchParam(fromParam: string | null, toParam: string | null): string | null {
  return fromParam && toParam === null ? fromParam : toParam;
}

function toWithInvariantSearchParams(to: To, location: Location) {
  const toPath = typeof to === "string" ? parsePath(to) : to;
  const fromSearchParams = new URLSearchParams(location.search);
  const fromRegNumber = getRegNumber(fromSearchParams);
  const toSearchParams = new URLSearchParams(toPath.search);
  const toRegNumber = getRegNumber(toSearchParams);

  const regNumber = getNextSearchParam(fromRegNumber, toRegNumber);

  if (regNumber) {
    setRegNumber(toSearchParams, regNumber);
  } else {
    deleteRegNumber(toSearchParams);
  }
  toPath.search = toSearchParams.toString();
  return toPath;
}

export default function createBrowserHistory(options?: BrowserHistoryOptions): BrowserHistory {
  const history = createUnwrappedBrowserHistory(options);
  const createHref = history.createHref;
  const push = history.push;
  const replace = history.replace;

  history.createHref = (to) => createHref(toWithInvariantSearchParams(to, history.location));
  history.push = (to, state) => push(toWithInvariantSearchParams(to, history.location), state);
  history.replace = (to, state) => replace(toWithInvariantSearchParams(to, history.location), state);

  return history;
}
