import { TabPanel } from "@mui/lab";
import { Container, Paper, Tab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SharedStylesRoot = styled("div")(() => ({
  height: "100%",
}));

export const PageContainer = styled(Container)(() => ({
  height: "100%",
  paddingTop: "1.5rem",
  paddingLeft: "1.5rem",
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(7),
  overflow: "hidden",
}));

export const StyledFlexPaper = styled(StyledPaper)(() => ({
  display: "flex",
}));

export const StyledPaperFlexColumn = styled(StyledFlexPaper)(() => ({
  flexDirection: "column",
  minWidth: "300px",
}));

export const StyledPaperFlexTableMarginTop32 = styled(StyledPaperFlexColumn)(() => ({
  display: "table",
  marginTop: "32px",
}));

export const StyledPaperWidth480 = styled(Paper)(() => ({
  maxWidth: 480,
}));

export const StyledPaperWidth960 = styled(Paper)(() => ({
  maxWidth: 960,
}));

export const StyledTableDefaultMessageTitle = styled(Typography)(() => ({
  textAlign: "center",
  marginTop: "23vh",
}));

export const StyledTableDefaultMessageSubtitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: "25vh",
  color: theme.palette.text.primary,
}));

export const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
}));

export const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
  paddingTop: "16px",
  paddingBottom: "32px",
  overflow: "auto",
}));
