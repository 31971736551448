import { TFunction } from "i18next";
import * as Yup from "yup";
import { SchemaOf } from "yup";

export type LostPasswordFormValues = {
  email: string;
};

export const getValidationSchema = (t: TFunction): SchemaOf<LostPasswordFormValues> => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t("Forms.ValidEmail", "Please enter a valid e-mail address."))
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.Email"),
        })
      ),
  });
};
