import type { FC } from "react";
import { Badge, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { TFunction } from "i18next";
import { useTranslation, Trans } from "react-i18next";
import DashboardWidget from "../components/DashboardWidget";
import { styled } from "@mui/material/styles";
import { ProtectionStatistics } from "../../../graphql/generated/generatedGraphQL";

const PREFIX = "ProtectionStatisticsWidget";
const classes = {
  badge: `${PREFIX}-badge`,
};
const StyledBox = styled(Box)(() => ({
  margin: "2vw 0",
  minHeight: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const StyledTable = styled(Table)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [`& .${classes.badge}`]: {
    paddingLeft: "20px",
    "& .MuiBadge-badge": {
      left: 6,
      top: 11,
    },
  },
}));

interface ChartDataItem {
  title: string;
  color: string;
  tableValue: number;
  chartValue: number;
}

function areAllStatsZero(p: ProtectionStatistics | undefined): boolean {
  return p !== undefined ? p.blockedWebsites + p.infectedFiles + p.malwareMails === 0 : true;
}

function getChartData(protectionData: ProtectionStatistics | undefined, t: TFunction): Array<ChartDataItem> {
  let blockedWebsites = 0;
  let infectedFiles = 0;
  let malwareEmails = 0;
  let allStatsZero = true;

  if (protectionData) {
    blockedWebsites = protectionData.blockedWebsites;
    infectedFiles = protectionData.infectedFiles;
    malwareEmails = protectionData.malwareMails;
    allStatsZero = areAllStatsZero(protectionData);
  }

  /* If all values are 0, we set everything to 1 for the chart so that we still get a nice chart. */
  const newChartData: Array<ChartDataItem> = [
    {
      title: t("Dashboard.BlockWebsites", "Blocked Websites"),
      color: "#87a3ba",
      tableValue: blockedWebsites,
      chartValue: allStatsZero ? 1 : blockedWebsites,
    },
    {
      title: t("Dashboard.InfectedFiles", "Infected Files"),
      color: "#c7e4ff",
      tableValue: infectedFiles,
      chartValue: allStatsZero ? 1 : infectedFiles,
    },
    {
      title: t("Dashboard.MalwareEmails", "Malware E-Mails"),
      color: "#a3c6e4",
      tableValue: malwareEmails,
      chartValue: allStatsZero ? 1 : malwareEmails,
    },
  ];
  return newChartData;
}

function getChartOptions(chartData: Array<ChartDataItem>, showTooltip: boolean): ApexOptions {
  return {
    tooltip: {
      enabled: showTooltip,
    },
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    colors: chartData.map((a) => a.color),
    dataLabels: {
      enabled: false,
    },
    labels: chartData.map((a) => a.title),
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: "100%",
            height: "auto",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: "300px",
            height: "300px",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
              },
            },
          },
        },
      },
      {
        breakpoint: 9000,
        options: {
          chart: {
            width: "350px",
            height: "350px",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "75%",
              },
            },
          },
        },
      },
    ],
  };
}
const ProtectionStatisticsWidget: FC<{ protectionStatistics: ProtectionStatistics | undefined }> = ({
  protectionStatistics,
}) => {
  const { t } = useTranslation();

  const allStatsZero = areAllStatsZero(protectionStatistics);
  const chartData = getChartData(protectionStatistics, t);

  const chart = {
    options: getChartOptions(chartData, !allStatsZero),
    series: chartData.map((a) => a.chartValue),
  };

  return (
    <DashboardWidget
      size="2x2"
      title={t("Dashboard.AvertedThreats", "Averted threats")}
      subtitle={
        <Trans i18nKey="Dashboard.AvertedThreatsDescription">
          What threats has G DATA 365 averted for you <strong>in the last 7 days</strong>?
        </Trans>
      }
    >
      <StyledBox>
        <Chart data-testid="ProtectionStatisticsWidget-chart" height={350} width={350} type="donut" {...chart} />
      </StyledBox>

      {allStatsZero ? (
        <>
          <Typography variant="h6" align="center">
            {t("Dashboard.NoThreats", "No threats had to be averted")}
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            {t(
              "Dashboard.NoThreatsDescription",
              "G DATA 365 did not have to block any suspicious actions on your devices. The protective measures you have taken are effective."
            )}
          </Typography>
        </>
      ) : (
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Dashboard.ThreatType", "Threat type")}</TableCell>
              <TableCell align="right">{t("Common.Count", "Count")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData &&
              chartData.map((row, index) => {
                const rowId = `protection-stats-${index}`;
                return (
                  <TableRow key={rowId}>
                    <TableCell>
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        variant="dot"
                        className={classes.badge}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: row.color,
                          },
                        }}
                      >
                        {row.title}
                      </Badge>
                    </TableCell>
                    <TableCell align="right">{row.tableValue}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </StyledTable>
      )}
    </DashboardWidget>
  );
};

export default ProtectionStatisticsWidget;
