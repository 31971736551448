import { FC, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, styled } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Column, Row } from "react-table";
import Page from "../../../components/Page";
import useClientsWithPaging from "../hooks/useClientsWithPaging";
import { useSecurityClientDownloads } from "../hooks/useSecurityClientDownloads";
import { StyledTabPanel, StyledTab, StyledFlexPaper } from "../../../theme/sharedStyles";
import PageTitle from "../../../components/PageTitle";
import { compareSignatureVersion, oldestSignature, signatureUpToDate } from "../../../types/signature";
import {
  ClientTenantDataSortInput,
  SortEnumType,
  useClientsWithPagingCountQuery,
} from "../../../graphql/generated/generatedGraphQL";
import useTablePagination from "../../../components/table/useTablePagination";
import useTableFilter from "../hooks/useTableFilter";
import useTableSortBy, { MapColumnToField } from "../../../components/table/useTableSortBy";
import { Client } from "../../../types/client";
import { ClientsIndexTable } from "./ClientIndexTable";
import { SecurityClientDownloads } from "./SecurityClientDownloads";

const pageSize = 25;
const StylesRoot = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const Title: FC = () => {
  const { t } = useTranslation();
  return <PageTitle title={`${t("Sidebar.MyOverview")} > ${t("Sidebar.DeviceManagement")}`} />;
};

export const SignatureCell: FC<{ value: Client }> = ({ value }: { value: Client }) => {
  const { t } = useTranslation();
  if (signatureUpToDate(value.status.signatures)) return t("Common.UpToDate", "Up to Date");
  return t("Common.DateFormat", { date: oldestSignature(value.status.signatures) });
};

function sortTypeSignatureVersion<T extends Client>(a: Row<T>, b: Row<T>): number {
  return compareSignatureVersion(a.original.status.signatures, b.original.status.signatures);
}

function useColumns(): Column<Client>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        id: "name",
        Header: t("Clients.Name") as string,
        accessor: "name",
      },
      {
        Header: t("Clients.OsVersion") as string,
        accessor: (row) => row.status.osVersion,
        disableSortBy: true,
      },
      {
        Header: t("Clients.ClientVersion") as string,
        accessor: (row) => row.status.clientVersion,
        disableSortBy: true,
      },
      {
        Header: t("Clients.SignatureVersion") as string,
        accessor: (originalRow) => originalRow,
        Cell: SignatureCell,
        sortType: sortTypeSignatureVersion,
        disableSortBy: true,
      },
    ],
    [t]
  );
}

const defaultSortOrder: ClientTenantDataSortInput[] = [{ name: SortEnumType.Asc }];
const columnsToGraphQLFields: MapColumnToField<ClientTenantDataSortInput> = {
  name: "name",
};

const Content: VFC = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("devices");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => setTabValue(newValue);
  const columns = useColumns();

  const filter = useTableFilter();
  const { order, onSortByChanged } = useTableSortBy<ClientTenantDataSortInput>(
    columnsToGraphQLFields,
    defaultSortOrder
  );
  const countResult = useClientsWithPagingCountQuery({
    where: filter.graphQLFilterInput,
  });
  const pagination = useTablePagination(countResult.data?.allClientsWithPaging?.totalCount ?? 0, pageSize);
  const queryResults = {
    clients: useClientsWithPaging({
      skip: pagination.pageIndex * pageSize,
      take: pageSize,
      where: filter.graphQLFilterInput,
      order,
    }),
    securityClientDownloads: useSecurityClientDownloads(),
  };

  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <StyledTab data-testid="ClientIndex-deviceOverview" label={`${t("Clients.Devices")}`} value="devices" />
          <StyledTab data-testid="ClientIndex-addDevice" label={`${t("Clients.AddDevice")}`} value="add-device" />
        </Tabs>
      </Box>
      <StyledTabPanel value="devices">
        <StyledFlexPaper sx={{ height: "100%" }}>
          <StylesRoot>
            <ClientsIndexTable
              onSortByChanged={onSortByChanged}
              data={queryResults.clients.data}
              pagination={pagination}
              columns={columns}
              filter={filter}
              order={order}
            />
          </StylesRoot>
        </StyledFlexPaper>
      </StyledTabPanel>
      <StyledTabPanel value="add-device">
        <SecurityClientDownloads securityClientDownloads={queryResults.securityClientDownloads.data ?? []} />
      </StyledTabPanel>
    </TabContext>
  );
};

const ClientIndex: FC = () => {
  return <Page Title={Title} Content={Content} queryResults={{}} />;
};

export default ClientIndex;
