import { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
  useDeleteRecommendedActionMutation,
  useRecommendedActionsAnalystQuery,
  useRecommendedActionsQuery,
} from "../../../graphql/generated/generatedGraphQL";
import useRecommendedActionsSnackbar from "../../../hooks/useRecommendedActionsSnackbar";

const useDeleteRecommendedActions = () => {
  const { mutateAsync } = useDeleteRecommendedActionMutation();
  const { snackbarSuccess, snackbarError } = useRecommendedActionsSnackbar();
  const queryClient = useQueryClient();
  return useCallback(
    async (input: string[]): Promise<void> => {
      try {
        await mutateAsync({ input });
        snackbarSuccess();
        queryClient.invalidateQueries(useRecommendedActionsQuery.getKey());
        queryClient.invalidateQueries(useRecommendedActionsAnalystQuery.getKey());
      } catch (e) {
        snackbarError();
      }
    },
    [mutateAsync, queryClient, snackbarError, snackbarSuccess]
  );
};

export default useDeleteRecommendedActions;
