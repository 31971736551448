import type { FC } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import DashboardWidget from "../components/DashboardWidget";
import { GetDashboardDataQuery } from "../../../graphql/generated/generatedGraphQL";

/**
 * Widget displays statistics fpr the signature- and software-updates
 */
const ServiceOverviewWidget: FC<{ data: GetDashboardDataQuery }> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <DashboardWidget
      size="2x1"
      title={t("Dashboard.ServiceOverview", "Service Overview")}
      subtitle={
        <Trans i18nKey="Dashboard.ServiceOverviewDescription">
          What threats has G DATA 365 averted for you <strong>in the last 7 days</strong>?
        </Trans>
      }
      /*
        link={{
          title: t("Dashboard.ShowServiceOverview", "Show details"),
          to: "#",
        }}
      */
    >
      <Table data-testid="ServiceOverviewWidget-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t("Dashboard.Activities", "Activities")}</TableCell>
            <TableCell align="right">{t("Common.Count", "Count")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t("Dashboard.SignatureUpdates", "Signature updates")}</TableCell>
            <TableCell align="right">{data.allStatistics?.updateStatistics.signaturesUpdateCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("Dashboard.ProgramUpdates", "Program updates")}</TableCell>
            <TableCell align="right">{data.allStatistics?.updateStatistics.clientUpdateCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("Dashboard.ExpertActivities", "Expert Activities")}</TableCell>
            <TableCell align="right">{data.allStatistics?.expertActivities}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DashboardWidget>
  );
};

export default ServiceOverviewWidget;
