import { useMemo } from "react";
import useUserContext from "./useUserContext";

type ComponentBehavior = {
  DashboardSidebar: {
    showClients: boolean;
    showRecommendedActions: boolean;
    showAnalysisGroup: boolean;
    showSettingsGroup: boolean;
  };
  ResellerHeader: { showResellerHeader: boolean };
  pages: {
    dashboard: {
      showLoginToPartnerPortal: boolean;
    };
    recommendedActionTemplates: {
      canMakeTemplatesPublic: boolean;
    };
    recommendedActions: {
      disableRecommendedActionsSelection: boolean;
      showTenantColumn: boolean;
      inRowMenuDisableResolve: boolean;
      inRowMenuDisableUnresolve: boolean;
    };
  };
};

export default function useComponentBehavior(): ComponentBehavior {
  const c = useUserContext();
  const resellerInspectingTenantOrOtherRole = !c.isRoleReseller || !!c.inspectingTenant;
  return useMemo(
    () => ({
      DashboardSidebar: {
        showClients: resellerInspectingTenantOrOtherRole,
        showRecommendedActions: resellerInspectingTenantOrOtherRole,
        showAnalysisGroup: c.isRoleAnalyst,
        showSettingsGroup: !c.isRoleReseller,
      },
      ResellerHeader: { showResellerHeader: c.isRoleReseller && !!c.inspectingTenant },
      pages: {
        dashboard: {
          showLoginToPartnerPortal: c.isRoleReseller && !c.inspectingTenant,
        },
        recommendedActionTemplates: {
          canMakeTemplatesPublic: c.isRoleAnalyst,
        },
        recommendedActions: {
          disableRecommendedActionsSelection: c.isRoleReseller,
          showTenantColumn: c.isRoleReseller && !c.inspectingTenant,
          inRowMenuDisableResolve: c.isRoleReseller,
          inRowMenuDisableUnresolve: c.isRoleReseller,
        },
      },
    }),
    [c.inspectingTenant, c.isRoleAnalyst, c.isRoleReseller, resellerInspectingTenantOrOtherRole]
  );
}
