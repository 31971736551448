import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField as MaterialUiTextField } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ContentBox from "../../../components/ContentBox";
import CssGrid from "../../../components/CssGrid";
import { User } from "../../../graphql/generated/generatedGraphQL";

interface ICompanyDataWidgetProps {
  user: User;
}

const CompanyData: FC<ICompanyDataWidgetProps> = ({ user }) => {
  const { t } = useTranslation();
  return (
    <ContentBox headline={t("Settings.CompanyData", "Unternehmensdaten")}>
      <CssGrid columns={{ xs: 1 }}>
        <MaterialUiTextField
          fullWidth
          label={t("Forms.CompanyName", "Company name")}
          name="companyName"
          type="text"
          variant="outlined"
          disabled
          value={user && user.company ? user.company.name : ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
        />
      </CssGrid>
    </ContentBox>
  );
};

export default CompanyData;
