import type { FC } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page, { IContentProps } from "../../components/Page";
import AppSettings from "./components/AppSettings";
import PersonalDataForm from "./components/PersonalDataForm";
import CompanyData from "./components/CompanyData";
import PageTitle from "../../components/PageTitle";
import { useGetOwnUserQuery, GetOwnUserQuery } from "../../graphql/generated/generatedGraphQL";
import Scrollbar from "../../components/Scrollbar";

const Title: FC = () => {
  const { t } = useTranslation();
  return <PageTitle title={`${t("Sidebar.Settings")} > ${t("Sidebar.Account")}`} />;
};

interface AccountProps {
  user: GetOwnUserQuery;
}

const Content: FC<IContentProps<AccountProps>> = ({ data }) => {
  return (
    <Grid container spacing={4} justifyContent="flex-end">
      <Grid item xs={12} md={6} lg={4}>
        <AppSettings data={data.user.selfUser.settings} />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <PersonalDataForm user={data.user.selfUser} />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <CompanyData user={data.user.selfUser} />
      </Grid>
    </Grid>
  );
};

const Account: FC = () => {
  const queryResults = {
    user: useGetOwnUserQuery(),
  };
  return (
    <Scrollbar data-testid="account-scrollbar">
      <Page Title={Title} Content={Content} queryResults={queryResults} />
    </Scrollbar>
  );
};

export default Account;
