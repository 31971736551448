import { FC } from "react";
import { InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const StyledSearchField = styled("div", { name: "SearchField" })(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",
  borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
  width: "362px",
}));

const StyledSearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(39),
    },
  },
}));

const inputProps = { "aria-label": "search" };

interface SearchFieldArgs {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  defaultValue: string;
}

const SearchField: FC<SearchFieldArgs> = ({ onChange, defaultValue }) => {
  const { t } = useTranslation();
  return (
    <StyledSearchField>
      <StyledSearchIconWrapper>
        <SearchIcon />
      </StyledSearchIconWrapper>
      <StyledInputBase
        id="searchfield"
        placeholder={t("Common.Search")}
        onChange={onChange}
        inputProps={inputProps}
        defaultValue={defaultValue}
      />
    </StyledSearchField>
  );
};

export default SearchField;
