import { VFC } from "react";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import Assign from "./Assign";
import Edit from "./Edit";
import Delete from "./Delete";
import { RecommendedActionTemplateViewModel } from "../../../types/RecommendedActionTemplate";

type Props = ActionMenuItemsProps<RecommendedActionTemplateViewModel>;

const ActionMenuItems = ({ selectedFlatRows, onClose }: Props): ReturnType<VFC> => {
  return (
    <>
      <Assign selectedFlatRows={selectedFlatRows} onClose={onClose} />
      <Edit selectedFlatRows={selectedFlatRows} onClose={onClose} />
      <Delete selectedFlatRows={selectedFlatRows} onClose={onClose} />
    </>
  );
};

export default ActionMenuItems;
