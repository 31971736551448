import { useCallback } from "react";
import { User } from "../types/user";
import useAxios from "../lib/useAxios";

const useGetUserByToken: () => (token: string) => Promise<User> = () => {
  const axios = useAxios();

  const getUserByToken = useCallback(
    async (token: string) => {
      if (token.length === 0) {
        return Promise.reject();
      }

      const response = await axios.get<User>(`/Auth/ValidateToken/${token}`);
      return response.data;
    },
    [axios]
  );
  return getUserByToken;
};

export default useGetUserByToken;
