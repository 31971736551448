import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

export type AutoCompleteWithLabelProps<T> = Omit<
  AutocompleteProps<T, false, false, false>,
  "options" | "renderInput"
> & {
  label: string;
};

const EmptyAutoComplete = <T,>({ label, ...rest }: AutoCompleteWithLabelProps<T>): JSX.Element => (
  <Autocomplete
    {...rest}
    disabled
    options={[]}
    renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
  />
);
export default EmptyAutoComplete;
