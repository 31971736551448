import useAxios from "../lib/useAxios";

export function useFetcher<TData, TVariables>(query: string): (variables?: TVariables) => Promise<TData> {
  const axios = useAxios();
  return async (variables?: TVariables) => {
    if (!process.env.REACT_APP_GRAPHQL_URL) {
      throw new Error("Environment variable REACT_APP_GRAPHQL_URL has to be set for build");
    }
    const response = await axios.post(process.env.REACT_APP_GRAPHQL_URL, JSON.stringify({ query, variables }), {
      headers: { "content-type": "application/json" },
    });

    if (response.data.errors) {
      const { message } = response.data.errors[0];

      throw new Error(message);
    }

    return response.data.data;
  };
}
