import produce from "immer";
import { useMemo, useState } from "react";
import { ClientTenantDataFilterInput } from "../../../graphql/generated/generatedGraphQL";

export interface IClientsTableFilterState {
  whereFullTextContains: string;
}

export interface IClientsTableFilter extends IClientsTableFilterState {
  graphQLFilterInput: ClientTenantDataFilterInput;
  setWhereFullTextContains(fullTextContains: string): void;
}

export function mapFieldsToFilter(state: IClientsTableFilterState): ClientTenantDataFilterInput {
  return {
    or: [
      { tenantName: { icontains: state.whereFullTextContains } },
      { name: { icontains: state.whereFullTextContains } },
      { clientState: { osName: { icontains: state.whereFullTextContains } } },
      { clientState: { clientVersion: { contains: state.whereFullTextContains } } },
    ],
  };
}

export const initialClientsFilterState: IClientsTableFilterState = {
  whereFullTextContains: "",
};

function useTableFilter(initialState?: IClientsTableFilterState): IClientsTableFilter {
  const [filterState, setFilterState] = useState(initialState ? initialState : initialClientsFilterState);
  return useMemo(
    () => ({
      ...filterState,
      graphQLFilterInput: mapFieldsToFilter(filterState),
      setWhereFullTextContains: (fullTextContains) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.whereFullTextContains = fullTextContains;
          });
        });
      },
    }),
    [filterState]
  );
}

export default useTableFilter;
