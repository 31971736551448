import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Logo from "../../Logo";
import { AppTitle } from "../../AppTitle";
import Navbar from "../../Navbar";
import LanguagePopover from "../../LanguagePopover";

const DefaultNavbar: FC = () => {
  return (
    <Navbar elevation={1}>
      <Toolbar>
        <RouterLink to="/">
          <Logo width="30px" />
        </RouterLink>
        <AppTitle typography={{ variant: "h1" }} />
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <LanguagePopover />
      </Toolbar>
    </Navbar>
  );
};

export default DefaultNavbar;
