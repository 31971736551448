import { LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Maintainance from "../../../components/Maintainance";
import PageTitle from "../../../components/PageTitle";
import { useClientsWithPagingCountQuery } from "../../../graphql/generated/generatedGraphQL";
import LocalSettingsContainer from "../../../hooks/LocalSettingsContainer";
import { getLanguage } from "../../../locales";
import { PageContainer, StyledPaper } from "../../../theme/sharedStyles";

const StyledTableRoot = styled("div", { name: "TableContainer" })(({}) => ({
  height: "100%",
  ".refresh-token-element": {
    display: "none",
  },
}));

const Title: FC = () => {
  const { t } = useTranslation();

  return <PageTitle title={`${t("Sidebar.MyOverview")} > ${t("Sidebar.Reports")}`} />;
};

const FindingsOverview: FC = () => {
  const { settings } = LocalSettingsContainer.useContainer();
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const element = document.querySelector("gd-reports-overview");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element?.addEventListener("onLoadingError", (event: any) => {
      setError(event.detail !== "" ? true : false);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element?.addEventListener("onLoadingstatus", (event: any) => {
      setIsLoading(event.detail);
    });
  });

  const { i18n } = useTranslation();
  const lang = getLanguage(i18n);

  const tokenRefreshHack = useClientsWithPagingCountQuery();

  const props = {
    theme: settings.theme === "DARK" ? 0 : 1,
    language: lang,
  };

  return (
    <PageContainer>
      {Title && <Title />}
      {isLoading ? <LinearProgress data-testid="page-progressbar" /> : <></>}
      {!isError ? (
        <StyledPaper style={{ paddingBottom: 0 }}>
          <StyledTableRoot>
            <div className="refresh-token-element">{tokenRefreshHack.data?.allClientsWithPaging?.totalCount}</div>
            <gd-reports-overview {...props} />
          </StyledTableRoot>
        </StyledPaper>
      ) : (
        <Maintainance />
      )}
    </PageContainer>
  );
};

export default FindingsOverview;
