import { AppBar, Box, styled, Toolbar } from "@mui/material";
import { FC } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccountPopover from "./AccountPopover";
import { useGetCompanyNameFromRegnumberQuery } from "../graphql/generated/generatedGraphQL";

interface ResellerHeaderProps {
  regNumber: string;
}

const ResellerHeaderStyled = styled(AppBar, { name: "resellerHeaderStyled" })(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: theme.spacing(6),
  width: "100%",
  zIndex: 1300,
  boxShadow: "none",
  [`& .MuiFormControlLabel-label`]: {
    marginBottom: "3px",
  },
}));
const StyledToolbar = styled(Toolbar, { name: "styledToolbar" })(({ theme }) => ({
  minHeight: theme.spacing(6) + "!important",
  paddingLeft: "22px",
  display: "flex",
}));
const ResellerHeaderContent = styled("div", { name: "resellerHeaderContent" })(({ theme }) => ({
  fontSize: theme.spacing(3),
  paddingLeft: "38px",
}));

export const ResellerHeader: FC<ResellerHeaderProps> = ({ regNumber }) => {
  const result = useGetCompanyNameFromRegnumberQuery({ input: regNumber });
  let companyName = "";
  if (result?.data?.resolveTenantsFromRegnumber && result?.data?.resolveTenantsFromRegnumber?.length > 0) {
    companyName = result?.data?.resolveTenantsFromRegnumber[0].company;
  }
  return (
    <ResellerHeaderStyled>
      <StyledToolbar>
        <VisibilityIcon />
        <ResellerHeaderContent>{companyName}</ResellerHeaderContent>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <AccountPopover />
      </StyledToolbar>
    </ResellerHeaderStyled>
  );
};
