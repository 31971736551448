import compareDates from "../utils/compareDates";
import { Signature as GraphQlSignature } from "../graphql/generated/generatedGraphQL";

export type Signature = {
  version: string;
  releaseDate: Date;
  upToDate: boolean;
};

export function createSignatureViewModel(signature: GraphQlSignature): Signature {
  return {
    version: signature.version || "",
    releaseDate: signature.releaseDate,
    upToDate: signature.upToDate || false,
  };
}

export function signatureUpToDate(signatures: Signature[]): boolean {
  return signatures.every((item) => item.upToDate);
}

export function compareReleaseDates(a: Signature, b: Signature): number {
  return new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime();
}

export function oldestSignature(signatures: Signature[]): Date {
  const outdatedSignatures = signatures.filter((item) => !item.upToDate);
  const sortedSignatures = outdatedSignatures.sort(compareReleaseDates);
  return new Date(sortedSignatures[0].releaseDate ?? new Date(1900, 1, 1));
}

export function compareSignatureVersion(a: Signature[], b: Signature[]): number {
  if (signatureUpToDate(a) && !signatureUpToDate(b)) return 1;
  if (signatureUpToDate(a) && signatureUpToDate(b)) return 0;
  if (!signatureUpToDate(a) && signatureUpToDate(b)) return -1;
  const oldestA = oldestSignature(a);
  const oldestB = oldestSignature(b);
  return compareDates(oldestA, oldestB);
}
