const regNumberKey = "regNumber";

export function getRegNumber(searchParams: URLSearchParams) {
  return searchParams.get(regNumberKey);
}

export function setRegNumber(searchParams: URLSearchParams, regNumber: string) {
  searchParams.set(regNumberKey, regNumber);
}

export function deleteRegNumber(searchParams: URLSearchParams) {
  searchParams.delete(regNumberKey);
}
