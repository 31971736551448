import type { FC } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle";
import PasswordForm from "./components/PasswordForm";
import { PageContainer } from "../../theme/sharedStyles";

const Overview: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContainer maxWidth="lg">
        <PageTitle title={`${t("Sidebar.Settings")} > ${t("Sidebar.Security")}`} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <PasswordForm />
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default Overview;
