import { Language } from "../graphql/generated/generatedGraphQL";

// Statically check if we missed a case
export function unknownLanguage(language: never): never;
// Catch unknown languages at runtime
export function unknownLanguage(language: Language) {
  return "Unknown language " + language.toString();
}

export { Language };
