/* eslint-disable no-console */
import { ApplicationInsights, ITelemetryItem, SeverityLevel } from "@microsoft/applicationinsights-web";
import format from "format-util";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { browserHistory } from "./components/BrowserRouter";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

const reactPlugin = new ReactPlugin();

const clickPlugin = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
    customDataPrefix: "data-test",
  },
};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin, clickPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPlugin.identifier]: clickPluginConfig,
    },
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableCookiesUsage: true,
  },
});
appInsights.loadAppInsights();

// Send build with all messages

function versionInfoTelemetryInitialier(envelope: ITelemetryItem) {
  const { tags } = envelope;
  if (tags) {
    tags.build = process.env.REACT_APP_BUILD;
    tags.rev = process.env.REACT_APP_REV;
  }
}
appInsights.addTelemetryInitializer(versionInfoTelemetryInitialier);

// Trace warnings and errors to Application Insights

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function robustFormat(message?: any, ...optionalParams: any[]) {
  if (typeof message === "string") {
    return format(message, ...optionalParams);
  }
  return format("%s", message, ...optionalParams);
}

const warn = (
  (oldWarn) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (message?: any, ...optionalParams: any[]) => {
    oldWarn(message, ...optionalParams);
    appInsights.trackTrace({
      message: robustFormat(message, ...optionalParams),
      severityLevel: SeverityLevel.Warning,
    });
  }
)(console.warn);

const error = (
  (oldError) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (message?: any, ...optionalParams: any[]) => {
    oldError(message, ...optionalParams);
    appInsights.trackTrace({
      message: robustFormat(message, ...optionalParams),
      severityLevel: SeverityLevel.Error,
    });
  }
)(console.error);

window.console.warn = warn;
window.console.error = error;

export default appInsights;
