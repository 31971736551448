import type { FC } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useSnackbar } from "notistack";
import { TextField } from "../../../components/formFields";
import { LostPasswordFormValues, getValidationSchema } from "../formValidations/lostPassword";
import useAxios from "../../../lib/useAxios";

interface LostPasswordFormProps {
  onSuccessfulSubmit?: () => void;
}

const useRequestLostPasswordMail = () => {
  const axios = useAxios();
  return async (username: string, language: string): Promise<boolean> => {
    try {
      // Response is always a 200, unless something is really broken.
      await axios.post("/Auth/RequestResetPassword", null, {
        params: { language, username },
      });
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn("Error on request password reset:", err);
      return false;
    }
  };
};

const LostPasswordForm: FC<LostPasswordFormProps> = ({ onSuccessfulSubmit }) => {
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const requestLostPasswordMail = useRequestLostPasswordMail();
  const initialFormValues: LostPasswordFormValues = {
    email: "",
  };
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const success = await requestLostPasswordMail(values.email, i18n.language);
      setStatus({ success });
      setSubmitting(false);
      if (success && onSuccessfulSubmit) {
        onSuccessfulSubmit();
      } else {
        enqueueSnackbar(t("Common.UnspecifiedError"), {
          variant: "error",
        });
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField formik={formik} label={t("Forms.Email")} name="email" type="email" required icon={<PersonIcon />} />
      <Button
        data-testid="lostPasswordForm-submit"
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {t("LostPassword.SubmitBtn", "Reset password")}
      </Button>
    </form>
  );
};

export default LostPasswordForm;
