import { ArrowForward } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IContentProps } from "../../../components/Page";
import PageTitle from "../../../components/PageTitle";
import { GetOwnUserQuery } from "../../../graphql/generated/generatedGraphQL";
import {
  StyledPaper,
  StyledTableDefaultMessageTitle,
  StyledTableDefaultMessageSubtitle,
} from "../../../theme/sharedStyles";

const LoginToPartnerPortal: FC<IContentProps<{ user: GetOwnUserQuery }>> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={2} item xs={12}>
          <Grid item>
            <PageTitle
              title={t("Dashboard.WelcomeBack", {
                defaultValue: "Welcome back, {{firstName}} {{lastName}}.",
                firstName: data.user.selfUser.firstName,
                lastName: data.user.selfUser.lastName,
              })}
              subtitle={data.user.selfUser.company?.name}
            />
          </Grid>
          <Grid item>{/* Placeholder for Export-Report Button */}</Grid>
        </Grid>
      </Grid>
      <StyledPaper sx={{ paddingBottom: 0, paddingRight: 0, paddingLeft: 0 }}>
        <StyledTableDefaultMessageTitle variant="h3">
          {t("Dashboard.PartnerPortalUnreachable")}
        </StyledTableDefaultMessageTitle>
        <StyledTableDefaultMessageSubtitle variant="h4">
          {t("Dashboard.PleaseSelectCustomer")}
        </StyledTableDefaultMessageSubtitle>
        <Box
          sx={{
            textAlign: "end",
            alignContent: "end",
            flexDirection: "column",
            padding: "8px",
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowForward fontSize="small" color="primary" />}
            variant="text"
            sx={{ fontSize: "14px" }}
            href={t("Dashboard.PartnerPortalURL")}
          >
            Partnerportal
          </Button>
        </Box>
      </StyledPaper>
    </>
  );
};

export default LoginToPartnerPortal;
