import { FC } from "react";
import clsx from "clsx";
import { priorities as priorityColors } from "../../../theme/colors";
import { RecommendedActionPriority, RecommendedActionViewModel } from "../../../types/RecommendedAction";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import RecommendedActionTag from "./RecommendedActionTag";

export const collapsedHeight = 37.5;

const PREFIX = "RecommendedActionsTable";
export const recommendedActionsTableClasses = {
  expanderColumn: `${PREFIX}-expanderColumn`,
  link: `${PREFIX}-link`,
  collapse: `${PREFIX}-collapse`,
  boxTable: `${PREFIX}-boxTable`,
  priority: `${PREFIX}-priority`,
  templateName: `${PREFIX}-templateName`,
  clientName: `${PREFIX}-clientName`,
  createdAt: `${PREFIX}-createdAt`,
  markdown: `${PREFIX}-markdown`,
  priorityIcon: `${PREFIX}-priorityIcon`,
  isSolved: `${PREFIX}-isSolved`,
  statusGrid: `${PREFIX}-statusGrid`,
  goodJob: `${PREFIX}-goodJob`,
  tenant: `${PREFIX}-tenant`,
};
export const StyledRecommendedActionsTableRoot = styled("div")(({ theme }) => ({
  height: "100%",
  [`& .${recommendedActionsTableClasses.expanderColumn}`]: {
    padding: "0px",
    width: "1%",
  },
  [`& .${recommendedActionsTableClasses.link}`]: {
    paddingLeft: "4px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "8px",
  },
  [`& .${recommendedActionsTableClasses.collapse}`]: {
    padding: "0px",
  },
  [`& .${recommendedActionsTableClasses.boxTable}`]: {
    height: "100%",
    "& th, td": {
      padding: "6px 6px",
    },
  },
  [`& .${recommendedActionsTableClasses.priority}`]: {
    width: "1%",
    paddingRight: 0,
    "&:not(:first-of-type)": {
      paddingLeft: 0,
    },
    "& svg": {
      width: "20px",
      height: "20px",
      display: "inline-block",
      verticalAlign: "middle",
    },
  },
  [`& .${recommendedActionsTableClasses.templateName}`]: {
    whiteSpace: "wrap",
    width: "150px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "160px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  [`& .${recommendedActionsTableClasses.clientName}`]: {
    whiteSpace: "nowrap",
    minWidth: "150px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "160px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  [`& .${recommendedActionsTableClasses.tenant}`]: {
    minWidth: "175px",
    maxWidth: "360px",
    overflowWrap: "break-word",
  },
  [`& .${recommendedActionsTableClasses.createdAt}`]: {
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      maxWidth: "160px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  [`& .${recommendedActionsTableClasses.markdown}`]: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    "& h1,h2,h3,h4,h5,h6": {
      fontSize: "12px",
      margin: "0px",
    },
    "& p": {
      margin: "0px",
    },
    "& ol,ul": {
      margin: "0px",
      "& li": {
        lineHeight: "12pt",
      },
    },
    "& a": {
      textDecoration: "none",
      color: "#5691d1",
    },
  },
  [`& .${recommendedActionsTableClasses.priorityIcon}`]: {
    "&.low": {
      color: priorityColors.low,
    },
    "&.medium": {
      color: priorityColors.medium,
    },
    "&.high": {
      color: priorityColors.high,
    },
  },
  [`& .${recommendedActionsTableClasses.isSolved}`]: {
    "& td": {
      color: theme.palette.text.disabled,
    },
    "& td:first-of-type": {
      opacity: "0.4",
    },
  },
  [`& .${recommendedActionsTableClasses.statusGrid}`]: {
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    height: "100%",
  },
  [`& .${recommendedActionsTableClasses.goodJob}`]: {
    opacity: 0.5,
    margin: "2vw 0",
    width: "210px",
  },
}));

export const PriorityIcon: FC<{ value: RecommendedActionPriority }> = ({ value: priority }) => {
  return (
    <FiberManualRecordIcon className={clsx(recommendedActionsTableClasses.priorityIcon, priority.toLowerCase())} />
  );
};

export const GoodJobImage: FC = () => {
  return (
    <img
      src="/static/images/recommendedActionsDone.svg"
      className={recommendedActionsTableClasses.goodJob}
      alt="Good Job"
    />
  );
};

export const RelatesToCell: FC<{ value: RecommendedActionViewModel }> = ({ value: recommendedAction }) => {
  if (recommendedAction.clientName) return <>{recommendedAction.clientName}</>;
  if (recommendedAction.tag)
    return (
      <>
        <RecommendedActionTag tag={recommendedAction.tag} />
      </>
    );
  return <></>;
};
