import { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";

const StyledBox = styled(Box)(() => ({
  position: "relative",
  "& .box-icon": {
    opacity: 0.3,
    zIndex: 1,
    position: "absolute",
    lineHeight: 1,
    "& svg": {
      fontSize: "inherit",
    },
  },
  "& .box-content": {
    zIndex: 2,
    position: "relative",
  },
}));

interface BoxWithBackgroundIconProps {
  icon: ReactNode;
  iconStyles?: SxProps<Theme>;
}

/**
 * Renders a material ui box with an transparent Icon as Background.
 * Used for registration and password reset
 */
const BoxWithBackgroundIcon: FC<BoxWithBackgroundIconProps> = ({ icon, children, iconStyles }) => {
  return (
    <StyledBox>
      <Box className="box-icon" sx={iconStyles}>
        {icon}
      </Box>
      <div className="box-content">{children}</div>
    </StyledBox>
  );
};

export default BoxWithBackgroundIcon;
