import { VFC } from "react";
import {
  RecommendedActionSortInput,
  SortEnumType,
  useRecommendedActionsAnalystQuery,
  useRecommendedActionsCountQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { StyledPaper } from "../../../theme/sharedStyles";
import RecommendedActionsTableActionBar from "./RecommendedActionsTableActionBar";
import useTableFilter from "../hooks/useTableFilter";
import RecommendedActionsTable from "../../recommendedActions/components/RecommendedActionsTable";
import useTableSortBy, { MapColumnToField } from "../../../components/table/useTableSortBy";
import ActionMenuItemsForAnalyst from "./ActionMenuItemsForAnalyst";
import { pageSize } from "../../recommendedActions/Overview";
import { RecommendedActionsForAnalystsTableColumns } from "../RecommendedActionsForAnalystsTableColumns";
import useTablePagination from "../../../components/table/useTablePagination";

export const columnsToGraphQLFields: MapColumnToField<RecommendedActionSortInput> = {
  tenantName: "tenantName",
  priority: "priority",
  client: "clientName",
  createdAt: "createdAt",
  createdByUserFullName: "createdByUserFullName",
  resolvedAt: "resolvedAt",
  resolvedByUserFullName: "resolvedByUserFullName",
};

const defaultSortOrder: RecommendedActionSortInput[] = [
  { state: SortEnumType.Asc },
  { priority: SortEnumType.Desc },
  { createdAt: SortEnumType.Desc },
];

const RecommendedActionsForAnalystsIndexContent: VFC = () => {
  const filter = useTableFilter();
  const { order, onSortByChanged } = useTableSortBy<RecommendedActionSortInput>(
    columnsToGraphQLFields,
    defaultSortOrder
  );
  const actionBar = <RecommendedActionsTableActionBar filter={filter} />;
  const countResult = useRecommendedActionsCountQuery({
    where: filter.graphQLFilterInput,
    order,
  });
  const pagination = useTablePagination(countResult.data?.recommendedActionsWithPaging?.totalCount || 0, pageSize);
  const queryResult = useRecommendedActionsAnalystQuery({
    skip: pagination.pageIndex * pageSize,
    take: pageSize,
    where: filter.graphQLFilterInput,
    order,
  });
  return (
    <StyledPaper>
      <RecommendedActionsTable
        onSortByChanged={onSortByChanged}
        actionBar={actionBar}
        order={order}
        columns={RecommendedActionsForAnalystsTableColumns}
        filter={filter}
        actionMenuItems={ActionMenuItemsForAnalyst}
        queryResult={queryResult}
        pagination={pagination}
      />
    </StyledPaper>
  );
};

export default RecommendedActionsForAnalystsIndexContent;
