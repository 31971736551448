import { MenuItem } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import useUnresolveRecommendedAction from "../hooks/useUnresolveRecommendedAction";

type UnresolveProps = ActionMenuItemsProps<RecommendedActionViewModel>;

const Unresolve = ({ selectedFlatRows, onClose }: UnresolveProps): ReturnType<VFC> => {
  const { t } = useTranslation();
  const unresolveRecommendedAction = useUnresolveRecommendedAction();
  const disabled = selectedFlatRows.length === 0 || !selectedFlatRows.some((r) => r.original.state !== "Unsolved");
  return (
    <MenuItem
      key={"RecommendedActions.Unresolved"}
      disabled={disabled}
      onClick={() => {
        onClose();
        if (selectedFlatRows.length) {
          const ids = selectedFlatRows.map((r) => r.original.id);
          ids.forEach(function (id) {
            unresolveRecommendedAction(id);
          });
        }
      }}
    >
      {t("RecommendedActions.Unresolved")}
    </MenuItem>
  );
};

export default Unresolve;
