import { forwardRef } from "react";
import ReactScrollBar from "./scrollbar/index";
import ScrollBar from "./scrollbar/index";
import type { ReactScrollBarProps as PerfectScrollbarProps } from "./scrollbar/index";
import "./Scrollbar.css";

type ScrollbarProps = PerfectScrollbarProps;

const Scrollbar = forwardRef<ScrollBar, ScrollbarProps>((props, ref) => {
  const { children, ...other } = props;

  return (
    <ReactScrollBar ref={ref} {...other}>
      {children}
    </ReactScrollBar>
  );
});

export default Scrollbar;
