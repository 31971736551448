import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Priority } from "../../../types/RecommendedActionTemplate";

type RecommendedActionPriorityProps = { priority: Priority };

const RecommendedActionPriority: VFC<RecommendedActionPriorityProps> = ({ priority }) => {
  const { t } = useTranslation();
  switch (priority) {
    case Priority.Low:
      return t("RecommendedActions.Priorities.LOW");
    case Priority.Medium:
      return t("RecommendedActions.Priorities.MEDIUM");
    case Priority.High:
      return t("RecommendedActions.Priorities.HIGH");
  }
};

export default RecommendedActionPriority;
