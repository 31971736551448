import { useState, VFC } from "react";
import { Divider, MenuItem, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ThumbUpAltSharpIcon from "@mui/icons-material/ThumbUpAltSharp";
import UndoSharpIcon from "@mui/icons-material/UndoSharp";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import { useTranslation } from "react-i18next";
import useResolveRecommendedAction from "../../recommendedActions/hooks/useResolveRecommendedAction";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: "24px",
}));

type ResolveWithConfirmationProps = ActionMenuItemsProps<RecommendedActionViewModel>;

const ResolveWithConfirmation = ({ selectedFlatRows, onClose }: ResolveWithConfirmationProps): ReturnType<VFC> => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  const resolveRecommendedAction = useResolveRecommendedAction();
  const disabled = selectedFlatRows.length === 0;

  return (
    <>
      <MenuItem key={"RecommendedActions.Resolve"} disabled={disabled} onClick={handleClickOpen}>
        {t("RecommendedActions.Resolve")}
      </MenuItem>
      <Dialog open={open}>
        <StyledDialogTitle>{t("ResolveDialog.HeaderMessage")}</StyledDialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>{t("ResolveDialog.DescriptionMessage")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            startIcon={<UndoSharpIcon fontSize="small" />}
          >
            {t("ResolveDialog.Revert")}
          </Button>
          <Button
            data-testid="ResolveWithConfirmation-ConfirmResolveTableAction"
            onClick={() => {
              onClose();
              handleClose();
              if (selectedFlatRows.length) {
                const ids = selectedFlatRows.map((r) => r.original.id);
                ids.forEach(function (id) {
                  resolveRecommendedAction(id);
                });
              }
            }}
            autoFocus
            color="primary"
            variant="contained"
            startIcon={<ThumbUpAltSharpIcon fontSize="small" />}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResolveWithConfirmation;
