import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { SortingRule } from "react-table";
import ContentBox from "../../../components/ContentBox";
import useTablePagination from "../../../components/table/useTablePagination";
import {
  RecommendedActionSortInput,
  useRecommendedActionsAnalystQuery,
  useRecommendedActionsCountQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { StyledRecommendedActionsTableRoot } from "../../recommendedActions";
import RecommendedActionsTable from "../../recommendedActions/components/RecommendedActionsTable";
import ActionMenuItemsForAnalyst from "../../recommendedActionsAnalyst/components/ActionMenuItemsForAnalyst";
import { IRecommendedActionTableFilter } from "../../recommendedActionsAnalyst/hooks/useTableFilter";
import { RecommendedActionsForAnalystsTableColumns } from "../../recommendedActionsAnalyst/RecommendedActionsForAnalystsTableColumns";

type LastRecommendedActionsProps = {
  order: RecommendedActionSortInput[];
  onSortByChanged: (sortBy: SortingRule<string>[]) => void;
  filter: IRecommendedActionTableFilter;
};

const LastRecommendedActions: VFC<LastRecommendedActionsProps> = ({ filter, order, onSortByChanged }) => {
  const { t } = useTranslation();
  const pageSize = 25;
  const countResult = useRecommendedActionsCountQuery({
    where: filter.graphQLFilterInput,
    order,
  });
  const pagination = useTablePagination(countResult.data?.recommendedActionsWithPaging?.totalCount || 0, pageSize);
  const queryResult = useRecommendedActionsAnalystQuery({
    skip: pagination.pageIndex * pageSize,
    take: pageSize,
    where: filter.graphQLFilterInput,
    order,
  });
  return (
    <ContentBox
      headline={t("RecommendedActions.Templates.LastRecommendedActions")}
      sx={{ mt: 4, display: "flex", flexDirection: "column", overflow: "hidden" }}
      sxChild={{ pt: 0, overflow: "hidden" }}
    >
      <StyledRecommendedActionsTableRoot>
        <RecommendedActionsTable
          order={order}
          onSortByChanged={onSortByChanged}
          filter={filter}
          columns={RecommendedActionsForAnalystsTableColumns}
          actionMenuItems={ActionMenuItemsForAnalyst}
          queryResult={queryResult}
          pagination={pagination}
        />
      </StyledRecommendedActionsTableRoot>
    </ContentBox>
  );
};

export default LastRecommendedActions;
