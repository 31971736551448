import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../types/RecommendedActionTemplate";
import { RecommendedActionTag as RestRecommendedActionTag } from "../../../types/RecommendedAction";

type RecommendedActionTagProps = { tag: Tag | RestRecommendedActionTag };

const RecommendedActionTag: VFC<RecommendedActionTagProps> = ({ tag }) => {
  const { t } = useTranslation();
  switch (tag) {
    case Tag.None:
    case "None":
      return t("RecommendedActions.Tag.NONE");
    case Tag.General:
    case "General":
      return t("RecommendedActions.Tag.GENERAL");
    case Tag.Welcome:
    case "Welcome":
      return t("RecommendedActions.Tag.WELCOME");
  }
};

export default RecommendedActionTag;
