import { VFC } from "react";
import { SortingRule } from "react-table";
import EnsureLoadingOrData from "../../../components/EnsureLoadingOrData";
import useTablePagination from "../../../components/table/useTablePagination";
import useTableSortBy, { MapColumnToField } from "../../../components/table/useTableSortBy";
import {
  RecommendedActionTemplateSortInput,
  SortEnumType,
  useRecommendedActionTemplatesCountQuery,
  useRecommendedActionTemplatesWithPagingQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { StyledPaper } from "../../../theme/sharedStyles";
import { RecommendedActionTemplateViewModel } from "../../../types/RecommendedActionTemplate";
import useTableFilter, {
  IRecommendedActionTemplatesTableFilter,
} from "../../recommendedActionTemplates/hooks/useTableFilter";
import RecommendedActionTemplatesTable from "./RecommendedActionTemplatesTable";
import RecommendedActionTemplatesTableActionBar from "./RecommendedActionTemplatesTableActionBar";

const pageSize = 25;

const columnsToGraphQLFields: MapColumnToField<RecommendedActionTemplateSortInput> = {
  priority: "priority",
  name: "name",
};

const defaultSortOrder: RecommendedActionTemplateSortInput[] = [
  { priority: SortEnumType.Desc, name: SortEnumType.Asc },
];

type QueryDataProps = {
  filter: IRecommendedActionTemplatesTableFilter;
  order: RecommendedActionTemplateSortInput[];
  onSortByChanged: (sortBy: SortingRule<RecommendedActionTemplateViewModel>[]) => void;
  rowCount: number;
};

const QueryData: VFC<QueryDataProps> = ({ filter, order, onSortByChanged, rowCount }) => {
  const pagination = useTablePagination(rowCount, pageSize);

  const queryResult = useRecommendedActionTemplatesWithPagingQuery({
    skip: pagination.pageIndex * pageSize,
    take: pageSize,
    where: filter.graphQLFilterInput,
    order,
  });

  const actionBar = <RecommendedActionTemplatesTableActionBar filter={filter} />;

  return (
    <EnsureLoadingOrData queryResult={queryResult}>
      <StyledPaper>
        <RecommendedActionTemplatesTable
          data={queryResult.data?.recommendedActionTemplatesWithPaging?.items ?? []}
          onSortByChanged={onSortByChanged}
          pagination={pagination}
          actionBar={actionBar}
        />
      </StyledPaper>
    </EnsureLoadingOrData>
  );
};

const TemplatesOverviewContent: VFC = () => {
  const filter = useTableFilter();
  const { order, onSortByChanged } = useTableSortBy<RecommendedActionTemplateSortInput>(
    columnsToGraphQLFields,
    defaultSortOrder
  );
  const countResult = useRecommendedActionTemplatesCountQuery({
    where: filter.graphQLFilterInput,
    order,
  });
  return (
    <EnsureLoadingOrData queryResult={countResult}>
      <QueryData
        filter={filter}
        order={order}
        onSortByChanged={onSortByChanged}
        rowCount={countResult.data?.recommendedActionTemplatesWithPaging?.totalCount || 0}
      />
    </EnsureLoadingOrData>
  );
};

export default TemplatesOverviewContent;
