import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "@mui/material";
import LanguageSelect from "../../../components/LanguageSelect";
import ContentBox from "../../../components/ContentBox";
import SendSecurityReportsFormControl from "./SendSecurityReportsControl";
import { UserSettings } from "../../../graphql/generated/generatedGraphQL";
import { ThemeSelectControl } from "./ThemeSelectControl";
interface Props {
  data: UserSettings | null | undefined;
}

const AppSettings: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ContentBox headline={t("Settings.AppSettings", "App Settings")}>
      <LanguageSelect data={data} />
      <ThemeSelectControl />
      <FormGroup>
        <SendSecurityReportsFormControl data={data} />
      </FormGroup>
    </ContentBox>
  );
};

export default AppSettings;
