import { SupportedLocale } from "../locales";
import {
  Tag as GraphQLTag,
  Priority as GraphQLPriority,
  RecommendedActionState as GraphQLState,
  RecommendedAction as GraphQLRecommendedAction,
  RecommendedActionExtendedFragment,
  RecommendedActionBaseFragment,
} from "../graphql/generated/generatedGraphQL";

export type { GraphQLRecommendedAction };

export type RecommendedActionPriority = "Low" | "Medium" | "High";

export type RecommendedActionState = "None" | "Unsolved" | "Solved";
export type RecommendedActionTag = "None" | "Welcome" | "General";

export type RecommendedActionCultureData = {
  summary: string;
  description: string;
};

/** Recommended action as returned by the API endpoints /RecommendedActions, /Dashboard */
export type RecommendedAction = {
  id: string;
  mavId: string | null;
  tenantName: string | null | undefined;
  clientId: number | null;
  clientName: string | null;
  priority: RecommendedActionPriority;
  state: RecommendedActionState;
  tag: RecommendedActionTag;
  createdAt: string;
  createdByFullname: string | null | undefined;
  resolvedAt: string | null;
  resolvedByFullname: string | null | undefined;
  cultureSpecifics: {
    [key in SupportedLocale]: RecommendedActionCultureData;
  };
};

export type RecommendedActionBaseViewModel = Omit<
  RecommendedAction,
  "createdAt" | "resolvedAt" | "createdByFullname" | "resolvedByFullname"
> & {
  createdAt: Date;
  readonly summary: string;
  readonly description: string;
};

export type RecommendedActionViewModel = RecommendedActionBaseViewModel & {
  createdByUserFullName?: string;
  resolvedAt?: Date | null;
  resolvedByUserFullName?: string | null;
};

const GraphQLTagToViewModel: Record<GraphQLTag, RecommendedActionTag> = {
  [GraphQLTag.None]: "None",
  [GraphQLTag.Welcome]: "Welcome",
  [GraphQLTag.General]: "General",
};

const GraphQLPriorityToViewModel: Record<GraphQLPriority, RecommendedActionPriority> = {
  [GraphQLPriority.Low]: "Low",
  [GraphQLPriority.Medium]: "Medium",
  [GraphQLPriority.High]: "High",
};

const GraphQLStateToViewModel: Record<GraphQLState, RecommendedActionState> = {
  [GraphQLState.None]: "None",
  [GraphQLState.Solved]: "Solved",
  [GraphQLState.Unsolved]: "Unsolved",
};

export function createRecommendedActionViewModelFromRecommendedActionExtendedFragment(
  r: RecommendedActionExtendedFragment | RecommendedActionBaseFragment,
  lng: SupportedLocale
): RecommendedActionViewModel {
  const { priority, state, tag, createdAt, recommendedActionCultureSpecifics, ...rest } = r;

  const resolvedAt = (r as RecommendedActionExtendedFragment)?.resolvedAt ?? null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cultureSpecifics: any = {};

  for (const cultureSpecific of recommendedActionCultureSpecifics) {
    const { language, ...summaryAndDescription } = cultureSpecific;
    cultureSpecifics[language.toLowerCase()] = summaryAndDescription;
  }

  return {
    ...rest,
    priority: GraphQLPriorityToViewModel[priority],
    state: GraphQLStateToViewModel[state],
    tag: GraphQLTagToViewModel[tag],
    createdAt: new Date(createdAt),
    resolvedAt: resolvedAt ? new Date(resolvedAt) : null,
    cultureSpecifics,
    get summary() {
      return cultureSpecifics[lng]?.summary || cultureSpecifics.en?.summary || "";
    },
    get description() {
      return cultureSpecifics[lng]?.description || cultureSpecifics.en?.description || "";
    },
  };
}
