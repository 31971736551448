import type { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface AppTitleProps {
  typography?: TypographyProps;
}

const renderAppTitle = ({ typography, userPlan }: { typography?: TypographyProps; userPlan: string }): JSX.Element => {
  return (
    <Typography className="appTitle" fontWeight={300} {...typography}>
      <strong>G DATA 365</strong> {userPlan}
    </Typography>
  );
};

export const AppTitle: FC<AppTitleProps> = ({ typography }) => {
  return renderAppTitle({ typography, userPlan: "" });
};

export const AppTitleWithPlan: FC<AppTitleProps> = ({ typography }) => {
  const userPlan = "Essentials";
  return renderAppTitle({ typography, userPlan: `| ${userPlan}` });
};
