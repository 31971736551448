import { useEffect, useState, FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import SplashScreen from "../../components/SplashScreen";
import { User } from "../../types/user";
import LostPasswordContainer from "./components/LostPasswordContainer";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useGetUserByToken } from "../../utils";

type ResetPasswordState = "initLoading" | "resetForm" | "tokenNotValid" | "resetComplete";

const ResetPassword: FC = () => {
  const [view, setView] = useState<ResetPasswordState>("initLoading");
  const [userByToken, setUserByToken] = useState<User>({} as User);
  const { token } = useParams() as { token: string };
  const { t } = useTranslation();
  const getUserByToken = useGetUserByToken();

  function handleResetComplete() {
    setView("resetComplete");
  }

  useEffect(() => {
    getUserByToken(token)
      .then((user) => {
        if (user) {
          setUserByToken(user);
          setView("resetForm");
        }
      })
      .catch(() => {
        setView("tokenNotValid");
      });
  }, [getUserByToken, token]);

  switch (view) {
    case "resetForm":
      return (
        <LostPasswordContainer
          dataTestid="ResetPassword-resetPasswordForm"
          headline={t("LostPassword.Reset.Headline")}
          subheader={t("LostPassword.Reset.Subheader", {
            email: userByToken.email,
          })}
        >
          <ResetPasswordForm onSuccessfulSubmit={handleResetComplete} token={token} />
        </LostPasswordContainer>
      );
    case "tokenNotValid":
      return (
        <LostPasswordContainer
          headline={t("Common.UnspecifiedError")}
          subheader={t("LostPassword.Reset.ErrorDescription")}
        />
      );
    case "resetComplete":
      return (
        <LostPasswordContainer
          dataTestid="ResetPassword-resetPasswordComplete"
          headline={t("LostPassword.ResetComplete.Headline")}
          subheader={t("LostPassword.ResetComplete.Info")}
        >
          <Typography variant="body2" align="center">
            <Button component={Link} color="primary" variant="text" to="/login">
              {t("LostPassword.ResetComplete.ToLogin")}
            </Button>
          </Typography>
        </LostPasswordContainer>
      );
    default:
      return <SplashScreen />;
  }
};

export default ResetPassword;
