import React, { useState, VFC } from "react";
import { CircularProgress, IconButton, Menu } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Row } from "react-table";
import UnknownObject from "../../types/UnknownObject";

export type ActionMenuItemsProps<T extends UnknownObject> = {
  selectedFlatRows: Row<T>[];
  onClose: () => void;
};

interface ButtonAndMenuProps<T extends UnknownObject> {
  ActionMenuItems: VFC<ActionMenuItemsProps<T>>;
  selectedFlatRows: Row<T>[];
}

const ButtonAndMenu = <T extends UnknownObject>({
  ActionMenuItems,
  selectedFlatRows,
}: ButtonAndMenuProps<T>): ReturnType<VFC> => {
  const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        data-testid="ButtonAndMenu-OpenTableActions"
        aria-controls="table-actions-menu"
        aria-haspopup="true"
        aria-label="table actions"
        size="small"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu id="table-actions-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        <ActionMenuItems selectedFlatRows={selectedFlatRows} onClose={handleClose} />
      </Menu>
    </>
  );
};

type TableActionMenuProps<T extends UnknownObject> = ButtonAndMenuProps<T> & {
  isLoading?: boolean;
};

const TableActionMenu = <T extends UnknownObject>({
  ActionMenuItems,
  selectedFlatRows,
  isLoading,
}: TableActionMenuProps<T>): ReturnType<VFC> => {
  // Size of CircularProgress only seems off. The element is rotating and changes height over time.
  return (
    <div style={{ textAlign: "center" }}>
      {isLoading ? (
        <CircularProgress size="23px" />
      ) : (
        <ButtonAndMenu ActionMenuItems={ActionMenuItems} selectedFlatRows={selectedFlatRows} />
      )}
    </div>
  );
};

export default TableActionMenu;
