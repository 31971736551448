import {
  Language,
  Priority,
  Tag,
  RecommendedActionTemplate,
  RecommendedActionTemplateCultureSpecifics,
  RecommendedActionTemplateFragment,
} from "../graphql/generated/generatedGraphQL";
import { fallbackLocale, SupportedLocale } from "../locales";

const prioritySortOrder: Record<Priority, number> = {
  HIGH: 0,
  MEDIUM: 1,
  LOW: 2,
};

export function compareRecommendedActionTemplatePriority(a: Priority, b: Priority): number {
  return Math.sign(prioritySortOrder[a] - prioritySortOrder[b]);
}

export function getLanguageIndices(
  cultureSpecifics: RecommendedActionTemplateCultureSpecifics[]
): Record<Language, number> {
  const result: Record<Language, number> = {
    PL: -1,
    DE: -1,
    EN: -1,
    NONE: -1,
  };
  cultureSpecifics.forEach((cultureSpecific, i) => {
    result[cultureSpecific.language] = i;
  });
  return result;
}

export type RecommendedActionTemplateViewModel = RecommendedActionTemplate & {
  readonly summary: string;
  readonly description: string;
};

export enum Lang {
  de = "De",
  en = "En",
  pl = "Pl",
}

export function ensureRecommendedActionTemplateLanguages(r: RecommendedActionTemplate): RecommendedActionTemplate {
  const { cultureSpecifics, ...rest } = r;
  const languageIndices = getLanguageIndices(r.cultureSpecifics);
  const missingCultureSpecifics: RecommendedActionTemplateCultureSpecifics[] = [];
  const allLanguages = Object.values(Language);
  allLanguages.forEach((language: Language) => {
    if (languageIndices[language] === -1 && language !== "NONE") {
      missingCultureSpecifics.push({
        language: language as Language,
        summary: "",
        description: "",
      });
    }
  });
  return { ...rest, cultureSpecifics: [...cultureSpecifics, ...missingCultureSpecifics] };
}

export function createRecommendedActionTemplateViewModel(
  r: RecommendedActionTemplateFragment,
  lng: SupportedLocale
): RecommendedActionTemplateViewModel {
  const hasLanguage = r.cultureSpecifics.some((obj) => obj.language === Language[Lang[lng]]);
  const recommendedActionCultureSpecifics = hasLanguage
    ? r.cultureSpecifics.find((obj) => obj.language === Language[Lang[lng]])
    : r.cultureSpecifics.find((obj) => obj.language === Language[Lang[fallbackLocale]]);
  return {
    ...r,
    get summary() {
      return recommendedActionCultureSpecifics?.summary ?? "";
    },
    get description() {
      return recommendedActionCultureSpecifics?.description ?? "";
    },
  };
}

export { Priority, Tag };
export type { RecommendedActionTemplate };
