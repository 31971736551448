import React, { useState, useCallback, useMemo } from "react";

// Inspired by https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
// (example was completely buggy)
function usePersistedState<S>(key: string, defaultValue: S): [S, React.Dispatch<S>] {
  const value = useMemo(() => {
    const localStorageValue = localStorage.getItem(key);
    if (localStorageValue) {
      try {
        return JSON.parse(localStorageValue);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing localStorage key "${key}": `, e);
      }
    }
    return defaultValue;
  }, [defaultValue, key]);

  const [state, setState] = useState<S>(value);

  const setLocalStorageAndState = useCallback(
    (s: S) => {
      localStorage.setItem(key, JSON.stringify(s));
      setState(s);
    },
    [key]
  );

  return [state, setLocalStorageAndState];
}

export default usePersistedState;
