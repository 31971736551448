import { useMemo } from "react";
import axios from "axios";
import IAuthentication, { KeycloakTokenParsed } from "./IAuthentication";
import usePersistedState from "../usePersistedState";
import createAxios from "../../lib/createAxios";

interface State {
  accessToken?: string;
  refreshToken?: string;
}

const initialState: State = {};
const kcUrl: string = process.env.REACT_APP_KEYCLOAK_URL || "";
const kcRealm: string = process.env.REACT_APP_KEYCLOAK_REALM || "";
const kcClientId: string = process.env.REACT_APP_KEYCLOAK_CLIENTID || "";
const url = `${kcUrl}realms/${kcRealm}/protocol/openid-connect/token`;

function parseToken(token: string): KeycloakTokenParsed | undefined {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error parsing JWT ", e);
    return undefined;
  }
}

function useResourceOwnerPasswordGrant(): IAuthentication {
  const [state, setState] = usePersistedState("tokens", initialState);

  const tokenParsed = useMemo(() => {
    if (!state.accessToken) {
      return undefined;
    }
    return parseToken(state.accessToken);
  }, [state]);

  const login = async (username: string, password: string): Promise<void> => {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);
    params.append("client_id", kcClientId);
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    const response = await axios.post<{
      access_token: string;
      refresh_token: string;
    }>(url, params, options);
    setState({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    return Promise.resolve();
  };

  const logout = async (): Promise<void> => {
    if (!state.refreshToken) {
      return Promise.reject(new Error("Can't updateToken without refresh token"));
    }
    const logoutUrl = `${kcUrl}realms/${kcRealm}/protocol/openid-connect/logout`;
    const params = new URLSearchParams();
    params.append("client_id", kcClientId);
    params.append("refresh_token", state.refreshToken);
    setState(initialState);
    await axios.post(logoutUrl, params);
    return Promise.resolve();
  };

  const updateToken = async () => {
    if (!state.refreshToken) {
      return Promise.reject(new Error("Can't updateToken without refresh token"));
    }
    const params = new URLSearchParams();
    params.append("client_id", kcClientId);
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", state.refreshToken);
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    const axiosInstance = createAxios(url);
    const response = await axiosInstance.post(url, params, options);
    if (response.status === 200) {
      setState({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      });
      return Promise.resolve();
    }
    if (response.status === 400) {
      setState(initialState);
      return Promise.reject();
    }
    return Promise.resolve();
  };

  return {
    isAuthenticated: !!state.accessToken,
    token: state.accessToken,
    tokenParsed,
    realmAccess: tokenParsed?.realm_access,
    login,
    logout,
    updateToken,
  };
}

export default useResourceOwnerPasswordGrant;
