import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import TrafficIcon from "@mui/icons-material/Traffic";
import ReactMarkdown from "react-markdown";
import { Collapse, Typography } from "@mui/material";
import { Column, Row } from "react-table";
import ExpandIcon from "./ExpandIcon";
import setCanExpand from "./setCanExpand";
import {
  recommendedActionsTableClasses,
  collapsedHeight,
  PriorityIcon,
  RelatesToCell,
} from "./RecommendedActionsTableStyles";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";

const ClientHeader: VFC = () => {
  const { t } = useTranslation();
  return t("Clients.Name");
};

export const priorityColumn: Column<RecommendedActionViewModel> = {
  Header: <TrafficIcon fontSize="small" />,
  accessor: "priority",
  Cell: PriorityIcon,
  className: recommendedActionsTableClasses.priority,
};

export const RecommendedActionHeader: VFC = () => {
  const { t } = useTranslation();
  return t("Common.RecommendedAction");
};

export const TenantHeader: VFC = () => {
  const { t } = useTranslation();
  return t("Common.Tenant", "Tenant");
};

export const tenantColumn: Column<RecommendedActionViewModel> = {
  Header: <TenantHeader />,
  id: "tenantName",
  accessor: "tenantName",
  className: recommendedActionsTableClasses.tenant,
};

export const clientColumn: Column<RecommendedActionViewModel> = {
  Header: <ClientHeader />,
  id: "client",
  accessor: (originalRow) => originalRow,
  Cell: RelatesToCell,
  className: recommendedActionsTableClasses.clientName,
};

const RecommendedActionCell = ({ row }: { row: Row<RecommendedActionViewModel> }): JSX.Element => {
  const value = row.original;
  return (
    <Collapse
      in={row.isExpanded}
      collapsedSize={`${collapsedHeight}px`}
      className={recommendedActionsTableClasses.collapse}
    >
      <div
        ref={(el) => {
          if (!el) return;
          setCanExpand(row, el.scrollHeight > collapsedHeight);
        }}
      >
        <Typography variant="body2">{value.summary}</Typography>
        <ReactMarkdown
          className={recommendedActionsTableClasses.markdown}
          components={{
            a: ({ children, ...rest }) => (
              <a
                target="_blank"
                {...rest}
                onClick={(e) => e.stopPropagation()}
                className={recommendedActionsTableClasses.link}
              >
                {children}
              </a>
            ),
          }}
        >
          {value.description || ""}
        </ReactMarkdown>
      </div>
    </Collapse>
  );
};

export const recommendedActionColumn: Column<RecommendedActionViewModel> = {
  Header: <RecommendedActionHeader />,
  id: "recommendedAction",
  Cell: RecommendedActionCell,
  disableSortBy: true,
  style: { width: "100%" },
};

export const expanderColumn: Column<RecommendedActionViewModel> = {
  id: "expander",
  Cell: ({ row }: { row: Row }) => <div>{row.state.canExpand ? <ExpandIcon expanded={row.isExpanded} /> : null}</div>,
  disableSortBy: true,
  className: recommendedActionsTableClasses.expanderColumn,
};

const CreatedAtHeader: VFC = () => {
  const { t } = useTranslation();
  return t("RecommendedActions.CreatedAt");
};

export const DateCell: VFC<{ value: Date }> = ({ value }) => {
  const { t } = useTranslation();
  return t("Common.DateTimeFormat", { date: value });
};

export const createdAtColumn: Column<RecommendedActionViewModel> = {
  Header: <CreatedAtHeader />,
  accessor: "createdAt",
  Cell: DateCell,
  sortType: "datetime",
  className: recommendedActionsTableClasses.createdAt,
};
