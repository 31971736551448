import { FC, useMemo } from "react";
import { Box, Button, MenuItem, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import getValidationSchema from "../formValidations/template";
import { TextField, SelectField } from "../../../components/formFields";
import { getLanguageIndices, Priority, Tag } from "../../../types/RecommendedActionTemplate";
import { Language } from "../../../types/Language";
import { styled } from "@mui/material/styles";
import { sortTags } from "../../../types/Tag";
import { sortPriorities } from "../../../types/Priority";
import RecommendedActionTag from "../../recommendedActions/components/RecommendedActionTag";
import RecommendedActionPriority from "../../recommendedActions/components/RecommendedActionPriority";
import YesNo from "./YesNo";
import useComponentBehavior from "../../../hooks/useComponentBehavior";
import FormFieldsForLanguage from "./FormFieldsForLanguage";
import { ICultureSpecifics } from "./ICultureSpecifics";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 4, 0, 4),
  display: "flex",
  flexDirection: "column",
  minWidth: "300px",
}));

export type IRecommendedActionTemplateFormProps<T extends ICultureSpecifics> = {
  Title: FC;
  mutateRecommendedActionTemplate: (value: T) => Promise<void>;
  recommendedActionTemplate: T;
};

function RecommendedActionTemplateForm<T extends ICultureSpecifics>({
  Title,
  recommendedActionTemplate: templateValues,
  mutateRecommendedActionTemplate,
}: IRecommendedActionTemplateFormProps<T>): ReturnType<FC> {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: templateValues,
    validationSchema: getValidationSchema(),
    validateOnMount: true,
    onSubmit: (values: T, { setStatus, setSubmitting }) => {
      mutateRecommendedActionTemplate(values)
        .then(() => {
          setStatus({ success: true });
          setSubmitting(false);
          navigate("./..");
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), {
            variant: "error",
          });
        });
    },
  });
  const languageIndices = useMemo(
    () => getLanguageIndices(templateValues.cultureSpecifics),
    [templateValues.cultureSpecifics]
  );
  const behavior = useComponentBehavior();

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <Title />
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid}
          size="medium"
          sx={{ height: "32px" }}
        >
          {t("RecommendedActions.Templates.Submit")}
        </Button>
      </div>

      <StyledPaper>
        <TextField
          formik={formik}
          name="name"
          label={t("RecommendedActions.Templates.Template")}
          hideHelperText
          required
          dataTestid="NewTemplateForm-Name"
          margin="normal"
        />
        <Box display="flex" sx={{ gap: "30px" }}>
          <SelectField
            formik={formik}
            name="priority"
            label={t("RecommendedActions.Priority")}
            data-testid="NewTemplateForm-Priority"
          >
            {sortPriorities(Object.values(Priority)).map((priority) => (
              <MenuItem key={priority} value={priority}>
                <RecommendedActionPriority priority={priority} />
              </MenuItem>
            ))}
          </SelectField>
          <SelectField
            formik={formik}
            name="tag"
            label={t("RecommendedActions.Category")}
            data-testid="NewTemplateForm-Tag"
          >
            {sortTags(Object.values(Tag)).map((tag) => (
              <MenuItem key={tag} value={tag}>
                <RecommendedActionTag tag={tag} />
              </MenuItem>
            ))}
          </SelectField>
          {behavior.pages.recommendedActionTemplates.canMakeTemplatesPublic && (
            <SelectField
              formik={formik}
              name="isPublic"
              label={t("RecommendedActions.Visibility")}
              data-testid="NewTemplateForm-Visibility"
            >
              {["true", "false"].map((isPublic) => (
                <MenuItem key={isPublic} value={isPublic}>
                  <YesNo value={isPublic} />
                </MenuItem>
              ))}
            </SelectField>
          )}
        </Box>
      </StyledPaper>

      <FormFieldsForLanguage formik={formik} language={Language.En} languageIndices={languageIndices} required />
      <FormFieldsForLanguage formik={formik} language={Language.De} languageIndices={languageIndices} />
      <FormFieldsForLanguage formik={formik} language={Language.Pl} languageIndices={languageIndices} />
    </form>
  );
}

export default RecommendedActionTemplateForm;
