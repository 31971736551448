import { VFC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import PageTitle from "../../components/PageTitle";
import { StyledRecommendedActionsTableRoot } from "../recommendedActions";
import RecommendedActionsForAnalystsIndexContent from "./components/RecommendedActionsForAnalystsIndexContent";

const Title: VFC = () => {
  const { t } = useTranslation();

  return (
    <PageTitle title={`${t("Sidebar.AnalysisAndAdministration")} > ${t("Sidebar.RecommendedActionsForAnalysts")}`} />
  );
};

const RecommendedActionsForAnalystsIndex: VFC = () => {
  return (
    <StyledRecommendedActionsTableRoot>
      <Page Title={Title} Content={RecommendedActionsForAnalystsIndexContent} queryResults={{}} />
    </StyledRecommendedActionsTableRoot>
  );
};
export default RecommendedActionsForAnalystsIndex;
