import React, { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Column, SortingRule } from "react-table";
import EnhancedTable from "../../../components/table/EnhancedTable";
import { getLanguage } from "../../../locales";
import {
  RecommendedActionsAnalystQuery,
  RecommendedActionSortInput,
  RecommendedActionsQuery,
  useRecommendedActionsCountQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { getRecommendedActionRowProps } from "..";
import { styled } from "@mui/material";
import EnsureLoadingOrData from "../../../components/EnsureLoadingOrData";
import { IRecommendedActionTableFilter } from "../../recommendedActionsAnalyst/hooks/useTableFilter";
import { useIsFetching, UseQueryResult } from "react-query";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import {
  createRecommendedActionViewModelFromRecommendedActionExtendedFragment,
  RecommendedActionBaseViewModel,
  RecommendedActionViewModel,
} from "../../../types/RecommendedAction";
import ITablePagination from "../../../components/table/ITablePagination";
import useComponentBehavior from "../../../hooks/useComponentBehavior";
import { SelectionCell, DisabledSelectionCell } from "../../../components/table/EnhancedTable";
import { InRowMenuItemsProps } from "../../../components/table/InRowMenu";

const PREFIX = "RecommendedActionsTable";
const classes = {
  enhancedTable: `${PREFIX}-enhancedTable`,
  enhancedTableActionBar: `${PREFIX}-enhancedTableActionBar`,
};

const StylesRoot = styled("div")(() => ({
  height: "100%",
  [`& .${classes.enhancedTable}`]: {
    height: "100%",
  },
  [`& .${classes.enhancedTableActionBar}`]: {
    height: "calc(100% - 48px)",
  },
}));

type RecommendedActionsTableProps = {
  onSortByChanged: (
    sortBy: SortingRule<RecommendedActionBaseViewModel>[] | SortingRule<RecommendedActionViewModel>[]
  ) => void;
  actionBar?: React.ReactNode;
  NoResultComponent?: React.ComponentType;
  filter: IRecommendedActionTableFilter;
  order: RecommendedActionSortInput[];
  columns: Column<RecommendedActionViewModel>[];
  InRowMenuItems?: ({ row }: InRowMenuItemsProps<RecommendedActionViewModel>) => ReturnType<VFC>;
  actionMenuItems?: ({
    selectedFlatRows,
    onClose,
  }: ActionMenuItemsProps<RecommendedActionViewModel>) => ReturnType<VFC>;
  queryResult:
    | UseQueryResult<RecommendedActionsQuery, unknown>
    | UseQueryResult<RecommendedActionsAnalystQuery, unknown>;
  pagination: ITablePagination;
};

const RecommendedActionsTable: VFC<RecommendedActionsTableProps> = ({
  onSortByChanged,
  actionBar,
  NoResultComponent,
  filter,
  order,
  columns,
  InRowMenuItems,
  actionMenuItems,
  queryResult,
  pagination,
}) => {
  const { i18n } = useTranslation();
  const lng = getLanguage(i18n);
  const countResult = useRecommendedActionsCountQuery({
    where: filter.graphQLFilterInput,
    order,
  });
  const viewModelData = useMemo(
    () =>
      (queryResult.data?.recommendedActionsWithPaging?.items ?? []).map((r) =>
        createRecommendedActionViewModelFromRecommendedActionExtendedFragment(r, lng)
      ),
    [lng, queryResult.data?.recommendedActionsWithPaging?.items]
  );
  const isLoading = useIsFetching() > 0;
  const behavior = useComponentBehavior();
  if (!isLoading && viewModelData.length < 1 && NoResultComponent) {
    return (
      <EnsureLoadingOrData queryResult={countResult}>
        <EnsureLoadingOrData queryResult={queryResult}>
          {actionBar && actionBar}
          {<NoResultComponent />}
        </EnsureLoadingOrData>
      </EnsureLoadingOrData>
    );
  }
  return (
    <StylesRoot>
      <EnsureLoadingOrData queryResult={countResult}>
        <EnsureLoadingOrData queryResult={queryResult}>
          {actionBar && actionBar}
          <EnhancedTable
            columns={columns}
            data={viewModelData}
            order={order}
            getRowProps={getRecommendedActionRowProps}
            isLoading={isLoading}
            manualSortBy
            onSortByChanged={onSortByChanged}
            className={actionBar ? classes.enhancedTableActionBar : classes.enhancedTable}
            pagination={pagination}
            Selection={
              behavior.pages.recommendedActions.disableRecommendedActionsSelection
                ? DisabledSelectionCell
                : SelectionCell
            }
            ActionMenuItems={actionMenuItems}
            InRowMenuItems={InRowMenuItems}
          />
        </EnsureLoadingOrData>
      </EnsureLoadingOrData>
    </StylesRoot>
  );
};

export default RecommendedActionsTable;
