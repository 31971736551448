import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Page from "../../components/Page";
import PageTitle from "../../components/PageTitle";
import Scrollbar from "../../components/Scrollbar";
import { useRecommendedActionTemplateQuery } from "../../graphql/generated/generatedGraphQL";
import CreateRecommendedActionFromTemplateContainer from "./components/CreateRecommendedActionFromTemplateContainer";

const Title: FC = () => {
  const { t } = useTranslation();
  return (
    <PageTitle
      title={`${t("Sidebar.AnalysisAndAdministration")} > ${t("RecommendedActions.Templates.AssignTemplate")}`}
    />
  );
};

const AssignTemplate: FC = () => {
  const { id } = useParams();

  const queryResults = {
    recommendedActionTemplateQuery: useRecommendedActionTemplateQuery({ id }),
  };
  return (
    <Scrollbar>
      <Page Title={Title} Content={CreateRecommendedActionFromTemplateContainer} queryResults={queryResults} />
    </Scrollbar>
  );
};

export default AssignTemplate;
