import { FC, useMemo } from "react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Page, { IContentProps } from "../../components/Page";
import PageTitle from "../../components/PageTitle";
import {
  RecommendedActionTemplateQuery,
  UpdateRecommendedActionTemplateInput,
  useRecommendedActionTemplateQuery,
  useRecommendedActionTemplatesWithPagingQuery,
  useUpdateRecommendedActionTemplateMutation,
} from "../../graphql/generated/generatedGraphQL";
import RecommendedActionTemplateForm from "./components/RecommendedActionTemplateForm";
import { ensureRecommendedActionTemplateLanguages } from "../../types/RecommendedActionTemplate";
import Scrollbar from "../../components/Scrollbar";

const useSubmitChanges = () => {
  const { mutateAsync } = useUpdateRecommendedActionTemplateMutation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return async (templateValues: UpdateRecommendedActionTemplateInput): Promise<void> => {
    templateValues.isPublic = JSON.parse(templateValues.isPublic.toString());
    const input = templateValues;
    try {
      await mutateAsync({ input });
      queryClient.invalidateQueries(useRecommendedActionTemplatesWithPagingQuery.getKey());
      queryClient.invalidateQueries(useRecommendedActionTemplateQuery.getKey({ id: input.id }));
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), {
        variant: "error",
      });
    }
  };
};

const Title: FC = () => {
  const { t } = useTranslation();
  return (
    <PageTitle
      title={`${t("Sidebar.AnalysisAndAdministration")} > ${t("Sidebar.TemplatesOverview")} > ${t(
        "RecommendedActions.Templates.EditTemplate"
      )}`}
    />
  );
};

const Content: FC<IContentProps<{ recommendedActionTemplateQuery: RecommendedActionTemplateQuery }>> = ({ data }) => {
  const submitChanges = useSubmitChanges();
  const template = useMemo(
    () => ensureRecommendedActionTemplateLanguages(data.recommendedActionTemplateQuery.recommendedActionTemplate),
    [data.recommendedActionTemplateQuery.recommendedActionTemplate]
  );
  return (
    <>
      {template.accessRights.canEdit && (
        <RecommendedActionTemplateForm
          Title={Title}
          recommendedActionTemplate={template}
          mutateRecommendedActionTemplate={submitChanges}
        />
      )}
    </>
  );
};

const EmptyTitle = () => <></>;

const EditTemplate: FC = () => {
  const { id } = useParams();
  const queryResults = { recommendedActionTemplateQuery: useRecommendedActionTemplateQuery({ id }) };
  return (
    <Scrollbar data-testid="TemplateForm-Scrollbar">
      <Page Title={EmptyTitle} Content={Content} queryResults={queryResults} />
    </Scrollbar>
  );
};

export default EditTemplate;
