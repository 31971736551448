import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, SortingRule } from "react-table";
import { Box, Collapse, styled, Typography } from "@mui/material";
import TrafficIcon from "@mui/icons-material/Traffic";
import ReactMarkdown from "react-markdown";
import {
  compareRecommendedActionTemplatePriority,
  createRecommendedActionTemplateViewModel,
  RecommendedActionTemplate,
  RecommendedActionTemplateViewModel,
} from "../../../types/RecommendedActionTemplate";
import EnhancedTable from "../../../components/table/EnhancedTable";
import { getLanguage } from "../../../locales";
import ExpandIcon from "../../recommendedActions/components/ExpandIcon";
import {
  recommendedActionsTableClasses,
  collapsedHeight,
  PriorityIcon,
} from "../../recommendedActions/components/RecommendedActionsTableStyles";
import setCanExpand from "../../recommendedActions/components/setCanExpand";
import ITablePagination from "../../../components/table/ITablePagination";
import ActionMenuItems from "./ActionMenuItems";
import { GDataIcon } from "../../../icons/GconIcon";
import { RecommendedActionPriority } from "../../../types/RecommendedAction";

const PREFIX = "RecommendedActionTemplatesTable";
const classes = {
  enhancedTable: `${PREFIX}-enhancedTable`,
  enhancedTableActionBar: `${PREFIX}-enhancedTableActionBar`,
};

const StylesRoot = styled("div")(() => ({
  height: "100%",
  [`& .${classes.enhancedTable}`]: {
    height: "100%",
  },
  [`& .${classes.enhancedTableActionBar}`]: {
    height: "calc(100% - 48px)",
  },
}));

function sortTypePriority(a: Row<RecommendedActionTemplateViewModel>, b: Row<RecommendedActionTemplateViewModel>) {
  return compareRecommendedActionTemplatePriority(a.original.priority, b.original.priority);
}

type RecommendedActionTemplatesWithPagingProps = {
  data: RecommendedActionTemplate[];
  onSortByChanged: (sortBy: SortingRule<RecommendedActionTemplateViewModel>[]) => void;
  pagination: ITablePagination;
  actionBar: React.ReactNode;
};

const RecommendedActionTemplatesTable: VFC<RecommendedActionTemplatesWithPagingProps> = ({
  data,
  onSortByChanged,
  pagination,
  actionBar,
}) => {
  const { t, i18n } = useTranslation();
  const lng = getLanguage(i18n);
  const viewModelData = useMemo(() => data.map((r) => createRecommendedActionTemplateViewModel(r, lng)), [data, lng]);

  const columns: Column<RecommendedActionTemplateViewModel>[] = [
    {
      Header: <TrafficIcon fontSize="small" />,
      accessor: "priority",
      Cell: ({ value }: { value: RecommendedActionPriority }) => (
        <Box>
          <PriorityIcon value={value} />
        </Box>
      ),
      sortType: sortTypePriority,
      className: recommendedActionsTableClasses.priority,
    },
    {
      Header: t("RecommendedActions.Templates.Template") as string,
      accessor: (originalRow) => originalRow,
      style: { width: "25%" },
      Cell: ({ value }: { value: RecommendedActionTemplateViewModel }) => (
        <Box display="flex">
          {value.isPublic && (
            <Typography variant="body1" marginRight="4px">
              <GDataIcon verticalAlign="middle" />
            </Typography>
          )}
          <Typography variant="body2" style={{ marginTop: "3px" }}>
            {value.name}
          </Typography>
        </Box>
      ),
      className: recommendedActionsTableClasses.templateName,
    },
    {
      Header: t("Common.RecommendedAction") as string,
      accessor: (originalRow) => originalRow,
      Cell: ({ value, row }: { value: RecommendedActionTemplateViewModel; row: Row }) => (
        <Collapse
          in={row.isExpanded}
          collapsedSize={`${collapsedHeight}px`}
          className={recommendedActionsTableClasses.collapse}
        >
          <div
            ref={(el) => {
              if (!el) return;
              setCanExpand(row, el.scrollHeight > collapsedHeight);
            }}
          >
            <Typography variant="body2">{value.summary}</Typography>
            <ReactMarkdown
              className={recommendedActionsTableClasses.markdown}
              components={{
                a: ({ children, ...rest }) => (
                  // eslint-disable-next-line
                  <a
                    target="_blank"
                    {...rest}
                    onClick={(e) => e.stopPropagation()}
                    className={recommendedActionsTableClasses.link}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {value.description || ""}
            </ReactMarkdown>
          </div>
        </Collapse>
      ),
    },
    {
      id: "expander",
      Cell: ({ row }: { row: Row }) => (
        <div>{row.state.canExpand ? <ExpandIcon expanded={row.isExpanded} /> : null}</div>
      ),
      disableSortBy: true,
      className: recommendedActionsTableClasses.expanderColumn,
    },
  ];

  return (
    <StylesRoot>
      {actionBar && actionBar}
      <EnhancedTable
        columns={columns}
        data={viewModelData}
        ActionMenuItems={ActionMenuItems}
        onSortByChanged={onSortByChanged}
        className={actionBar ? classes.enhancedTableActionBar : classes.enhancedTable}
        pagination={pagination}
        dataTestid="templatesOverview-RecommendedActionTemplatesTable"
      />
    </StylesRoot>
  );
};

export default RecommendedActionTemplatesTable;
