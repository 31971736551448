import type { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface DashboardGridProps {
  children: ReactNode;
  columns?: number;
}

const StyledBox = styled(Box)(({ theme }) => ({
  gridAutoFlow: "dense",
  display: "grid",
  gridGap: theme.spacing(4),
  paddingLeft: "1px",
  paddingRight: "1px",
  paddingBottom: "1px",
  [theme.breakpoints.up("md")]: {
    gridAutoRows: "1fr",
  },
}));

const DashboardGrid: FC<DashboardGridProps> = ({ children, columns }) => {
  const oneFractionalUnit = "1fr ";
  return (
    <StyledBox
      sx={{
        gridTemplateColumns: oneFractionalUnit.repeat(columns ?? 4),
      }}
    >
      {children}
    </StyledBox>
  );
};

export default DashboardGrid;
