import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@mui/material";
import { getLanguage, mapLanguageToSupportedLocale, mapSupportedLocaleToLanguage, SupportedLocale } from "../locales";
import { languageOptions } from "./LanguagePopover";
import {
  useCreateOrUpdateUserSettingsMutation,
  useGetOwnUserQuery,
  UserSettingsInput,
} from "../graphql/generated/generatedGraphQL";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { UserSettingsProps } from "../pages/settings/components/SendSecurityReportsControl";

const LanguageSelect: FC<UserSettingsProps> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { mutateAsync } = useCreateOrUpdateUserSettingsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleChangeLanguage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const input: UserSettingsInput = {
      userId: data?.userId,
      language: mapSupportedLocaleToLanguage(event.target.value as SupportedLocale),
    };
    try {
      const result = await mutateAsync({ input });
      queryClient.invalidateQueries(useGetOwnUserQuery.getKey());
      i18n.changeLanguage(mapLanguageToSupportedLocale(result.createOrUpdateUserSettings.language));
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  const selectedOption = getLanguage(i18n);

  return (
    <TextField
      fullWidth
      select
      label={t("Common.Language", "Language")}
      data-testid="LanguageSelect-languageField"
      value={selectedOption}
      variant="outlined"
      onChange={handleChangeLanguage}
    >
      {(Object.keys(languageOptions) as SupportedLocale[]).map((language) => (
        <MenuItem data-testid={`LanguageSelect-languageItem${language}`} key={`lang${language}`} value={language}>
          {languageOptions[language].label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSelect;
