import { VFC } from "react";
import { useTranslation } from "react-i18next";
import StyledTableHeaderBar from "../../../components/StyledTableHeaderBar";
import { RecommendedActionState } from "../../../graphql/generated/generatedGraphQL";
import { IRecommendedActionTableFilter } from "../hooks/useTableFilter";
import SearchField from "../../../components/SearchField";
import PriorityToggleButtons from "../../../components/PriorityToggleButtons";
import StyledToggleButtonWithCheckIcon from "../../../components/CheckedStyledToggleButton";
import { FilterButtonsContainer } from "../../../components/table/FilterButtonsContainer";
import useDebouncedInputValueHandler from "../../../hooks/useDebouncedInputValueHandler";

type RecommendedActionsTableActionBarProps = { filter: IRecommendedActionTableFilter };

const RecommendedActionsTableActionBar: VFC<RecommendedActionsTableActionBarProps> = ({ filter }) => {
  const { t } = useTranslation();
  const handleSearchChange = (value: string) => filter.setWhereFullTextContains(value);
  const handleSearchChangeDebounced = useDebouncedInputValueHandler(handleSearchChange);

  return (
    <StyledTableHeaderBar>
      <FilterButtonsContainer>
        <StyledToggleButtonWithCheckIcon
          value="unsolved"
          selected={filter.whereState.UNSOLVED}
          onChange={() => filter.includeState(RecommendedActionState.Unsolved, !filter.whereState.UNSOLVED)}
          containerclassname="state"
        >
          {t("RecommendedActions.Open")}
        </StyledToggleButtonWithCheckIcon>
        <StyledToggleButtonWithCheckIcon
          value="solved"
          selected={filter.whereState.SOLVED}
          onChange={() => filter.includeState(RecommendedActionState.Solved, !filter.whereState.SOLVED)}
          containerclassname="state"
        >
          {t("RecommendedActions.Closed")}
        </StyledToggleButtonWithCheckIcon>
        <PriorityToggleButtons filter={filter} />
      </FilterButtonsContainer>
      <SearchField onChange={handleSearchChangeDebounced} defaultValue={filter.whereFullTextContains} />
    </StyledTableHeaderBar>
  );
};

export default RecommendedActionsTableActionBar;
