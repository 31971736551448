import { FC } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledPaperWidth960 } from "../../../theme/sharedStyles";

const TokenNotValid: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledPaperWidth960>
      <Typography variant="h1" sx={{ mb: 3 }}>
        {t("Common.UnspecifiedError")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("Registration.ErrorDescription")}
      </Typography>
    </StyledPaperWidth960>
  );
};

export default TokenNotValid;
