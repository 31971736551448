import { useQuery, UseQueryResult } from "react-query";
import {
  useAllTenantsQuery,
  AllTenantsQuery,
  AllTenantsDocument,
  MmsTenant,
} from "../../../graphql/generated/generatedGraphQL";
import { useFetcher } from "../../../graphql/useAxiosFetcher";

export type Tenant = {
  mavId: string;
  name: string;
};

function mapToTenant(data: MmsTenant): Tenant {
  return {
    mavId: (data.mavId ?? "").toString(),
    name: data.name ?? "",
  };
}

function useCustomFetcher() {
  const fetcher = useFetcher<AllTenantsQuery, unknown>(AllTenantsDocument);

  return () => fetcher().then((response) => response.allTenants?.map(mapToTenant) ?? []);
}

export default function useAllTenants(): UseQueryResult<Tenant[]> {
  const fetcher = useCustomFetcher();

  const result = useQuery<Tenant[]>(useAllTenantsQuery.getKey(), fetcher);
  return result;
}
