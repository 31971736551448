import { VFC } from "react";
import { InRowMenuItemsProps } from "../../../components/table/InRowMenu";
import InRowResolve from "../../../components/table/InRowResolveWithConfirmation";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";

type Props = InRowMenuItemsProps<RecommendedActionViewModel>;

const InRowMenuItems = ({ row }: Props): ReturnType<VFC> => {
  return <InRowResolve row={row} />;
};

export default InRowMenuItems;
