import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ITablePagination from "./ITablePagination";

const pageKey = "page";

export default function useTablePagination(rowCount: number, pageSize: number): ITablePagination {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageCount = Math.ceil(rowCount / pageSize);

  let pageIndex = parseInt(searchParams.get(pageKey) ?? "1") - 1;
  if (isNaN(pageIndex) || pageIndex < 0 || pageIndex >= pageCount) {
    pageIndex = 0;
  }

  const onPageIndexChanged = useCallback(
    (page: number) => {
      const newValue = (page + 1).toString();

      // Prevent endless loops on callback
      if (searchParams.get(pageKey) === newValue) {
        return;
      }

      searchParams.set(pageKey, newValue);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return useMemo(
    () => ({
      pageIndex,
      pageSize,
      pageCount,
      onPageIndexChanged,
    }),
    [pageCount, pageIndex, pageSize, onPageIndexChanged]
  );
}
