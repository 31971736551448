import { FC } from "react";
import { Box, Typography, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import BoxWithBackgroundIcon from "../components/BoxWithBackgroundIcon";
import LoginForm from "../components/LoginForm";
import { StyledPaperWidth480 } from "../../../theme/sharedStyles";

const RegisterComplete: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Zoom in timeout={500}>
      <StyledPaperWidth480>
        <BoxWithBackgroundIcon
          iconStyles={{
            fontSize: "14rem",
            color: theme.palette.success.main,
            top: "-1rem",
            right: "-1rem",
          }}
          icon={<DoneIcon />}
        >
          <Typography variant="h1" sx={{ mb: 3 }}>
            {t("Registration.Success", "Registration successful")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t(
              "Registration.SuccessDescription",
              "Please enter the password you have just chosen to log in to your personal area."
            )}
          </Typography>
        </BoxWithBackgroundIcon>
        <Box sx={{ mt: 12 }}>
          <LoginForm />
        </Box>
      </StyledPaperWidth480>
    </Zoom>
  );
};

export default RegisterComplete;
