// based on https://github.com/tannerlinsley/react-table/tree/master/examples/material-UI-kitchen-sink
// inspired by https://github.com/ggascoigne/react-table-example/blob/master/src/Table/Table.tsx

import React, { ReactElement, PropsWithChildren, useEffect, VFC, FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  useRowSelect,
  useSortBy,
  useTable,
  Hooks,
  TableOptions,
  RowPropGetter,
  PluginHook,
  useExpanded,
  useRowState,
  UseRowSelectInstanceProps,
  UseTableCellProps,
  SortingRule,
  usePagination,
  Row,
} from "react-table";
import { useTranslation } from "react-i18next";
import TableActionMenu, { ActionMenuItemsProps } from "./TableActionMenu";
import Scrollbar from "../Scrollbar";
import { styled } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import ITablePagination from "./ITablePagination";
import InRowMenu, { InRowMenuItemsProps } from "./InRowMenu";

const PREFIX = "EnhancedTable";
const classes = {
  table: `${PREFIX}-table`,
  selection: `${PREFIX}-selection`,
};
const StyledTableRoot = styled("div", { name: "EnhancedTableContainer" })(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "visible",
  alignItems: "stretch",
  flexFlow: "column",
  [`& .${classes.table}`]: {
    flex: "auto",
    overflow: "auto",
    "&": {
      // necessary for sticky header
      borderCollapse: "separate",
    },
    "& th": {
      display: "inline-bock",
      whiteSpace: "nowrap",

      // sticky header
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    },
    "& td": {
      verticalAlign: "baseline",
    },
  },
  [`& .${classes.selection}`]: {
    width: "1%",
  },
}));

const StyledPagination = styled(Pagination, { name: "Pagination" })(() => ({ flex: "none", paddingTop: "10px" }));

function stopPropagation(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
  e.stopPropagation();
}

// Used instead of object, to prevent warnings from ESLint
type UnknownObject = Record<string, unknown>;

export function SelectionCell<T extends UnknownObject>({ row }: PropsWithChildren<UseTableCellProps<T>>) {
  return (
    <Checkbox
      data-testid="SelectionCell-RowSelected"
      size="small"
      {...row.getToggleRowSelectedProps()}
      onClick={stopPropagation}
    />
  );
}

export function DisabledSelectionCell() {
  return <Checkbox size="small" onClick={stopPropagation} disabled />;
}

const ActionHeader: VFC = () => {
  const { t } = useTranslation();
  return t("EnhancedTable.Action");
};

type ISelectRadioCellProps<T extends UnknownObject> = UseTableCellProps<T> & UseRowSelectInstanceProps<T>;
export function SelectionRadioCell<T extends UnknownObject>({
  row,
  toggleAllRowsSelected,
  toggleRowSelected,
}: PropsWithChildren<ISelectRadioCellProps<T>>) {
  return (
    <Checkbox
      {...row.getToggleRowSelectedProps()}
      onClick={(e) => {
        stopPropagation(e);
        toggleAllRowsSelected(false);
        toggleRowSelected(row.id, true);
      }}
      size="small"
    />
  );
}

export type TableProperties<T extends UnknownObject> = TableOptions<T> & {
  /** A component that renders the children of the TableActionMenu */
  ActionMenuItems?: VFC<ActionMenuItemsProps<T>>;
  InRowMenuItems?: VFC<InRowMenuItemsProps<T>>;
  getRowProps?: (row: T) => RowPropGetter<T>;
  isLoading?: boolean;
  dataTestid?: string;
  Selection?: FC<UseTableCellProps<T>>;
  onSortByChanged?: (sortBy: SortingRule<T>[]) => void;
  pagination?: ITablePagination;
};

function EnhancedTable<T extends UnknownObject>(props: PropsWithChildren<TableProperties<T>>): ReactElement {
  const {
    dataTestid,
    ActionMenuItems,
    InRowMenuItems,
    Selection = SelectionCell,
    getRowProps = () => ({}),
    isLoading,
    className,
    onSortByChanged = () => {},
    autoResetSortBy = false,
    pagination,
    manualPagination = true,
    autoResetPage = false,
    ...rest
  } = props;

  const { t } = useTranslation();

  const plugins: PluginHook<T>[] = [useSortBy, useExpanded, useRowState, usePagination];
  if (ActionMenuItems) {
    plugins.push(useRowSelect, (hooks: Hooks<T>) => {
      hooks.allColumns.push((columns) => [
        ...columns,
        // Let's make a column for selection
        {
          id: "selection",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: Selection,
          className: classes.selection,
        },
      ]);
    });
  }

  if (InRowMenuItems) {
    plugins.push((hooks: Hooks<T>) => {
      hooks.allColumns.push((columns) => [
        ...columns,
        {
          Header: <ActionHeader />,
          id: "inRowMenu",
          Cell: ({ row }: { row: Row<T> }) => {
            return InRowMenu({ Items: InRowMenuItems, row: row });
          },
          disableSortBy: true,
        },
      ]);
    });
  }

  const tableOptions = { ...rest, autoResetSortBy, manualPagination, autoResetPage };
  if (pagination) {
    tableOptions.initialState = {
      ...tableOptions.initialState,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageIndex,
    };
    tableOptions.pageCount = pagination.pageCount;
  }
  const tableInstance = useTable<T>(tableOptions, ...plugins);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy, pageIndex },
    gotoPage,
  } = tableInstance;

  useEffect(() => {
    onSortByChanged(sortBy);
  }, [onSortByChanged, sortBy]);

  const handlePageIndexChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    gotoPage(page - 1);
    if (pagination) {
      pagination.onPageIndexChanged(page - 1);
    }
  };

  return (
    <TableContainer data-testid={dataTestid} className={className}>
      <StyledTableRoot>
        <Scrollbar data-testid="EnhancedTable-Scrollbar">
          <MaUTable {...getTableProps()} size="small" className={classes.table}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...(column.id === "selection" || column.disableSortBy
                        ? column.getHeaderProps()
                        : column.getHeaderProps([
                            {
                              className: column.className,
                              style: column.style,
                            },
                            column.getSortByToggleProps({
                              title: t("EnhancedTable.ToggleSortBy", "Sort by column"),
                            }),
                          ]))}
                    >
                      {column.id === "selection" && ActionMenuItems ? (
                        <TableActionMenu
                          ActionMenuItems={ActionMenuItems}
                          selectedFlatRows={selectedFlatRows}
                          isLoading={isLoading}
                        />
                      ) : (
                        column.render("Header")
                      )}
                      {column.id !== "selection" && !column.disableSortBy ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <TableRow
                    {...row.getRowProps(getRowProps(row.original))}
                    {...row.getToggleRowExpandedProps()}
                    title=""
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            className: cell.column.className,
                            style: cell.column.style,
                          })}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaUTable>
        </Scrollbar>

        {pagination && pagination.pageCount > 1 && (
          <StyledPagination page={pageIndex + 1} count={pagination.pageCount} onChange={handlePageIndexChanged} />
        )}
      </StyledTableRoot>
    </TableContainer>
  );
}

export default EnhancedTable;
