import { Icon } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { FC } from "react";
import { styled } from "@mui/material/styles";

const StyledIcon = styled(Icon)(() => ({
  verticalAlign: "-2px",
}));

const ExpandIcon: FC<{ expanded: boolean }> = ({ expanded }) => {
  return <StyledIcon fontSize="small">{expanded ? <ArrowDropUp /> : <ArrowDropDown />}</StyledIcon>;
};

export default ExpandIcon;
