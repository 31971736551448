import type { FC } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { PasswordField } from "../../../components/formFields";
import { ResetPasswordFormValues, getValidationSchema } from "../formValidations/resetPassword";
import useAxios from "../../../lib/useAxios";
import PasswordStrengthMeter from "../../login/components/PasswordStrengthMeter";

interface LostPasswordFormProps {
  onSuccessfulSubmit?: () => void;
  token: string;
}

const useSetNewPassword = () => {
  const axios = useAxios();
  return async (newPassword: string, token: string): Promise<boolean> => {
    try {
      // Response is always a 200, unless something is really broken.
      await axios.post(
        "/Auth/ResetPassword",
        { newPassword },
        {
          params: { token },
        }
      );
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn("Error on reset password:", err);
      return false;
    }
  };
};

const ResetPasswordForm: FC<LostPasswordFormProps> = ({ token, onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const setNewPassword = useSetNewPassword();
  const initialFormValues: ResetPasswordFormValues = {
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const success = await setNewPassword(values.newPassword, token);
      setStatus({ success });
      setSubmitting(false);
      if (success && onSuccessfulSubmit) {
        onSuccessfulSubmit();
      } else {
        enqueueSnackbar(t("Common.UnspecifiedError"), {
          variant: "error",
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <PasswordField
        formik={formik}
        autoComplete="new-password"
        label={t("Forms.Password")}
        name="newPassword"
        required
      />
      <PasswordField
        formik={formik}
        autoComplete="off"
        label={t("Forms.PasswordConfirm")}
        name="newPasswordConfirm"
        required
      />
      <PasswordStrengthMeter password={formik.values.newPassword} />
      <Button
        data-testid="ResetPasswordForm-Reset"
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {t("Settings.SetPasswordBtn")}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
