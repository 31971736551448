import type { FC } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import DashboardWidget from "../components/DashboardWidget";
import StatusBadge from "../components/StatusBadge";
import { GetDashboardDataQuery } from "../../../graphql/generated/generatedGraphQL";

const StyledBox = styled(Box)(() => ({
  alignItems: "center",
  flexDirection: "column",
  display: "flex",
}));

function statusToPriority(data: GetDashboardDataQuery) {
  if (data.securityStatus?.recommendedActionsHigh && data.securityStatus.recommendedActionsHigh > 0) {
    return "high";
  }
  if (data.securityStatus?.recommendedActionsMedium && data.securityStatus.recommendedActionsMedium > 0) {
    return "medium";
  }
  if (data.securityStatus?.recommendedActionsLow && data.securityStatus.recommendedActionsLow > 0) {
    return "low";
  }
  if (data.allStatistics?.clientCount === 0) {
    return "noClients";
  }
  return "none";
}

function getPriorityStatusText(priority: string, t: TFunction): { line1: string; line2: string } {
  let line1 = "";
  let line2 = "";
  switch (priority) {
    case "high":
      line1 = t("Dashboard.ParticipationIsRequired");
      line2 = t("Dashboard.RecommendationsWithHighPriority");
      break;
    case "medium":
      line1 = t("Dashboard.ParticipationIsRequired");
      line2 = t("Dashboard.RecommendationsWithMediumPriority");
      break;
    case "low":
      line1 = t("Dashboard.AllDevicesAreProtected");
      line2 = t("Dashboard.RecommendationsWithLowPriority");
      break;
    case "noClients":
      line1 = t("Dashboard.ParticipationIsRequired");
      line2 = t("Dashboard.NoDevicesMonitored");
      break;
    default:
      line1 = t("Dashboard.AllDevicesAreProtected");
      break;
  }
  return { line1, line2 };
}

/**
 * Widget displays the current security-status, based on the highest recommendation priority
 */
const SecurityStatusWidget: FC<{ data: GetDashboardDataQuery }> = ({ data }) => {
  const { t } = useTranslation();

  const priority = statusToPriority(data);
  const statusText = getPriorityStatusText(priority, t);

  return (
    <DashboardWidget title={t("Dashboard.SecurityStatus")} size="1x1">
      <StyledBox data-testid="SecurityStatusWidget-statusBox">
        <StatusBadge priority={priority} />
        <Typography variant="body2" align="center" fontWeight={600}>
          {statusText.line1}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          {statusText.line2}
        </Typography>
      </StyledBox>
    </DashboardWidget>
  );
};

export default SecurityStatusWidget;
