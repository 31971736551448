import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function useRecommendedActionsSnackbar() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useMemo(
    () => ({
      snackbarSuccess: () => {
        enqueueSnackbar(t("RecommendedActions.Updated", "The recommended actions have been successfully updated."), {
          variant: "success",
        });
      },
      snackbarError: () => {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      },
    }),
    [enqueueSnackbar, t]
  );
}

export default useRecommendedActionsSnackbar;
