import { styled, ToggleButton } from "@mui/material";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  height: theme.spacing(4),
  borderRadius: theme.spacing(2),
  fontWeight: theme.typography.fontWeightRegular,
  "&:not(:first-of-type)": {
    marginLeft: theme.spacing(2),
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: "1px solid rgba(0,0,0,0)",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "&:not(.Mui-selected)": {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

export default StyledToggleButton;
