import { useState } from "react";
import type { FC } from "react";
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import * as React from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";
import { AuthContainer } from "../hooks/auth";
import { ThemeSelectControl } from "../pages/settings/components/ThemeSelectControl";
import { useGetOwnUserQuery } from "../graphql/generated/generatedGraphQL";
import { useNavigate } from "react-router-dom";

const AccountPopover: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = useGetOwnUserQuery();
  const { logout } = AuthContainer.useContainer();
  const navigate = useNavigate();
  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar />
          <ListItemText primary={`${user.data?.selfUser.firstName} ${user.data?.selfUser.lastName} `} />
        </MenuItem>
        <Divider />
        <MenuItem>
          <ThemeSelectControl />
        </MenuItem>
        <MenuItem onClick={() => navigate("/settings/security")}>
          <ListItemIcon>
            <LockOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Settings.ChangePassword")} />
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNewIcon fontSize="small" />
          </ListItemIcon>
          {t("Common.Logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
export default AccountPopover;
