import { ArrowDropDown, CheckCircleOutline } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";
import { FormikProps, getIn } from "formik";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../components/formFields";
import LanguageText from "../../../components/LanguageText";
import { Language } from "../../../types/Language";
import { ICultureSpecifics } from "./ICultureSpecifics";

type Props<T extends ICultureSpecifics> = {
  formik: FormikProps<T>;
  language: Language;
  languageIndices: Record<Language, number>;
  required?: boolean;
};

const StyledAccordion = styled(Accordion, { name: "StyledAccordion" })(({ theme }) => ({
  marginBlock: theme.spacing(2),
}));

const StyledAccordionSummary = styled(AccordionSummary, { name: "StyledAccordionSummary" })(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledAccordionDetails = styled(AccordionDetails, { name: "StyledAccordionDetails" })(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledLanguageText = styled(LanguageText, { name: "StyledLanguageText" })(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const FormFieldsForLanguage = <T extends ICultureSpecifics>({
  formik,
  language,
  languageIndices,
  required,
}: Props<T>) => {
  const { t } = useTranslation();
  const summaryField = `cultureSpecifics[${languageIndices[language]}].summary`;
  const descriptionField = `cultureSpecifics[${languageIndices[language]}].description`;
  const summary = getIn(formik.values, summaryField);
  const description = getIn(formik.values, descriptionField);
  const incomplete = !summary.trim() || !description.trim();
  return (
    <StyledAccordion disableGutters defaultExpanded={required}>
      <StyledAccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls={`${language.toString()}-content`}
        id={`${language.toString()}-header`}
      >
        <CheckCircleOutline color={incomplete ? "disabled" : "success"} />
        <StyledLanguageText language={language} />
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <TextField
          formik={formik}
          name={summaryField}
          label={t("RecommendedActions.Templates.Summary")}
          hideHelperText
          required={required}
          dataTestid={`NewTemplateForm-Title${language.toString()}`}
        />
        <TextField
          multiline
          rows={5}
          formik={formik}
          name={descriptionField}
          label={t("RecommendedActions.Templates.Description")}
          hideHelperText
          required={required}
          dataTestid={`NewTemplateForm-Description${language.toString()}`}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default FormFieldsForLanguage;
