import { VFC } from "react";
import { useTranslation } from "react-i18next";
import EmptyAutoComplete from "./EmptyAutocomplete";
import QueryComboBoxMultiple, { QueryComboBoxProps } from "../../../components/QueryComboBoxMultiple";
import { Client, useAllClients } from "../hooks/useAllClients";

type ClientQueryAndComboBoxProps<T> = Omit<QueryComboBoxProps<T>, "queryResult"> & {
  mavId: string;
};

const ClientQueryAndComboBox: VFC<ClientQueryAndComboBoxProps<Client>> = ({ mavId, value, ...rest }) => {
  const queryResult = useAllClients({ input: mavId });

  // get deviceId if id is unkown
  value =
    value?.map((v) => {
      if (v.id == -31337) return queryResult.data?.find((item) => item.name === v.name) ?? { id: 0, name: "no match" };
      return v;
    }) ?? [];

  return (
    <QueryComboBoxMultiple {...rest} value={value} queryResult={queryResult} getOptionLabel={(client) => client.name} />
  );
};

type ClientComboBoxProps<T> = Omit<QueryComboBoxProps<T>, "label" | "queryResult"> & {
  mavId: string | null;
};

const ClientComboBox = (props: ClientComboBoxProps<Client>): ReturnType<VFC> => {
  const { t } = useTranslation();

  const { mavId, onChange, defaultValue, multiple, value, ...rest } = props;
  if (mavId === null) {
    return <EmptyAutoComplete {...rest} label={t("Clients.Name")} />;
  }
  return (
    <ClientQueryAndComboBox
      {...rest}
      mavId={mavId}
      onChange={onChange}
      defaultValue={defaultValue}
      multiple={multiple}
      value={value}
      limitTags={3}
      label={t("Clients.Name")}
    />
  );
};
export default ClientComboBox;
