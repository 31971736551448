import axios, { AxiosInstance } from "axios";

const createAxios = (baseURL: string): AxiosInstance => {
  const axiosInstance = axios.create({ baseURL });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 400) {
        localStorage.removeItem("tokens");
        window.location.pathname = "/login";
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxios;
