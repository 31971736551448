import { Check } from "@mui/icons-material";
import { styled, ToggleButtonProps } from "@mui/material";
import { FC } from "react";
import StyledToggleButton from "./StyledToggleButton";

const StyledToggleButtonWithCheckIconStyle = styled(StyledToggleButton)(() => ({
  "&:not(.Mui-selected)": {
    "&>svg:first-of-type": {
      display: "none",
    },
    "&>div.priority": {
      paddingLeft: "13px",
      paddingRight: "11px",
    },
    "&>div.state": {
      paddingLeft: "13.5px",
      paddingRight: "13.5px",
    },
  },
  "&.Mui-selected": {
    paddingLeft: "10px",
    paddingRight: "10px",
    "&>div.state": {
      paddingRight: "3px",
    },
  },
}));

const StyledCheckIcon = styled(Check)(() => ({
  height: "20px",
  width: "20px",
  marginRight: "6px",
}));

const StyledContainer = styled("div", { name: "StyledContainer" })(() => ({}));

interface CheckedToggleButtonProps extends ToggleButtonProps {
  containerclassname?: string;
}

const StyledToggleButtonWithCheckIcon: FC<CheckedToggleButtonProps> = (props) => {
  return (
    <StyledToggleButtonWithCheckIconStyle
      data-testid={"StyledToggleButtonWithCheckIcon-TableActionBar-" + props.value}
      {...props}
    >
      <StyledCheckIcon />
      <StyledContainer className={props.containerclassname}>{props.children}</StyledContainer>
    </StyledToggleButtonWithCheckIconStyle>
  );
};

export default StyledToggleButtonWithCheckIcon;
