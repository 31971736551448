import * as Yup from "yup";
import { SchemaOf } from "yup";

export const getValidationSchema = (): SchemaOf<unknown> => {
  return Yup.object()
    .shape({
      mavId: Yup.string().required(),
      clients: Yup.array().required(),
    })
    .noUnknown(false);
};
