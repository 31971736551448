import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContainer } from "./auth";
import { KeycloakRoles } from "./auth/IAuthentication";
import { getRegNumber } from "../types/URLSearchParams";
import { Role } from "../types/Role";
import { useGetMavIdFromRegnumberQuery } from "../graphql/generated/generatedGraphQL";

type UserContext = {
  isRoleReseller: boolean;
  isRoleAnalyst: boolean;
  inspectingTenant?: string;
};

function hasRole(realmAccess: KeycloakRoles | undefined, role: Role) {
  return !!realmAccess?.roles.some((element) => element === role);
}

export default function useUserContext(): UserContext {
  const { realmAccess } = AuthContainer.useContainer();
  const isRoleReseller = hasRole(realmAccess, "Reseller");
  const isRoleAnalyst = hasRole(realmAccess, "Analyst");

  const [searchParams] = useSearchParams();
  const regNumber = getRegNumber(searchParams);
  const resellerAndRegNumber = isRoleReseller && !!regNumber;
  const regNumberQueryResult = useGetMavIdFromRegnumberQuery(
    { input: regNumber || "" },
    { enabled: resellerAndRegNumber }
  );
  useEffect(() => {
    regNumberQueryResult.data?.resolveTenantsFromRegnumber?.values();
  }, [regNumberQueryResult.data?.resolveTenantsFromRegnumber]);

  const mavIds = regNumberQueryResult?.data?.resolveTenantsFromRegnumber || [];

  const inspectingTenant = resellerAndRegNumber && mavIds.length > 0 ? (mavIds[0].mavId as string) : undefined;

  return useMemo(() => {
    return {
      isRoleReseller,
      isRoleAnalyst,
      inspectingTenant,
    };
  }, [isRoleReseller, isRoleAnalyst, inspectingTenant]);
}
