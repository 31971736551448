import { useQuery, UseQueryResult } from "react-query";
import {
  ClientsWithPagingDocument,
  ClientsWithPagingQuery,
  ClientsWithPagingQueryVariables,
  ClientTenantDataFragment,
  useClientsWithPagingQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { useFetcher } from "../../../graphql/useAxiosFetcher";
import useUserContext from "../../../hooks/useUserContext";
import { Client } from "../../../types/client";
import { createSignatureViewModel } from "../../../types/signature";

function createClientTenantViewModel(clientTenant: ClientTenantDataFragment | undefined): Client {
  return {
    name: clientTenant?.name || "",
    tenantName: clientTenant?.tenantName || "",
    status: {
      osVersion: clientTenant?.clientState?.osName || "",
      clientVersion: clientTenant?.clientState?.clientVersion || "",
      signatures: clientTenant?.clientState?.signatures.map(createSignatureViewModel) || [],
    },
  };
}

function useCustomFetcher() {
  const fetcher = useFetcher<ClientsWithPagingQuery, unknown>(ClientsWithPagingDocument);

  return (variables?: ClientsWithPagingQueryVariables) => {
    return fetcher(variables).then(
      (response) => response.allClientsWithPaging?.items?.map(createClientTenantViewModel) || []
    );
  };
}

export default function useClientsWithPaging(variables?: ClientsWithPagingQueryVariables): UseQueryResult<Client[]> {
  const fetcher = useCustomFetcher();
  const userContext = useUserContext();

  let v = variables;

  if (userContext.inspectingTenant) {
    v = {
      ...v,
      mavId: userContext.inspectingTenant,
    };
  }

  const result = useQuery<Client[]>(useClientsWithPagingQuery.getKey(v), fetcher.bind(null, v));
  return result;
}
