import { Priority } from "../graphql/generated/generatedGraphQL";

const prioritySortOrder: Record<Priority, number> = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
};

function comparePriority(a: Priority, b: Priority): number {
  return Math.sign(prioritySortOrder[a] - prioritySortOrder[b]);
}

export function sortPriorities(priorities: Priority[]): Priority[] {
  return [...priorities].sort((a, b) => comparePriority(a, b));
}
