import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import RegisterForm from "../components/RegisterForm";
import { User } from "../../../types/user";
import { StyledPaperWidth960 } from "../../../theme/sharedStyles";

interface RegistrationProps {
  prefillUserData: User;
  token: string;
  onSuccessfulSubmit?: () => void;
}

const Registration: FC<RegistrationProps> = ({ prefillUserData, token, onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  return (
    <StyledPaperWidth960>
      <Typography variant="h1" sx={{ mb: 3 }}>
        {t("Registration.Welcome", {
          defaultValue: "Welcome, {{name}}.",
          firstName: prefillUserData.firstName,
          lastName: prefillUserData.lastName,
        })}
      </Typography>
      <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
        {t("Registration.Headline", "Your registration")}
      </Typography>
      <Typography variant="body2" color="textSecondary" width="50%">
        {t(
          "Registration.Subheader",
          "Simply fill out the form - and you'll get access to your personal security dashboard."
        )}
      </Typography>
      <Box sx={{ mt: 6 }}>
        <RegisterForm prefill={prefillUserData} token={token} onSuccessfulSubmit={onSuccessfulSubmit} />
      </Box>
    </StyledPaperWidth960>
  );
};

export default Registration;
