import { VFC } from "react";
import { InRowMenuItemsProps } from "../../../components/table/InRowMenu";
import InRowResolveWithConfirmation from "../../../components/table/InRowResolveWithConfirmation";
import InRowUnresolve from "../../../components/table/InRowUnresolve";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";

type Props = InRowMenuItemsProps<RecommendedActionViewModel>;

const InRowMenuItems = ({ row }: Props): ReturnType<VFC> => {
  return (
    <>
      <InRowUnresolve row={row} />
      <InRowResolveWithConfirmation row={row} />
    </>
  );
};

export default InRowMenuItems;
