import { VFC } from "react";
import { useTranslation } from "react-i18next";

type YesNoProps = { value: string | boolean | undefined };

const YesNo: VFC<YesNoProps> = ({ value }) => {
  const { t } = useTranslation();

  if (value?.toString() === "true") return t("Common.Yes");
  else return t("Common.No");
};

export default YesNo;
