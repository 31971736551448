import type { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/layouts/dashboard/DashboardLayout";
import Dashboard from "./pages/dashboard/Overview";
import DefaultLayout from "./components/layouts/default/DefaultLayout";
import Account from "./pages/settings/Account";
import Register from "./pages/login/Register";
import Login from "./pages/login/Login";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import Security from "./pages/settings/Security";
import LostPassword from "./pages/lostPassword/LostPassword";
import ResetPassword from "./pages/lostPassword/ResetPassword";
import RecommendedActionsOverview from "./pages/recommendedActions/Overview";
import RecommendedActionDetail from "./pages/recommendedActions/Detail";
import ClientIndex from "./pages/clients";
import TemplatesOverview from "./pages/recommendedActionTemplates/TemplatesOverview";
import NewTemplate from "./pages/recommendedActionTemplates/NewTemplate";
import AssignTemplate from "./pages/recommendedActionTemplates/AssignTemplate";
import EditTemplate from "./pages/recommendedActionTemplates/EditTemplate";
import RecommendedActionsForAnalystsIndex from "./pages/recommendedActionsAnalyst/RecommendedActionsForAnalystsIndex";
import SelectedTenantGuard from "./components/SelectedTenantGuard";
import FindingsOverview from "./pages/findings";

const routes: RouteObject[] = [
  {
    path: "*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "*",
        element: <SelectedTenantGuard />,
        children: [
          {
            path: "",
            element: <Navigate to="/dashboard" />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "recommendedActions",
            children: [
              {
                path: "",
                element: <RecommendedActionsOverview />,
              },
              {
                path: ":id",
                element: <RecommendedActionDetail />,
              },
            ],
          },
          {
            path: "analysis",
            children: [
              {
                path: "recommendedActions",
                element: <RecommendedActionsForAnalystsIndex />,
              },
              {
                path: "findings",
                element: <FindingsOverview />,
              },
              {
                path: "recommendedActionTemplates",
                children: [
                  {
                    path: "",
                    element: <TemplatesOverview />,
                  },
                  {
                    path: "create",
                    element: <NewTemplate />,
                  },
                  {
                    path: ":id",
                    children: [
                      {
                        path: "",
                        element: <EditTemplate />,
                      },
                      {
                        path: "createRecommendedAction",
                        element: <AssignTemplate />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            children: [
              {
                path: "account",
                element: <Account />,
              },
              {
                path: "security",
                element: <Security />,
              },
            ],
          },
          {
            path: "clients",
            element: <ClientIndex />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <DefaultLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register/:token",
        element: <Register />,
      },
      {
        path: "lost-password",
        children: [
          {
            path: "",
            element: <LostPassword />,
          },
          {
            path: "reset/:token",
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
];

export default routes;
