import { useEffect } from "react";
import { createContainer } from "unstated-next";
import type { ThemeType } from "../theme";
import usePersistedState from "./usePersistedState";

/**
 * LocalSettings
 * Temporary solution until we can store settings in the user DB.
 * Store only non-sensitive settings in LocalStorage!
 */

interface LocalSettings {
  theme: ThemeType;
}

const INITIAL_STATE: LocalSettings = {
  theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "DARK" : "LIGHT",
};

function useLocalSettings(initialState = INITIAL_STATE) {
  const [state, setState] = usePersistedState("localSettings", initialState);

  useEffect(() => {
    if (state.theme === "DARK") {
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
    }
  }, [state]);

  const setTheme = (theme: ThemeType) => {
    setState({ theme });
  };

  return {
    settings: state,
    setTheme,
  };
}

const LocalSettingsContainer = createContainer(useLocalSettings);
export default LocalSettingsContainer;
