import { useCallback, VFC } from "react";
import useTableSortBy, { MapColumnToField } from "../../../components/table/useTableSortBy";
import {
  RecommendedActionSortInput,
  RecommendedActionTemplateQuery,
  SortEnumType,
} from "../../../graphql/generated/generatedGraphQL";
import useTableFilter from "../../recommendedActionsAnalyst/hooks/useTableFilter";
import LastRecommendedActions from "./LastRecommendedActions";
import CreateRecommendedActionFromTemplateForm from "./CreateRecommendedActionFromTemplateForm";

const columnsToGraphQLFields: MapColumnToField<RecommendedActionSortInput> = {
  tenantName: "tenantName",
  priority: "priority",
  client: "clientName",
  createdAt: "createdAt",
  createdByUserFullName: "createdByUserFullName",
  resolvedAt: "resolvedAt",
  resolvedByUserFullName: "resolvedByUserFullName",
};

const defaultSortOrder: RecommendedActionSortInput[] = [
  { state: SortEnumType.Asc },
  { priority: SortEnumType.Desc },
  { createdAt: SortEnumType.Desc },
];

type CreateRecommendedActionFromTemplateContainerProps = {
  data: {
    recommendedActionTemplateQuery: RecommendedActionTemplateQuery;
  };
};

const CreateRecommendedActionFromTemplateContainer: VFC<CreateRecommendedActionFromTemplateContainerProps> = ({
  data,
}) => {
  const filter = useTableFilter();
  const { order, onSortByChanged } = useTableSortBy<RecommendedActionSortInput>(
    columnsToGraphQLFields,
    defaultSortOrder
  );
  const onTenantChanged = useCallback(
    (mavId?: string) => {
      filter.setWhereMavIdEquals(mavId ?? "");
    },
    [filter]
  );

  return (
    <>
      <CreateRecommendedActionFromTemplateForm data={data} onTenantChanged={onTenantChanged} />
      <LastRecommendedActions onSortByChanged={onSortByChanged} order={order} filter={filter} />
    </>
  );
};

export default CreateRecommendedActionFromTemplateContainer;
