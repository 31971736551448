import type { FC } from "react";
import { Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useSnackbar } from "notistack";
import { AuthContainer } from "../../../hooks/auth";
import { PasswordField, TextField } from "../../../components/formFields";
import { LoginFormValues, getValidationSchema } from "../formValidations/login";
import { AxiosError } from "axios";

interface LoginFormProps {
  onSuccessfulSubmit?: () => void;
}
/**
 *  Stand alone Login-Form
 */
const LoginForm: FC<LoginFormProps> = ({ onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { login } = AuthContainer.useContainer();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: LoginFormValues = {
    email: "",
    password: "",
  };
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await login(values.email, values.password);
        setStatus({ success: true });
        setSubmitting(false);
        if (onSuccessfulSubmit) {
          onSuccessfulSubmit();
        }
      } catch (err: unknown) {
        const error = err as AxiosError;
        // eslint-disable-next-line no-console
        console.warn("Error on login:", error);

        setStatus({ success: false });
        setSubmitting(false);
        if (error) {
          if (error.response?.status === 401) {
            // login/password invalid
            setErrors({
              password: t("Login.LoginIncorrect", "Username or password incorrect."),
            });
          } else {
            // server error
            enqueueSnackbar(t("Login.ServerError"), {
              variant: "error",
            });
          }
        }
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        formik={formik}
        label={t("Forms.UsernameOrMail")}
        name="email"
        type="email"
        autoComplete="username"
        required
        icon={<PersonIcon />}
        InputLabelProps={{ required: false }}
      />
      <PasswordField
        formik={formik}
        label={t("Forms.Password")}
        name="password"
        autoComplete="current-password"
        required
        InputLabelProps={{ required: false }}
      />
      <Button
        data-testid="LoginForm-Login"
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {t("Login.LoginBtn", "Login")}
      </Button>
      <Typography variant="body2" align="center" pt={2}>
        <Link data-testid="LoginForm-lostPassword" href="/lost-password" underline="none">
          {t("Login.ForgotPassword", "Forgot your password?")}
        </Link>
      </Typography>
    </form>
  );
};

export default LoginForm;
