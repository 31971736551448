import type { FC } from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import { PasswordField, TextField } from "../../../components/formFields";
import { RegisterFormValues, getValidationSchema } from "../formValidations/register";
import useAxios from "../../../lib/useAxios";
import { getLanguage } from "../../../locales";

interface RegisterFormProps {
  onSuccessfulSubmit?: () => void;
  token: string;
  prefill?: { email: string; firstName: string; lastName: string; phone: string };
}

const useRegister = () => {
  const axios = useAxios();
  const { i18n } = useTranslation();
  const language = getLanguage(i18n);
  return async (token: string, regFormValues: RegisterFormValues): Promise<boolean> => {
    if (token.length === 0) {
      return Promise.reject();
    }

    const response = await axios.post(`/Auth/Register/${token}`, {
      password: regFormValues.password,
      firstName: regFormValues.firstName,
      lastName: regFormValues.lastName,
      phone: regFormValues.phone,
      language: language,
    });
    return response.status === 200;
  };
};

const RegisterForm: FC<RegisterFormProps> = ({ prefill, token, onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const register = useRegister();
  const initialFormValues: RegisterFormValues = {
    email: prefill ? prefill.email : "",
    firstName: prefill ? prefill.firstName : "",
    lastName: prefill ? prefill.lastName : "",
    password: "",
    passwordConfirm: "",
    phone: prefill ? prefill.phone : "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      register(token, values)
        .then((success) => {
          setStatus({ success });
          setSubmitting(false);
          if (success && onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), {
            variant: "error",
          });
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <TextField
            dataTestid="RegisterForm-UsernameField"
            formik={formik}
            label={t("Forms.UsernameOrMail", "Username / E-Mail")}
            name="email"
            autoComplete="username"
            disabled
            icon={<MailOutlineIcon color="disabled" />}
          />
          <TextField
            dataTestid="RegisterForm-FirstNameField"
            formik={formik}
            label={t("Forms.FirstName", "First name")}
            name="firstName"
            autoComplete="given-name"
            required
            icon={<PersonIcon color="inherit" />}
          />
          <TextField
            dataTestid="RegisterForm-LastNameField"
            formik={formik}
            label={t("Forms.LastName", "Last name")}
            name="lastName"
            autoComplete="family-name"
            required
            icon={<PersonIcon color="inherit" />}
          />
          <TextField
            dataTestid="RegisterForm-PhoneField"
            formik={formik}
            label={t("Forms.Phone", "Phone")}
            name="phone"
            type="tel"
            autoComplete="tel"
            icon={<CallIcon color="inherit" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordField
            dataTestid="RegisterForm-PasswordField"
            formik={formik}
            label={t("Forms.Password")}
            name="password"
            autoComplete="new-password"
            required
          />
          <PasswordField
            dataTestid="RegisterForm-PasswordConfirmField"
            formik={formik}
            label={t("Forms.PasswordConfirm")}
            name="passwordConfirm"
            required
          />
          <PasswordStrengthMeter password={formik.values.password} />
          <Button
            data-testid="RegisterForm-Register"
            color="primary"
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {t("Forms.ButtonApply")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterForm;
