import { FC } from "react";
import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import createI18nInstance from "./createI18nInstance";
import routes from "./routes";
import { createTheme } from "./theme";
import LocalSettingsContainer from "./hooks/LocalSettingsContainer";
import { AuthContainer, useResourceOwnerPasswordGrant } from "./hooks/auth";
import { SharedStylesRoot } from "./theme/sharedStyles";

const i18n = createI18nInstance();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = LocalSettingsContainer.useContainer();
  const authentication = useResourceOwnerPasswordGrant();

  const theme = createTheme({
    responsiveFontSizes: true,
    roundedCorners: false,
    theme: settings.theme,
  });

  const style = document.createElement("link");
  style.id = "findingsStyle";
  style.rel = "stylesheet";
  style.href = process.env.REACT_APP_API_URL?.includes("production")
    ? "https://gdata-mav-production-api.azurewebsites.net/production/css/findings-overview.css"
    : "https://gdata-mav-staging-api.azurewebsites.net/staging/css/findings-overview.css";
  if (!document.getElementById(style.id)) document.head.append(style);

  const script = document.createElement("script");
  script.id = "findingsScript";
  script.src = style.href = process.env.REACT_APP_API_URL?.includes("production")
    ? "https://gdata-mav-production-api.azurewebsites.net/production/js/findings-overview.js"
    : "https://gdata-mav-staging-api.azurewebsites.net/staging/js/findings-overview.js";
  if (!document.getElementById(script.id)) document.body.append(script);

  return (
    <AuthContainer.Provider value={authentication}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ whiteSpace: "pre-line" }}
          >
            <CssBaseline />
            <SharedStylesRoot>{content}</SharedStylesRoot>
          </SnackbarProvider>
        </I18nextProvider>
      </ThemeProvider>
    </AuthContainer.Provider>
  );
};

export default App;
