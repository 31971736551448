import { FC, useCallback, useMemo } from "react";
import { UseQueryResult } from "react-query";
import { LinearProgress } from "@mui/material";
import mapValues from "lodash/mapValues";
import { ErrorAlert, NoDataAlert } from "./ErrorAlert";
import { PageContainer } from "../theme/sharedStyles";
import Maintainance from "./Maintainance";

// https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
type UseQueryResults<TData> = {
  [Property in keyof TData]: UseQueryResult<TData[Property], unknown>;
};

export type IContentProps<TData> = {
  data: TData;
};

interface IPageProps<TData> {
  Title?: FC;
  Content: FC<IContentProps<TData>>;
  queryResults: UseQueryResults<TData>;
  className?: string;
}

// Substates
// * a query is loading (no data is cached)
// * a query is fetching (data in cache is refreshed)
// * an error occurred => Maintainance
// * a query didn't return data

// We only handle some combinations:
// * a query is loading
// * a query didn't return data
//   * an error occurred => Maintainance
//   * no error occured
// * all queries returned data
//   * an error occurred => Maintainance
//   * no error occured
function Page<TData>({ Title, Content, queryResults, className }: IPageProps<TData>): JSX.Element {
  const results = useMemo(() => Object.values(queryResults) as UseQueryResult<unknown>[], [queryResults]);
  const retry = useCallback(() => {
    const failedResults = results.filter((r) => r.error || !r.data);
    failedResults.forEach((r) => r.refetch());
  }, [results]);

  if (results.some((r) => r.isLoading)) {
    return (
      <PageContainer className={className}>
        {Title && <Title />}
        <LinearProgress data-testid="page-progressbar" />
      </PageContainer>
    );
  }

  const isError = results.some((r) => r.error);

  if (!results.every((r) => r.data)) {
    return (
      <PageContainer className={className}>
        {Title && <Title />}
        {isError ? <Maintainance /> : <NoDataAlert retry={retry} />}
      </PageContainer>
    );
  }

  const data = mapValues(queryResults, (r) => r.data) as TData;

  return (
    <PageContainer className={className}>
      {Title && <Title />}
      {isError && <ErrorAlert retry={retry} />}
      <Content data={data} />
    </PageContainer>
  );
}

export default Page;
