import type { FC } from "react";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import { styled } from "@mui/material/styles";
import { SharedStylesRoot } from "../../theme/sharedStyles";
import { useGetOwnUserQuery } from "../../graphql/generated/generatedGraphQL";
import LoginToPartnerPortal from "./components/LoginToPartnerPortal";

const classes = {
  page: "emptyState-page",
};

const StyledPageRootEmptyState = styled(SharedStylesRoot)(() => ({
  [`& .${classes.page}`]: {
    paddingTop: 0,
    paddingLeft: 23,
    paddingRight: 23,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
}));

const EmptyState: FC = () => {
  const queryResults = {
    user: useGetOwnUserQuery(),
  };

  return (
    <StyledPageRootEmptyState>
      <Scrollbar data-testid="overview-scrollbar">
        <Page Content={LoginToPartnerPortal} queryResults={queryResults} className={classes.page} />
      </Scrollbar>
    </StyledPageRootEmptyState>
  );
};

export default EmptyState;
