import type { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DefaultFooter from "./DefaultFooter";
import DefaultNavbar from "./DefaultNavbar";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingTop: 64,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh",
  "&::before": {
    content: "' '",
    display: "block",
  },
}));

const MainLayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  padding: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  "& >.MuiPaper-root": {
    padding: "32px",
  },
}));

const DefaultLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <MainLayoutRoot>
      <DefaultNavbar />
      <MainLayoutContainer>{children || <Outlet />}</MainLayoutContainer>
      <DefaultFooter />
    </MainLayoutRoot>
  );
};

export default DefaultLayout;
