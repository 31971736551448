import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import ClientDownloadFlexbox from "./ClientDownloadFlexbox";
import WindowsClientDownloadCard from "./WindowsClientDownloadCard";
import MacClientDownloadCard from "./MacClientDownloadCard";
import {
  StyledTableDefaultMessageSubtitle,
  StyledTableDefaultMessageTitle,
  StyledFlexPaper,
} from "../../../theme/sharedStyles";
import { InstallPackage, PackageType } from "../../../graphql/generated/generatedGraphQL";

export const SecurityClientDownloads: VFC<{ securityClientDownloads: InstallPackage[] }> = ({
  securityClientDownloads,
}) => {
  const { t } = useTranslation();
  const windowsClientDownload = securityClientDownloads.find(
    (installPackage) => installPackage.type === PackageType.Windows
  );
  const macClientDownload = securityClientDownloads.find((installPackage) => installPackage.type === PackageType.Mac);

  return (
    <>
      {securityClientDownloads.length > 0 ? (
        <ClientDownloadFlexbox>
          <WindowsClientDownloadCard downloadUrl={windowsClientDownload?.downloadUrl} />
          <MacClientDownloadCard downloadUrl={macClientDownload?.downloadUrl} />
          {/* TODO: Add other security client downloads */}
        </ClientDownloadFlexbox>
      ) : (
        <StyledFlexPaper>
          <Container>
            <StyledTableDefaultMessageTitle variant="h3">
              {t("Clients.PackageNotAvailable")}
            </StyledTableDefaultMessageTitle>
            <StyledTableDefaultMessageSubtitle variant="h4">
              {t("Clients.PackageNotAvailableDescription")}
            </StyledTableDefaultMessageSubtitle>
          </Container>
        </StyledFlexPaper>
      )}
    </>
  );
};
