import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useCreateOrUpdateUserSettingsMutation,
  useGetOwnUserQuery,
  UserSettings,
} from "../../../graphql/generated/generatedGraphQL";
import { useQueryClient } from "react-query";

export interface UserSettingsProps {
  data: UserSettings | null | undefined;
}

const SendSecurityReportsFormControl: FC<UserSettingsProps> = ({ data }) => {
  const { t } = useTranslation();
  const { mutateAsync } = useCreateOrUpdateUserSettingsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = {
      userId: data?.userId,
      sendSecurityReports: event?.target.checked,
    };

    try {
      await mutateAsync({ input });
      queryClient.invalidateQueries(useGetOwnUserQuery.getKey());
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          name="sendSecurityReports"
          color="primary"
          checked={data?.sendSecurityReports ?? true}
          onChange={handleChange}
        />
      }
      label={t("Settings.SendSecurityReports", "Status report by email").toString()}
    />
  );
};

export default SendSecurityReportsFormControl;
