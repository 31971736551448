import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Language, unknownLanguage } from "../types/Language";

function useLanguageText(language: Language) {
  const { t } = useTranslation();
  switch (language) {
    case Language.De:
      return t("Language.De");
    case Language.En:
      return `${t("Language.En")} (${t("Common.Required")})`;
    case Language.Pl:
      return t("Language.Pl");
    case Language.None:
      return "NONE";
    default:
      return unknownLanguage(language);
  }
}

type Props = {
  className?: string;
  language: Language;
};

const LanguageText: FC<Props> = ({ className, language }) => {
  const languageText = useLanguageText(language);
  return <span className={className}>{languageText}</span>;
};

export default LanguageText;
