import React, { VFC } from "react";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { UseQueryResult } from "react-query";
import { ErrorAlert, NoDataAlert } from "./ErrorAlert";
import QueryComboBoxCircularProgress from "./QueryComboBoxCircularProgress";

export type QueryComboBoxProps<T> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  "options" | "renderInput"
> & {
  /** Label of the text field */
  label: string;
  /** A react-query query result */
  queryResult: UseQueryResult<readonly T[], unknown>;
  error?: boolean;
};

const QueryComboBox = <T,>({
  label,
  queryResult,
  error,
  autoHighlight = true,
  autoSelect = true,
  ...rest
}: QueryComboBoxProps<T>): ReturnType<VFC> => {
  if (!queryResult.isLoading) {
    if (queryResult.error) {
      return <ErrorAlert retry={queryResult.refetch} />;
    }
    if (!queryResult.data) {
      return <NoDataAlert retry={queryResult.refetch} />;
    }
  }
  const options = queryResult.isLoading ? [] : queryResult.data;

  return (
    <Autocomplete
      {...rest}
      fullWidth
      disablePortal
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {queryResult.isLoading ? <QueryComboBoxCircularProgress /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loading={queryResult.isLoading}
      autoHighlight={autoHighlight}
      autoSelect={autoSelect}
    />
  );
};

export default QueryComboBox;
