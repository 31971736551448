import { useCallback, useState } from "react";
import type { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { ResellerHeader } from "../../ResellerHeader";
import useComponentBehavior from "../../../hooks/useComponentBehavior";
import { getRegNumber } from "../../../types/URLSearchParams";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "relative",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "256px",
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const sideBarClose = useCallback(() => setIsSidebarMobileOpen(false), []);
  const sideBarOpen = useCallback(() => setIsSidebarMobileOpen(true), []);
  const behavior = useComponentBehavior();
  const urlSearchParams = new URLSearchParams(location.search);
  const regNumber = getRegNumber(urlSearchParams);
  return (
    <DashboardLayoutRoot>
      {behavior.ResellerHeader.showResellerHeader ? <ResellerHeader regNumber={regNumber ?? ""} /> : <></>}
      <DashboardNavbar onSidebarMobileOpen={sideBarOpen} />
      <DashboardSidebar onMobileClose={sideBarClose} openMobile={isSidebarMobileOpen} />
      <DashboardLayoutWrapper
        sx={{
          paddingTop: behavior.ResellerHeader.showResellerHeader ? "112px" : "64px",
        }}
      >
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
