import { VFC } from "react";
import RedoIcon from "@mui/icons-material/Redo";
import IconButton from "@mui/material/IconButton";
import { InRowMenuItemsProps } from "./InRowMenu";
import useUnresolveRecommendedAction from "../../pages/recommendedActions/hooks/useUnresolveRecommendedAction";
import { RecommendedActionViewModel } from "../../types/RecommendedAction";
import useComponentBehavior from "../../hooks/useComponentBehavior";

type InRowUnresolveProps = InRowMenuItemsProps<RecommendedActionViewModel>;

const InRowUnresolve = ({ row: row }: InRowUnresolveProps): ReturnType<VFC> => {
  const unresolveRecommendedAction = useUnresolveRecommendedAction();
  const behavior = useComponentBehavior();
  const disabled = row.original.state !== "Solved" || behavior.pages.recommendedActions.inRowMenuDisableUnresolve;
  return (
    <IconButton
      data-testid="InRowUnresolve-UnresolveTableAction"
      disabled={disabled}
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        unresolveRecommendedAction(row.original.id);
      }}
    >
      <RedoIcon fontSize="small" />
    </IconButton>
  );
};

export default InRowUnresolve;
