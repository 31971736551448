import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Toolbar } from "@mui/material";
import type { AppBarProps } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Logo from "../../Logo";
import { AppTitleWithPlan } from "../../AppTitle";
import Navbar from "../../Navbar";
import { AuthContainer } from "../../../hooks/auth";
import useComponentBehavior from "../../../hooks/useComponentBehavior";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen: () => void;
}

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { logout } = AuthContainer.useContainer();
  const behavior = useComponentBehavior();
  return (
    <Navbar sx={{ paddingTop: behavior.ResellerHeader.showResellerHeader ? "48px" : 0 }} {...other} elevation={1}>
      <Toolbar>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} data-testid="DashboardNavbar-OpenSidebar">
            <MenuIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
          <RouterLink to="/">
            <Logo width="30px" />
          </RouterLink>
          <AppTitleWithPlan
            typography={{
              variant: "h1",
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 1 }}>
          {!behavior.ResellerHeader.showResellerHeader && (
            <IconButton data-testid="DashboardNavbar-Logout" color="inherit" onClick={logout}>
              <PowerSettingsNewIcon />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </Navbar>
  );
};

export default DashboardNavbar;
