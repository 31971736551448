import { FC, useEffect } from "react";
import { Box, Typography, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import LoginForm from "./components/LoginForm";
import { StyledPaperWidth480 } from "../../theme/sharedStyles";

const Login: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Clear caches here instead of during logout, to prevent still visible widgets from requerying.
    queryClient.clear();
  }, [queryClient]);

  return (
    <Zoom in timeout={500}>
      <StyledPaperWidth480>
        <Typography variant="h1" sx={{ mb: 1 }}>
          {t("Login.Headline", "Login")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("Login.Subheader", "Your personal customer area - online, simple & convenient.")}
        </Typography>
        <Box sx={{ mt: 8 }}>
          <LoginForm />
        </Box>
      </StyledPaperWidth480>
    </Zoom>
  );
};

export default Login;
