import React, { VFC } from "react";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { UseQueryResult } from "react-query";
import { ErrorAlert, NoDataAlert } from "./ErrorAlert";
import QueryComboBoxCircularProgress from "./QueryComboBoxCircularProgress";

export type QueryComboBoxProps<T> = Omit<
  AutocompleteProps<T, true, undefined, undefined>,
  "options" | "renderInput"
> & {
  /** Label of the text field */
  label: string;
  /** A react-query query result */
  queryResult: UseQueryResult<readonly T[], unknown>;
  error?: boolean;
};

function limitRenderedItems(renderTagsValue: string[], limitTags: number) {
  const more = renderTagsValue.length - limitTags;
  if (more > 0) {
    renderTagsValue = renderTagsValue.slice(0, limitTags);
    renderTagsValue.push(`+${more}`);
  }
  return renderTagsValue;
}

const QueryComboBoxMultiple = <T,>({
  label,
  queryResult,
  error,
  limitTags,
  value,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel = (option: any) => option.label,
  autoHighlight = true,
  ...rest
}: QueryComboBoxProps<T>): ReturnType<VFC> => {
  if (!queryResult.isLoading) {
    if (queryResult.error) {
      return <ErrorAlert retry={queryResult.refetch} />;
    }
    if (!queryResult.data) {
      return <NoDataAlert retry={queryResult.refetch} />;
    }
  }
  const options = queryResult.isLoading ? [] : queryResult.data;

  return (
    <Autocomplete
      {...rest}
      fullWidth
      disablePortal
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {queryResult.isLoading ? <QueryComboBoxCircularProgress /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loading={queryResult.isLoading}
      autoHighlight={autoHighlight}
      multiple
      disableCloseOnSelect
      disableListWrap
      renderTags={(selected) => {
        if (!selected.length) return null;

        let renderTagsValue = selected.map((elem) => getOptionLabel(elem) + ", ");

        if (limitTags && limitTags > 0) {
          renderTagsValue = limitRenderedItems(renderTagsValue, limitTags);
        }
        return renderTagsValue;
      }}
    />
  );
};

export default QueryComboBoxMultiple;
