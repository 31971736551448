import type { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ClientDownloadFlexboxProps {
  children: ReactNode;
}

const StyledBox = styled(Box)(({ theme }) => ({
  gridAutoFlow: "dense",
  display: "flex",
  flexWrap: "wrap",
  gridGap: theme.spacing(4),
}));

const ClientDownloadFlexbox: FC<ClientDownloadFlexboxProps> = ({ children }) => {
  return <StyledBox>{children}</StyledBox>;
};

export default ClientDownloadFlexbox;
