import { RowPropGetter } from "react-table";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import { recommendedActionsTableClasses } from "./RecommendedActionsTableStyles";

const getRecommendedActionRowProps = (row: RecommendedActionViewModel): RowPropGetter<RecommendedActionViewModel> =>
  row.state === "Solved"
    ? {
        className: recommendedActionsTableClasses.isSolved,
      }
    : {};
export default getRecommendedActionRowProps;
