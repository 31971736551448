import type { FC } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SplashScreen from "../../components/SplashScreen";
import TokenNotValid from "./steps/TokenNotValid";
import RegisterComplete from "./steps/RegisterComplete";
import Registration from "./steps/Registration";
import { User } from "../../types/user";
import { useGetUserByToken } from "../../utils";

type RegistrationState = "initLoading" | "registerForm" | "tokenNotValid" | "registerComplete";

const Register: FC = () => {
  const [view, setView] = useState<RegistrationState>("initLoading");
  const [userByToken, setUserByToken] = useState<User>({} as User);
  const { token } = useParams() as { token: string };
  const getUserByToken = useGetUserByToken();

  function handleRegisterComplete() {
    setView("registerComplete");
  }

  useEffect(() => {
    getUserByToken(token)
      .then((user) => {
        if (user) {
          setUserByToken(user);
          setView("registerForm");
        }
      })
      .catch(() => {
        setView("tokenNotValid");
      });
  }, [getUserByToken, token]);

  switch (view) {
    case "registerForm":
      return <Registration prefillUserData={userByToken} token={token} onSuccessfulSubmit={handleRegisterComplete} />;
    case "tokenNotValid":
      return <TokenNotValid />;
    case "registerComplete":
      return <RegisterComplete />;
    default:
      return <SplashScreen />;
  }
};

export default Register;
