import { AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AuthContainer } from "../hooks/auth";
import createAxios from "./createAxios";

const axiosInstance = createAxios(process.env.REACT_APP_API_URL || "");

function getAccessToken(): string {
  const tokensString = localStorage.getItem("tokens");
  if (tokensString) {
    const tokens = JSON.parse(tokensString);
    return tokens.accessToken;
  }
  return "";
}

axiosInstance.interceptors.request.use((request) => {
  if (request && request.headers) {
    request.headers.Authorization = `Bearer ${getAccessToken()}`;
  }
  return request;
});

function useAxios(): AxiosInstance {
  const { updateToken } = AuthContainer.useContainer();
  createAuthRefreshInterceptor(axiosInstance, updateToken);
  return axiosInstance;
}

export default useAxios;
