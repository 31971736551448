import { produce } from "immer";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RecommendedActionState,
  RecommendedActionFilterInput,
  Language,
} from "../../../graphql/generated/generatedGraphQL";
import {
  allDisabled,
  ITableFilter,
  mapToArray,
  ITableFilterState,
  allPriorityTrue,
  initialFilterState,
} from "../../../hooks/table/useTableFilterBase";
import { getLanguage, mapSupportedLocaleToLanguage } from "../../../locales";

export interface IRecommendedActionTableFilterState extends ITableFilterState {
  whereState: Record<RecommendedActionState, boolean>;
  whereMavIdEquals?: string;
}

export interface IRecommendedActionTableFilter extends IRecommendedActionTableFilterState, ITableFilter {
  graphQLFilterInput: RecommendedActionFilterInput;
  includeState(state: RecommendedActionState, include: boolean): void;
  setWhereMavIdEquals(mavId?: string): void;
}
const allStateTrue = {
  [RecommendedActionState.None]: true,
  [RecommendedActionState.Unsolved]: true,
  [RecommendedActionState.Solved]: true,
};

const allStateFalse = {
  [RecommendedActionState.None]: false,
  [RecommendedActionState.Unsolved]: false,
  [RecommendedActionState.Solved]: false,
};

export const initialRecommendedActionFilterState: IRecommendedActionTableFilterState = {
  whereState: allStateFalse,
  ...initialFilterState,
};

export function mapFieldsToFilter(
  state: IRecommendedActionTableFilterState,
  lng: Language
): RecommendedActionFilterInput {
  return {
    and: [
      {
        priority: {
          in: mapToArray(state.wherePriority).length ? mapToArray(state.wherePriority) : mapToArray(allPriorityTrue),
        },
      },
      {
        state: {
          in: mapToArray(state.whereState).length ? mapToArray(state.whereState) : mapToArray(allStateTrue),
        },
      },
      ...(!(
        state.whereMavIdEquals === "" ||
        state.whereMavIdEquals === undefined ||
        state.whereMavIdEquals === "<no guid>"
      )
        ? [{ mavId: { eq: state.whereMavIdEquals } }]
        : []),
    ],
    or: [
      { tenantName: { icontains: state.whereFullTextContains } },
      { clientName: { icontains: state.whereFullTextContains } },
      {
        recommendedActionCultureSpecifics: {
          some: {
            and: [
              { language: { eq: lng } },
              {
                or: [
                  { summary: { icontains: state.whereFullTextContains } },
                  { description: { icontains: state.whereFullTextContains } },
                ],
              },
            ],
          },
        },
      },
    ],
  };
}

function useTableFilter(initialState?: IRecommendedActionTableFilterState): IRecommendedActionTableFilter {
  const [filterState, setFilterState] = useState(initialState ? initialState : initialRecommendedActionFilterState);
  const { i18n } = useTranslation();
  const lng = getLanguage(i18n);
  return useMemo(
    () => ({
      ...filterState,
      graphQLFilterInput: mapFieldsToFilter(filterState, mapSupportedLocaleToLanguage(lng)),
      includeState: (state, include) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.whereState[state] = include;
            if (allDisabled(draft.whereState)) {
              draft.whereState = allStateFalse;
            }
          });
        });
      },
      includePriority: (priority, include) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.wherePriority[priority] = include;
            if (allDisabled(draft.wherePriority)) {
              draft.wherePriority = initialRecommendedActionFilterState.wherePriority;
            }
          });
        });
      },
      setWhereFullTextContains: (fullTextContains) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.whereFullTextContains = fullTextContains;
          });
        });
      },
      setWhereMavIdEquals: (mavid) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.whereMavIdEquals = mavid ? mavid.trim() : undefined;
          });
        });
      },
    }),
    [filterState, lng]
  );
}

export default useTableFilter;
