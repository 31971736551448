import { Typography } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import {
  clientColumn,
  expanderColumn,
  priorityColumn,
} from "../../recommendedActions/components/recommendedActionTableColumns";

const RecommendedActionHeader: VFC = () => {
  const { t } = useTranslation();
  return t("Common.RecommendedAction");
};

export const recommendedActionColumn: Column<RecommendedActionViewModel> = {
  Header: <RecommendedActionHeader />,
  id: "recommendedActions",
  accessor: (originalRow) => originalRow,
  Cell: ({ value }: { value: RecommendedActionViewModel }) => {
    const { t } = useTranslation();
    return (
      <>
        {value.summary}
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: "12px" }}>
          {t("Common.RelativeDate", {
            relativeDate: {
              date: value.createdAt,
              baseDate: new Date(),
            },
          })}
        </Typography>
      </>
    );
  },
  disableSortBy: true,
};
const priorityColumnWidget = {
  ...priorityColumn,
  disableSortBy: true,
};
const clientColumnWidget = {
  ...clientColumn,
  disableSortBy: true,
};

export const recommendedActionTableColumns: Column<RecommendedActionViewModel>[] = [
  priorityColumnWidget,
  clientColumnWidget,
  recommendedActionColumn,
  expanderColumn,
];
