import { FC } from "react";
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type IRetryProps = {
  retry: () => void;
};

export const RetryButton: FC<IRetryProps> = ({ retry }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={retry} color="inherit" size="small" data-testid="RetryButton-Retry">
      {t("Page.Retry", "RETRY")}
    </Button>
  );
};

export const ErrorAlert: FC<IRetryProps> = ({ retry }) => {
  const { t } = useTranslation();
  return (
    <Alert severity="error" action={<RetryButton retry={retry} />}>
      {t("Page.Error", "Error: A server request failed.")}
    </Alert>
  );
};

export const NoDataAlert: FC<IRetryProps> = ({ retry }) => {
  const { t } = useTranslation();
  return (
    <Alert severity="error" action={<RetryButton retry={retry} />}>
      {t("Page.NoData", "Error: A server request did not return data.")}
    </Alert>
  );
};
