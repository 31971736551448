// https://dev.to/ekeijl/react-automatic-date-formatting-in-translations-i18next-date-fns-8df
import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import { format as formatDate, formatRelative, isDate } from "date-fns";
import { enGB as dateEnGB, de as dateDe, pl as datePl } from "date-fns/locale"; // import all locales we need
import { isNumber } from "lodash";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import de from "./locales/de/translation.json";
import pl from "./locales/pl/translation.json";
import { fallbackLocale, supportedLocales, SupportedLocale, getLanguage } from "./locales";

const dateLocales: Record<SupportedLocale, Locale> = {
  en: dateEnGB,
  de: dateDe,
  pl: datePl,
};

// Pre-defined format options similar to https://angular.io/api/common/DatePipe
// for the format spec see https://date-fns.org/v2.21.1/docs/format
const dateFormats = new Map(
  Object.entries({
    shortDate: "P",
    longDate: "PPP",
    shortTime: "p",
    mediumTime: "pp",
  })
);

const resources: Record<SupportedLocale, ResourceLanguage> = {
  en: { translation: en },
  de: { translation: de },
  pl: { translation: pl },
};

const languageDetector = new LanguageDetector(null, {
  order: ["querystring", "localStorage", "navigator"],
});

export default function createI18nInstance(): typeof i18n {
  const instance = i18n.createInstance();

  instance
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: fallbackLocale,
      supportedLngs: supportedLocales,
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
          if (!format || !lng) {
            return value;
          }

          if (isDate(value)) {
            const dateLocale = dateLocales[getLanguage(instance)];
            const realFormat = dateFormats.get(format) ?? format;
            return formatDate(value, realFormat, { locale: dateLocale });
          }

          if (isNumber(value) && format === "number") {
            return new Intl.NumberFormat(lng).format(value);
          }

          if (format === "relativeDate") {
            const dateLocale = dateLocales[getLanguage(instance)];
            const { date, baseDate } = value;
            return formatRelative(date, baseDate, { locale: dateLocale });
          }

          return value;
        },
      },
    });
  return instance;
}
