import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Alert, Box, Button, Link } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentBox from "../../../components/ContentBox";
import getValidationSchema from "../formValidations/personalData";
import { TextField } from "../../../components/formFields";
import CssGrid from "../../../components/CssGrid";
import {
  UpdateOwnUserMutationVariables,
  User,
  useUpdateOwnUserMutation,
} from "../../../graphql/generated/generatedGraphQL";

interface IPersonalDataProps {
  user: User;
}

const PersonalDataForm: FC<IPersonalDataProps> = ({ user }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useUpdateOwnUserMutation();

  const updateUser = async (updateUserInput: User): Promise<User> => {
    const input: UpdateOwnUserMutationVariables = {
      user: {
        firstName: updateUserInput.firstName,
        lastName: updateUserInput.lastName,
        phone: updateUserInput.phone,
      },
    };
    try {
      const response = await mutateAsync(input);
      if (!response.updateUser.isUpdated) {
        throw new Error("User wasn't updated");
      }
      return response.updateUser.user;
    } catch (err) {
      return updateUserInput;
    }
  };

  const formik = useFormik({
    initialValues: user,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setValues, setStatus, setSubmitting }) => {
      try {
        const updatedUser = await updateUser(values);
        setValues(updatedUser);
        setStatus({ success: true });
        enqueueSnackbar(t("Settings.PersonalDataSaved", "The changes to your data have been successfully applied."), {
          variant: "success",
        });
      } catch (e) {
        setStatus({ success: false });
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  return (
    <ContentBox headline={t("Settings.PersonalData", "Persönliche Daten")}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <CssGrid columns={{ xs: 1, lg: 2 }}>
          <TextField
            formik={formik}
            label={t("Forms.FirstName", "First name")}
            name="firstName"
            autoComplete="given-name"
            required
            icon={<PersonIcon color="inherit" />}
          />
          <TextField
            formik={formik}
            label={t("Forms.LastName", "Last name")}
            name="lastName"
            autoComplete="family-name"
            required
            icon={<PersonIcon color="inherit" />}
          />
          <TextField
            formik={formik}
            label={t("Forms.Email")}
            name="eMail"
            type="email"
            autoComplete="email"
            disabled
            icon={<MailOutlineIcon color="inherit" />}
          />
          <TextField
            formik={formik}
            label={t("Forms.Phone", "Phone")}
            name="phone"
            type="tel"
            autoComplete="tel"
            icon={<CallIcon color="inherit" />}
          />
          <Box />
          <Button
            data-testid="PersonalDataForm-Apply"
            color="primary"
            fullWidth
            disabled={formik.isSubmitting || !formik.dirty}
            size="large"
            type="submit"
            variant="contained"
          >
            {t("Forms.ButtonApply")}
          </Button>
        </CssGrid>
        <Alert variant="outlined" severity="info" sx={{ mt: 3 }}>
          {t("Settings.ChangeMailInfo")} <Link href="mailto:365@gdata.de">365@gdata.de</Link>.
        </Alert>
      </form>
    </ContentBox>
  );
};

export default PersonalDataForm;
