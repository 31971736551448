import { useQuery, UseQueryResult } from "react-query";
import {
  useClientsByTenantQuery,
  ClientsByTenantDocument,
  ClientsByTenantQueryVariables,
  ClientsByTenantQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { useFetcher } from "../../../graphql/useAxiosFetcher";

export type Client = {
  id: number;
  name: string;
};

function mapToClient(data: { name: string; id: number }): Client {
  return {
    id: data.id,
    name: data.name ?? "",
  };
}

function useCustomFetcher() {
  const fetcher = useFetcher<ClientsByTenantQuery, unknown>(ClientsByTenantDocument);

  return (variables: ClientsByTenantQueryVariables) => {
    return fetcher(variables).then((response) => response.allClients?.map(mapToClient) ?? []);
  };
}

export function useAllClients(variables: ClientsByTenantQueryVariables): UseQueryResult<Client[]> {
  const fetcher = useCustomFetcher();

  const result = useQuery<Client[]>(useClientsByTenantQuery.getKey(variables), fetcher.bind(null, variables));
  return result;
}
