import type { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DashboardWidget from "../components/DashboardWidget";

/**
 * Widget shows the number of devices
 */
const DeviceWidget: FC<{ clientsCount: number | undefined }> = ({ clientsCount }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <DashboardWidget
      size="1x1"
      title={t("Dashboard.ProtectedClients")}
      link={{
        title: t("Dashboard.ShowClientOverview"),
        to: "/clients",
      }}
    >
      <Typography
        data-testid="DeviceWidget-clientCount"
        sx={{
          lineHeight: 1,
          fontWeight: 300,
          fontSize: theme.spacing(12),
          marginTop: theme.spacing(1),
        }}
        align="center"
      >
        {clientsCount}
      </Typography>
    </DashboardWidget>
  );
};

export default DeviceWidget;
