import { VFC } from "react";
import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Page from "../../components/Page";
import { StyledRecommendedActionsTableRoot } from "../recommendedActions/components/RecommendedActionsTableStyles";
import TemplatesOverviewContent from "./components/TemplatesOverviewContent";

const Title: VFC = () => {
  const { t } = useTranslation();
  return (
    <Box m="0px" justifyContent="space-between" alignItems={"flex-end"} style={{ display: "flex" }} mb={4}>
      <Box>
        <Typography color="textPrimary" component="h2" variant="h4">
          {`${t("Sidebar.AnalysisAndAdministration")} > ${t("Sidebar.TemplatesOverview")}`}
        </Typography>
      </Box>
      <Button
        color="primary"
        startIcon={<Add />}
        variant="contained"
        size="medium"
        sx={{ height: "32px" }}
        component={Link}
        to="create"
        data-testid="templatesOverview-newTemplateButton"
      >
        {t("RecommendedActions.Templates.NewTemplate")}
      </Button>
    </Box>
  );
};

const TemplatesOverview: VFC = () => {
  return (
    <StyledRecommendedActionsTableRoot>
      <Page Title={Title} Content={TemplatesOverviewContent} queryResults={{}} />
    </StyledRecommendedActionsTableRoot>
  );
};

export default TemplatesOverview;
