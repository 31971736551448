import { FC } from "react";
import "./Gcon.css";

interface GconIconProps {
  name: string;
  className?: string;
  verticalAlign?: string;
}

const GconIcon: FC<GconIconProps> = ({ name, className, verticalAlign }) => {
  return verticalAlign ? (
    <span className={`${name} ${className}`} style={{ verticalAlign: verticalAlign }} />
  ) : (
    <span className={`${name} ${className}`} />
  );
};

export const WindowsGconIcon: FC<{ className?: string }> = ({ className }) => {
  return <GconIcon name="icon-windows" className={className} />;
};

export const MacGconIcon: FC<{ className?: string }> = ({ className }) => {
  return <GconIcon name="icon-osx" className={className} />;
};

export const GDataIcon: FC<{ className?: string; verticalAlign?: string }> = ({ className, verticalAlign }) => {
  return <GconIcon name="icon-gdata" className={className} verticalAlign={verticalAlign} />;
};
