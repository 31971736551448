import { useQuery, UseQueryResult } from "react-query";
import {
  GetInstallPackagesFromRegnumbersDocument,
  GetInstallPackagesFromRegnumbersQuery,
  InstallPackage,
  useGetInstallPackagesFromRegnumbersQuery,
} from "../../../graphql/generated/generatedGraphQL";
import { useFetcher } from "../../../graphql/useAxiosFetcher";

export const securityClientDownloadsKey = "securityClientDownloads";

function filterDefined<T>(array: Array<T | null | undefined>): T[] {
  return array.filter((e) => !!e) as T[];
}

function useCustomFetcher() {
  const fetcher = useFetcher<GetInstallPackagesFromRegnumbersQuery, unknown>(GetInstallPackagesFromRegnumbersDocument);

  return async () => {
    const response = await fetcher();
    return response.resolveTenantsFromRegnumber?.flatMap((i) => filterDefined(i.installPackages || []) || []) || [];
  };
}

export function useSecurityClientDownloads(): UseQueryResult<InstallPackage[]> {
  const fetcher = useCustomFetcher();

  const result = useQuery<InstallPackage[]>(useGetInstallPackagesFromRegnumbersQuery.getKey(), fetcher.bind(null));
  return result;
}
