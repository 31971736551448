import { VFC } from "react";
import StyledTableHeaderBar from "../../../components/StyledTableHeaderBar";
import { IRecommendedActionTemplatesTableFilter } from "../hooks/useTableFilter";
import SearchField from "../../../components/SearchField";
import PriorityToggleButtons from "../../../components/PriorityToggleButtons";
import { FilterButtonsContainer } from "../../../components/table/FilterButtonsContainer";
import useDebouncedInputValueHandler from "../../../hooks/useDebouncedInputValueHandler";

type RecommendedActionTemplatesTableActionBarProps = { filter: IRecommendedActionTemplatesTableFilter };

const RecommendedActionTemplatesTableActionBar: VFC<RecommendedActionTemplatesTableActionBarProps> = ({ filter }) => {
  const handleSearchChange = (value: string) => filter.setWhereFullTextContains(value);
  const handleSearchChangeDebounced = useDebouncedInputValueHandler(handleSearchChange);

  return (
    <StyledTableHeaderBar>
      <FilterButtonsContainer>
        <PriorityToggleButtons filter={filter} />
      </FilterButtonsContainer>
      <SearchField onChange={handleSearchChangeDebounced} defaultValue={filter.whereFullTextContains} />
    </StyledTableHeaderBar>
  );
};

export default RecommendedActionTemplatesTableActionBar;
