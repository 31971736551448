import { FC, useState } from "react";
import { IconButton, InputLabelProps } from "@mui/material";
import { FormikProps } from "formik";
import TextField from "./TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  label: string;
  name: string;
  id?: string;
  dataTestid?: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  InputLabelProps?: Partial<InputLabelProps>;
}

const PasswordField: FC<PasswordFieldProps> = (props) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <TextField
      type={!passwordVisible ? "password" : "text"}
      icon={
        <IconButton
          aria-label="toggle password visibility"
          onClick={togglePasswordVisibility}
          tabIndex={-1}
          sx={{ padding: 0 }}
        >
          {passwordVisible ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
      {...props}
    />
  );
};

export default PasswordField;
