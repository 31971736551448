import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WindowsGconIcon } from "../../../icons/GconIcon";
import ClientDownloadCard, { ClientDownloadCardClasses } from "./ClientDownloadCard";

const WindowsClientDownloadCard: FC<{ downloadUrl?: string }> = ({ downloadUrl }) => {
  const { t } = useTranslation();
  return (
    <ClientDownloadCard
      title="Windows"
      subtitle={t("Clients.Windows.DownloadSubtitle")}
      icon={<WindowsGconIcon className={ClientDownloadCardClasses.icon} />}
      description={t("Clients.Windows.DownloadDescription")}
      downloadUrl={downloadUrl}
    />
  );
};

export default WindowsClientDownloadCard;
