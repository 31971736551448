import { Button, MenuItem, Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import _ from "lodash";
import Select from "../../../components/formFields/SelectField";
import QueryComboBox from "../../../components/QueryComboBox";
import ClientComboBox from "./ClientComboBox";
import {
  CreateRecommendedActionFromTemplateInput,
  Priority,
  RecommendedActionTemplateQuery,
  Tag,
  useCreateRecommendedActionFromTemplateMutation,
  useRecommendedActionTemplatesWithPagingQuery,
  ClientInput,
} from "../../../graphql/generated/generatedGraphQL";
import { getValidationSchema } from "../formValidations/assignTemplate";
import useAllTenants from "../hooks/useAllTenants";
import { Client } from "../hooks/useAllClients";
import { sortTags } from "../../../types/Tag";
import { sortPriorities } from "../../../types/Priority";
import RecommendedActionTag from "../../recommendedActions/components/RecommendedActionTag";
import RecommendedActionPriority from "../../recommendedActions/components/RecommendedActionPriority";
import ContentBox from "../../../components/ContentBox";

type CreateRecommendedActionFromTemplateFormProps = {
  data: {
    recommendedActionTemplateQuery: RecommendedActionTemplateQuery;
  };
  onTenantChanged: (mavId?: string) => void;
};

const useCreateRecommendedActionFromTemplate = () => {
  const { mutateAsync } = useCreateRecommendedActionFromTemplateMutation();
  const queryClient = useQueryClient();
  return async (input: CreateRecommendedActionFromTemplateInput): Promise<void> => {
    await mutateAsync({ input });
    queryClient.invalidateQueries(useRecommendedActionTemplatesWithPagingQuery.getKey());
  };
};

export type FormValues = {
  clients: Array<ClientInput>;
  id: string;
  mavId: string | null;
  tenantName: string;
  priority: string;
  tag: string;
};

const loadInitValueFromLoacalStorage = () => {
  const templateCreateData = "templateCreateData";
  const initData = localStorage.getItem(templateCreateData);
  if (initData) {
    const dataObject = JSON.parse(initData);

    if (
      (dataObject as {
        mavId: string;
        tenantName: string;
        clients: [{ id: number; name: string }];
        timestamp: number;
      }) &&
      Math.abs(Date.now() - dataObject.timestamp) / 1000 < 60
    )
      return dataObject;

    return undefined;
  }
};

const CreateRecommendedActionFromTemplateForm: FC<CreateRecommendedActionFromTemplateFormProps> = ({
  data,
  onTenantChanged,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Formik
  const template = data.recommendedActionTemplateQuery.recommendedActionTemplate;
  const createRecommmendedActionFromTemplate = useCreateRecommendedActionFromTemplate();
  const localStorageData = loadInitValueFromLoacalStorage();
  const initValues: FormValues = {
    priority: template.priority,
    id: template.id,
    tag: template.tag,
    mavId: localStorageData?.mavId ?? null,
    tenantName: localStorageData?.tenantName ?? "",
    clients: localStorageData?.clients ?? [],
  };
  const schema = getValidationSchema();
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: (values: FormValues, { setStatus, setSubmitting }) => {
      let input = _.omit(values, ["priority", "tag"]);
      if (input.clients.length === 0) {
        input = {
          ...input,
          clients: [{ id: 0, name: "" }],
        };
      }
      createRecommmendedActionFromTemplate(input)
        .then(() => {
          setStatus({ success: true });
          setSubmitting(false);
          navigate("./../..");
        })
        .catch((e) => {
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar(t("Common.UnspecifiedError"), {
            variant: "error",
          });
          // eslint-disable-next-line no-console
          console.error("Error creating recommended action from template", e);
        });
    },
  });

  const allTenants = useAllTenants();

  return (
    <ContentBox headline={`Template: ${template.name}`}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container rowSpacing={0} columnSpacing={4}>
          <Grid item xs={6}>
            <Select disabled formik={formik} name="priority" label={t("RecommendedActions.Priority")}>
              {sortPriorities(Object.values(Priority)).map((priority) => (
                <MenuItem key={priority} value={priority}>
                  <RecommendedActionPriority priority={priority} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select formik={formik} name="tag" label={t("RecommendedActions.Category")} disabled>
              {sortTags(Object.values(Tag)).map((tag) => (
                <MenuItem key={tag} value={tag}>
                  <RecommendedActionTag tag={tag} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <QueryComboBox
              label={t("Common.Tenant")}
              queryResult={allTenants}
              value={{ mavId: formik.values.mavId ?? "", name: formik.values.tenantName }}
              onChange={(
                _event: React.SyntheticEvent<Element, Event>,
                value: { mavId: string; name: string } | null
              ) => {
                formik.setFieldValue("mavId", value?.mavId ?? null, false);
                formik.setFieldValue("tenantName", value?.name ?? "", false);
                formik.setFieldValue("clients", [], false);
                onTenantChanged(value?.mavId);
              }}
              isOptionEqualToValue={(option1, option2) => option1.mavId === option2.mavId}
              getOptionLabel={(tenant) => tenant.name}
              error={formik.errors?.mavId ? true : undefined}
              data-testid="AssignTemplate-Tenant"
            />
          </Grid>
          <Grid item xs={6}>
            <ClientComboBox
              mavId={formik.values.mavId}
              value={formik.values.clients}
              fullWidth
              isOptionEqualToValue={(option1, option2) => option1.id === option2.id}
              onChange={(_event: React.SyntheticEvent<Element, Event>, value: Client[] | null) => {
                formik.setFieldValue("clients", value);
              }}
              error={formik.errors?.clients ? true : undefined}
              data-testid="AssignTemplate-Client"
            />
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" variant="outlined" fullWidth component={Link} to="./../..">
              {t("Forms.ButtonCancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
              {t("RecommendedActions.Templates.AssignTemplate")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentBox>
  );
};

export default CreateRecommendedActionFromTemplateForm;
