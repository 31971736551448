import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import ContentBox from "../../../components/ContentBox";
import { PasswordChangeFormValues, getValidationSchema } from "../formValidations/passwordChange";
import { PasswordField } from "../../../components/formFields";
import PasswordStrengthMeter from "../../login/components/PasswordStrengthMeter";
import {
  UpdateOwnPasswordMutationVariables,
  useUpdateOwnPasswordMutation,
} from "../../../graphql/generated/generatedGraphQL";

const PasswordForm: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useUpdateOwnPasswordMutation();

  const initialFormValues: PasswordChangeFormValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const updatePassword = async (currentPassword: string, newPassword: string): Promise<boolean> => {
    const input: UpdateOwnPasswordMutationVariables = {
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await mutateAsync(input);
      return response.setNewPassword;
    } catch (err) {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const passwordUpdatedResponse = await updatePassword(values.currentPassword, values.newPassword);
      setSubmitting(false);
      setStatus({ success: passwordUpdatedResponse });
      switch (passwordUpdatedResponse) {
        case true:
          enqueueSnackbar(t("Settings.PasswordSaved"), { variant: "success" });
          break;
        default:
          enqueueSnackbar(t("Settings.PasswordNotSaved"), { variant: "error" });
          break;
      }
    },
  });

  return (
    <ContentBox headline={t("Settings.ChangePassword")}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <PasswordField
          formik={formik}
          autoComplete="off"
          label={t("Forms.CurrentPassword")}
          name="currentPassword"
          required
        />
        <PasswordField
          formik={formik}
          autoComplete="off"
          label={t("Settings.NewPassword")}
          name="newPassword"
          required
        />
        <PasswordField
          formik={formik}
          autoComplete="off"
          dataTestid="passwordForm-confirmPassword"
          label={t("Forms.PasswordConfirm")}
          name="newPasswordConfirm"
          required
        />
        <PasswordStrengthMeter password={formik.values.newPassword} />
        <Button
          data-testid="PasswordForm-SetPassword"
          color="primary"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t("Settings.SetPasswordBtn")}
        </Button>
      </form>
    </ContentBox>
  );
};

export default PasswordForm;
