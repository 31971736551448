import { UseQueryResult } from "react-query";
import {
  RecommendedActionsQuery,
  RecommendedActionsQueryVariables,
  useRecommendedActionsQuery as useQuery,
} from "../../../graphql/generated/generatedGraphQL";
import useUserContext from "../../../hooks/useUserContext";

export default function useRecommendedActionsQuery(
  variables?: RecommendedActionsQueryVariables
): UseQueryResult<RecommendedActionsQuery> {
  const userContext = useUserContext();

  let v = variables;

  if (userContext.inspectingTenant) {
    v = v?.where
      ? {
          ...v,
          where: { and: [{ mavId: { eq: userContext.inspectingTenant } }, v.where] },
        }
      : {
          ...v,
          where: { mavId: { eq: userContext.inspectingTenant } },
        };
  }

  return useQuery(v);
}
