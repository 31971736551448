import _ from "lodash";
import { useCallback, useRef } from "react";

export default function useDebouncedInputValueHandler(
  handler: (value: string) => void,
  waitInMs = 1000
): React.ChangeEventHandler<HTMLInputElement> {
  const ref = useRef(_.debounce(handler, waitInMs));
  return useCallback(({ currentTarget: { value } }) => ref.current(value), []);
}
