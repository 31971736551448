import clsx from "clsx";
import type { FC } from "react";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { priorities as priorityColors } from "../../../theme/colors";
import { styled } from "@mui/material/styles";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  fontSize: "5rem",
  fontWeight: 600,
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginBottom: theme.spacing(3),
  color: "#FFF",
  [theme.breakpoints.up("lg")]: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    fontSize: "6rem",
  },
  backgroundColor: theme.palette.success.main,
  "&.low": {
    backgroundColor: theme.palette.success.main,
  },
  "&.medium": {
    backgroundColor: priorityColors.medium,
  },
  "&.high": {
    backgroundColor: priorityColors.high,
  },
  "&.noClients": {
    backgroundColor: "transparent",
    color: priorityColors.noClients,
    fontSize: "6rem",
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
      fontSize: "7.7rem",
    },
  },
}));

function getAvatar(priority: string) {
  switch (priority) {
    case "none": {
      return <CheckIcon fontSize="inherit" />;
    }
    case "noClients": {
      return <HelpOutlineIcon fontSize="inherit" />;
    }
    default: {
      return " ";
    }
  }
}

/**
 * StatusBadge used for SecurityStatus on Dashboard
 */
const StatusBadge: FC<{ priority: string }> = ({ priority }) => {
  const avatarContent = getAvatar(priority);

  return <StyledAvatar className={clsx(priority)}>{avatarContent}</StyledAvatar>;
};

export default StatusBadge;
