import { TFunction } from "i18next";
import * as Yup from "yup";
import { SchemaOf } from "yup";

export type LoginFormValues = {
  email: string;
  password: string;
};

export const getValidationSchema = (t: TFunction): SchemaOf<LoginFormValues> => {
  return Yup.object().shape({
    email: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("Forms.UsernameOrMail", "Username"),
      })
    ),
    password: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("Forms.Password"),
      })
    ),
  });
};
