import { TFunction } from "i18next";
import * as Yup from "yup";
import { SchemaOf } from "yup";

export type UpdateOwnUserFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
};

export default (t: TFunction): SchemaOf<UpdateOwnUserFormValues> => {
  return Yup.object().shape({
    firstName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.FirstName", "First name"),
        })
      ),
    lastName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.LastName", "Last name"),
        })
      ),
    phone: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "50",
        })
      )
      .notRequired()
      .default(""),
  });
};
