import { MenuItem } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionTemplateViewModel } from "../../../types/RecommendedActionTemplate";

type AssignProps = ActionMenuItemsProps<RecommendedActionTemplateViewModel>;

const Assign = ({ selectedFlatRows, onClose }: AssignProps): ReturnType<VFC> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const disabled = selectedFlatRows.length !== 1;
  return (
    <MenuItem
      data-testid="recommendedActionTemplates-AssignButton"
      key={"EnhancedTable.Assign"}
      disabled={disabled}
      onClick={() => {
        onClose();
        if (selectedFlatRows.length) {
          navigate(`${selectedFlatRows[0].original.id}/createRecommendedAction`);
        }
      }}
    >
      {t("EnhancedTable.Assign")}
    </MenuItem>
  );
};

export default Assign;
