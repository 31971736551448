import type { FC } from "react";
import { Box } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";

interface CssGridProps {
  columns: ResponsiveStyleValue<number>;
  columnGap?: number | ResponsiveStyleValue<number>;
  rowGap?: number | ResponsiveStyleValue<number>;
}

/**
  Material UI uses a Flexbox Grid. This is a component that creates a real css grid (display: grid).
  This makes a different row- and column-gap possible.
 */
const CssGrid: FC<CssGridProps> = ({ children, columns, columnGap, rowGap }) => {
  const gridColumnDefinition: ResponsiveStyleValue<string> = {};
  Object.entries(columns).forEach(([breakpoint, columnCount]) => {
    gridColumnDefinition[breakpoint] = `repeat(${columnCount}, 1fr)`;
  });
  return (
    <Box display="grid" columnGap={columnGap ?? 4} rowGap={rowGap ?? 0} gridTemplateColumns={gridColumnDefinition}>
      {children}
    </Box>
  );
};

export default CssGrid;
