import { styled } from "@mui/material";

const StyledTableHeaderBarRightOrientation = styled("div", { name: "TableHeaderBar" })(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  display: "flex",
  flexFlow: "row wrap",
  gap: theme.spacing(2),
  flexDirection: "row-reverse",
}));

export default StyledTableHeaderBarRightOrientation;
