import { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
  useRecommendedActionsAnalystQuery,
  useRecommendedActionsQuery,
  useResolveRecommendedActionMutation,
} from "../../../graphql/generated/generatedGraphQL";
import useRecommendedActionsSnackbar from "../../../hooks/useRecommendedActionsSnackbar";

const useResolveRecommendedAction = () => {
  const { mutateAsync } = useResolveRecommendedActionMutation();
  const { snackbarSuccess, snackbarError } = useRecommendedActionsSnackbar();
  const queryClient = useQueryClient();
  return useCallback(
    async (recommendedActionId: string): Promise<void> => {
      try {
        await mutateAsync({ recommendedActionId });
        queryClient.invalidateQueries(useRecommendedActionsQuery.getKey());
        queryClient.invalidateQueries(useRecommendedActionsAnalystQuery.getKey());
        snackbarSuccess();
      } catch (e) {
        snackbarError();
      }
    },
    [mutateAsync, queryClient, snackbarError, snackbarSuccess]
  );
};

export default useResolveRecommendedAction;
