import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import {
  DeleteRecommendedActionTemplateInput,
  useDeleteRecommendedActionTemplatesMutation,
  useRecommendedActionTemplatesWithPagingQuery,
} from "../../../graphql/generated/generatedGraphQL";

const useDeleteTemplate = () => {
  const { mutateAsync } = useDeleteRecommendedActionTemplatesMutation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useCallback(
    async (input: DeleteRecommendedActionTemplateInput): Promise<void> => {
      try {
        await mutateAsync({ input });
        queryClient.invalidateQueries(useRecommendedActionTemplatesWithPagingQuery.getKey());
      } catch (e) {
        enqueueSnackbar(t("Common.UnspecifiedError"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, mutateAsync, queryClient, t]
  );
};

export default useDeleteTemplate;
