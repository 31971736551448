import type { FC, ReactNode } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import type { SxProps } from "@mui/system";

interface ContentBoxProps {
  headline?: string;
  children: ReactNode;
  sx?: SxProps;
  sxChild?: SxProps;
}

/**
 * Renders a default box with a background, and optionally with a headline separated by a stroke.
 */
const ContentBox: FC<ContentBoxProps> = ({ headline, children, sx, sxChild }) => {
  return (
    <Paper sx={{ ...sx, pt: 2, pb: 2 }}>
      {headline && (
        <>
          <Typography variant="h3" sx={{ pr: 4, pl: 4 }}>
            {headline}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
      <Box sx={{ pt: 2, pb: 2, pl: 4, pr: 4, ...sxChild }}>{children}</Box>
    </Paper>
  );
};

export default ContentBox;
