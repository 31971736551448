import { Priority } from "../../graphql/generated/generatedGraphQL";

export interface ITableFilterState {
  wherePriority: Record<Priority, boolean>;
  whereFullTextContains: string;
}

export interface ITableFilter extends ITableFilterState {
  includePriority(priority: Priority, include: boolean): void;
  setWhereFullTextContains(fullTextContains: string): void;
}

export const allPriorityTrue = {
  [Priority.High]: true,
  [Priority.Medium]: true,
  [Priority.Low]: true,
};

export const allPriorityFalse = {
  [Priority.High]: false,
  [Priority.Medium]: false,
  [Priority.Low]: false,
};

export const initialFilterState: ITableFilterState = {
  wherePriority: allPriorityFalse,
  whereFullTextContains: "",
};

export function mapToArray<T extends string>(map: Record<T, boolean>): T[] {
  const result = [];
  for (const [key, value] of Object.entries(map)) {
    if (value) {
      result.push(key as T);
    }
  }
  return result;
}

export function allDisabled(map: Record<string, boolean>) {
  return Object.values(map).every((v) => v === false);
}
