import { VFC } from "react";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import { Unresolve } from "../../recommendedActions";
import Delete from "./Delete";
import ResolveWithConfirmation from "./ResolveWithConfirmation";

type Props = ActionMenuItemsProps<RecommendedActionViewModel>;

const ActionMenuItemsForAnalyst = ({ selectedFlatRows, onClose }: Props): ReturnType<VFC> => {
  return (
    <>
      <ResolveWithConfirmation selectedFlatRows={selectedFlatRows} onClose={onClose} />
      <Unresolve selectedFlatRows={selectedFlatRows} onClose={onClose} />
      <Delete selectedFlatRows={selectedFlatRows} onClose={onClose} />
    </>
  );
};

export default ActionMenuItemsForAnalyst;
