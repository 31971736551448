import LocalSettingsContainer from "../../../hooks/LocalSettingsContainer";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC } from "react";

type ThemeSelectControlProps = {
  className?: string;
};

export const ThemeSelectControl: FC<ThemeSelectControlProps> = ({ className }) => {
  const { settings, setTheme } = LocalSettingsContainer.useContainer();
  const { t } = useTranslation();
  function handleThemeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const theme = event.target.checked ? "DARK" : "LIGHT";

    setTheme(theme);
  }
  return (
    <FormControlLabel
      className={className}
      control={
        <Switch
          name="darkModeActive"
          color="primary"
          checked={settings.theme === "DARK"}
          onChange={handleThemeChange}
        />
      }
      label={<Typography style={{ marginLeft: "4px" }}>{t("Settings.DarkMode", "Dark Mode").toString()}</Typography>}
    />
  );
};
