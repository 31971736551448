import { Language } from "./graphql/generated/generatedGraphQL";

type SupportedLocale = "de" | "en" | "pl";
const supportedLocalesInternal: SupportedLocale[] = ["de", "en", "pl"];
const fallbackLocale: SupportedLocale = "en";

const supportedLocales = supportedLocalesInternal as string[];

/** Returns the first detected language that is supported
 * @param i18n An instance of i18next
 */
function getLanguage(i18n: { languages: readonly string[] }): SupportedLocale {
  const language = i18n.languages.find((l) => supportedLocales.includes(l as SupportedLocale));
  if (language) {
    return language as SupportedLocale;
  }
  // To make Typescript happy. i18n.languages should always include the fallback.
  return fallbackLocale;
}

function mapSupportedLocaleToLanguage(lng: SupportedLocale): Language {
  switch (lng) {
    case "de": {
      return Language.De;
    }
    case "pl": {
      return Language.Pl;
    }
    default: {
      return Language.En;
    }
  }
}

function mapLanguageToSupportedLocale(lng: Language): SupportedLocale {
  switch (lng) {
    case Language.De: {
      return "de";
    }
    case Language.Pl: {
      return "pl";
    }
    default: {
      return fallbackLocale;
    }
  }
}

export type { SupportedLocale };
export { supportedLocales, fallbackLocale, getLanguage, mapSupportedLocaleToLanguage, mapLanguageToSupportedLocale };
