import { produce } from "immer";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language, RecommendedActionTemplateFilterInput } from "../../../graphql/generated/generatedGraphQL";
import {
  allDisabled,
  initialFilterState,
  ITableFilter,
  mapToArray,
  ITableFilterState,
  allPriorityTrue,
} from "../../../hooks/table/useTableFilterBase";
import { getLanguage, mapSupportedLocaleToLanguage } from "../../../locales";

export interface IRecommendedActionTemplatesTableFilter extends ITableFilter {
  graphQLFilterInput: RecommendedActionTemplateFilterInput;
}

export function mapFieldsToFilter(state: ITableFilterState, lng: Language): RecommendedActionTemplateFilterInput {
  return {
    and: [
      {
        priority: {
          in: mapToArray(state.wherePriority).length ? mapToArray(state.wherePriority) : mapToArray(allPriorityTrue),
        },
      },
    ],
    or: [
      { name: { icontains: state.whereFullTextContains } },
      {
        cultureSpecifics: {
          some: {
            and: [
              { language: { eq: lng } },
              {
                or: [
                  { summary: { icontains: state.whereFullTextContains } },
                  { description: { icontains: state.whereFullTextContains } },
                ],
              },
            ],
          },
        },
      },
    ],
  };
}

function useTableFilter(): IRecommendedActionTemplatesTableFilter {
  const [filterState, setFilterState] = useState(initialFilterState);
  const { i18n } = useTranslation();
  const lng = getLanguage(i18n);
  return useMemo(
    () => ({
      ...filterState,
      graphQLFilterInput: mapFieldsToFilter(filterState, mapSupportedLocaleToLanguage(lng)),
      includePriority: (priority, include) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.wherePriority[priority] = include;
            if (allDisabled(draft.wherePriority)) {
              draft.wherePriority = initialFilterState.wherePriority;
            }
          });
        });
      },
      setWhereFullTextContains: (fullTextContains) => {
        setFilterState((s) => {
          return produce(s, (draft) => {
            draft.whereFullTextContains = fullTextContains;
          });
        });
      },
    }),
    [filterState, lng]
  );
}

export default useTableFilter;
