import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "react-query";
import { useFetcher } from "../useAxiosFetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  UUID: any;
};

export type AccessRights = {
  __typename?: "AccessRights";
  canDelete: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
};

export enum ApplyPolicy {
  AfterResolver = "AFTER_RESOLVER",
  BeforeResolver = "BEFORE_RESOLVER",
}

export type AuthorizeDirective = {
  __typename?: "AuthorizeDirective";
  apply: ApplyPolicy;
  policy?: Maybe<Scalars["String"]>;
  roles?: Maybe<Array<Scalars["String"]>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars["Boolean"]>;
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type Client = {
  __typename?: "Client";
  clientState?: Maybe<ClientState>;
  id: Scalars["Int"];
  mavId: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  recommendedActionsWithPaging?: Maybe<RecommendedActionCollectionSegment>;
  type: ClientType;
};

export type ClientRecommendedActionsWithPagingArgs = {
  where?: InputMaybe<RecommendedActionFilterInput>;
};

export type ClientInput = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export enum ClientSignatureType {
  Bd = "BD",
  Gd = "GD",
  None = "NONE",
  SysSec = "SYS_SEC",
}

export type ClientSignatureTypeOperationFilterInput = {
  eq?: InputMaybe<ClientSignatureType>;
  in?: InputMaybe<Array<ClientSignatureType>>;
  neq?: InputMaybe<ClientSignatureType>;
  nin?: InputMaybe<Array<ClientSignatureType>>;
};

export type ClientSortInput = {
  clientState?: InputMaybe<ClientStateSortInput>;
  id?: InputMaybe<SortEnumType>;
  mavId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type ClientState = {
  __typename?: "ClientState";
  clientVersion?: Maybe<Scalars["String"]>;
  engineArchitecture: EngineArchitectureType;
  osBuildVersion?: Maybe<Scalars["String"]>;
  osName?: Maybe<Scalars["String"]>;
  signatures: Array<Signature>;
};

export type ClientStateFilterInput = {
  and?: InputMaybe<Array<ClientStateFilterInput>>;
  clientVersion?: InputMaybe<CustomStringOperationFilterInput>;
  engineArchitecture?: InputMaybe<EngineArchitectureTypeOperationFilterInput>;
  or?: InputMaybe<Array<ClientStateFilterInput>>;
  osBuildVersion?: InputMaybe<CustomStringOperationFilterInput>;
  osName?: InputMaybe<CustomStringOperationFilterInput>;
  signatures?: InputMaybe<ListFilterInputTypeOfSignatureFilterInput>;
};

export type ClientStateSortInput = {
  clientVersion?: InputMaybe<SortEnumType>;
  engineArchitecture?: InputMaybe<SortEnumType>;
  osBuildVersion?: InputMaybe<SortEnumType>;
  osName?: InputMaybe<SortEnumType>;
};

export type ClientTenantData = {
  __typename?: "ClientTenantData";
  clientState?: Maybe<ClientState>;
  id: Scalars["Int"];
  name: Scalars["String"];
  tenantName: Scalars["String"];
  type: ClientType;
};

export type ClientTenantDataCollectionSegment = {
  __typename?: "ClientTenantDataCollectionSegment";
  items?: Maybe<Array<ClientTenantData>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars["Int"];
};

export type ClientTenantDataFilterInput = {
  and?: InputMaybe<Array<ClientTenantDataFilterInput>>;
  clientState?: InputMaybe<ClientStateFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  name?: InputMaybe<CustomStringOperationFilterInput>;
  or?: InputMaybe<Array<ClientTenantDataFilterInput>>;
  tenantName?: InputMaybe<CustomStringOperationFilterInput>;
  type?: InputMaybe<ClientTypeOperationFilterInput>;
};

export type ClientTenantDataSortInput = {
  clientState?: InputMaybe<ClientStateSortInput>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantName?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum ClientType {
  Android = "ANDROID",
  Exchange = "EXCHANGE",
  Ios = "IOS",
  Linux = "LINUX",
  LinuxLightAgent = "LINUX_LIGHT_AGENT",
  Mac = "MAC",
  None = "NONE",
  RemoteScanServer = "REMOTE_SCAN_SERVER",
  Windows = "WINDOWS",
  WindowsLightAgent = "WINDOWS_LIGHT_AGENT",
}

export type ClientTypeOperationFilterInput = {
  eq?: InputMaybe<ClientType>;
  in?: InputMaybe<Array<ClientType>>;
  neq?: InputMaybe<ClientType>;
  nin?: InputMaybe<Array<ClientType>>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: "CollectionSegmentInfo";
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars["Boolean"];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars["Boolean"];
};

export type Company = {
  __typename?: "Company";
  name: Scalars["String"];
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  neq?: InputMaybe<Scalars["DateTime"]>;
  ngt?: InputMaybe<Scalars["DateTime"]>;
  ngte?: InputMaybe<Scalars["DateTime"]>;
  nin?: InputMaybe<Array<Scalars["DateTime"]>>;
  nlt?: InputMaybe<Scalars["DateTime"]>;
  nlte?: InputMaybe<Scalars["DateTime"]>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<Scalars["UUID"]>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<Scalars["UUID"]>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<Scalars["Int"]>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  neq?: InputMaybe<Scalars["DateTime"]>;
  ngt?: InputMaybe<Scalars["DateTime"]>;
  ngte?: InputMaybe<Scalars["DateTime"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  nlt?: InputMaybe<Scalars["DateTime"]>;
  nlte?: InputMaybe<Scalars["DateTime"]>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type CreateOrganizationUnitInput = {
  mavId?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
  parentId: Scalars["UUID"];
  resellerNumber?: InputMaybe<Scalars["String"]>;
};

export type CreateOrganizationUnitPayload = {
  __typename?: "CreateOrganizationUnitPayload";
  organizationUnitWithMetaData?: Maybe<OrganizationUnitWithMetaData>;
};

export type CreateRecommendedActionFromTemplateInput = {
  clients: Array<ClientInput>;
  id: Scalars["UUID"];
  mavId: Scalars["UUID"];
  tenantName: Scalars["String"];
};

export type CreateRecommendedActionTemplateInput = {
  cultureSpecifics: Array<RecommendedActionTemplateCultureSpecificsInput>;
  isPublic: Scalars["Boolean"];
  name: Scalars["String"];
  priority: Priority;
  tag: Tag;
  type: TemplateRecommendedActionType;
};

export type CreateRecommendedActionTemplatePayload = {
  __typename?: "CreateRecommendedActionTemplatePayload";
  recommendedActionTemplate?: Maybe<RecommendedActionTemplate>;
};

export type CreateUserInput = {
  address?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  language: Language;
  lastName: Scalars["String"];
  licenseLogin: Scalars["String"];
  organizationUnitId: Scalars["UUID"];
  password: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  postCode?: InputMaybe<Scalars["String"]>;
  regnumbers: Array<Scalars["String"]>;
  role: Roles;
  tenants: Array<Scalars["String"]>;
  userName: Scalars["String"];
};

export type CredentialsInput = {
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type CustomStringOperationFilterInput = {
  and?: InputMaybe<Array<CustomStringOperationFilterInput>>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  icontains?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ncontains?: InputMaybe<Scalars["String"]>;
  nendsWith?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nstartsWith?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<CustomStringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type DeleteRecommendedActionTemplateInput = {
  id: Array<Scalars["UUID"]>;
};

export enum EmailType {
  ForgottenPassword = "FORGOTTEN_PASSWORD",
  NewHighRecommendedAction = "NEW_HIGH_RECOMMENDED_ACTION",
  Register = "REGISTER",
}

export enum EngineArchitectureType {
  None = "NONE",
  X64 = "X64",
  X86 = "X86",
}

export type EngineArchitectureTypeOperationFilterInput = {
  eq?: InputMaybe<EngineArchitectureType>;
  in?: InputMaybe<Array<EngineArchitectureType>>;
  neq?: InputMaybe<EngineArchitectureType>;
  nin?: InputMaybe<Array<EngineArchitectureType>>;
};

export type InstallPackage = {
  __typename?: "InstallPackage";
  downloadUrl: Scalars["String"];
  type: PackageType;
};

export enum Language {
  De = "DE",
  En = "EN",
  None = "NONE",
  Pl = "PL",
}

export type LanguageOperationFilterInput = {
  eq?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  neq?: InputMaybe<Language>;
  nin?: InputMaybe<Array<Language>>;
};

export type ListFilterInputTypeOfRecommendedActionCultureSpecificFilterInput = {
  all?: InputMaybe<RecommendedActionCultureSpecificFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<RecommendedActionCultureSpecificFilterInput>;
  some?: InputMaybe<RecommendedActionCultureSpecificFilterInput>;
};

export type ListFilterInputTypeOfRecommendedActionTemplateCultureSpecificsFilterInput = {
  all?: InputMaybe<RecommendedActionTemplateCultureSpecificsFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<RecommendedActionTemplateCultureSpecificsFilterInput>;
  some?: InputMaybe<RecommendedActionTemplateCultureSpecificsFilterInput>;
};

export type ListFilterInputTypeOfSignatureFilterInput = {
  all?: InputMaybe<SignatureFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SignatureFilterInput>;
  some?: InputMaybe<SignatureFilterInput>;
};

export type MavIdNameDataInput = {
  mavId: Scalars["UUID"];
  tenantName: Scalars["String"];
};

export type MmsTenant = {
  __typename?: "MmsTenant";
  mavId?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MmsTenantFilterInput = {
  and?: InputMaybe<Array<MmsTenantFilterInput>>;
  mavId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  name?: InputMaybe<CustomStringOperationFilterInput>;
  or?: InputMaybe<Array<MmsTenantFilterInput>>;
};

export type MmsTenantSortInput = {
  mavId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type Mutation = {
  __typename?: "Mutation";
  activateUser: Scalars["Boolean"];
  cleanUpUser: Scalars["Boolean"];
  createOrUpdateUserSettings: UserSettings;
  createOrganizationUnit: CreateOrganizationUnitPayload;
  createRecommendedActionFromTemplate: Scalars["Boolean"];
  createRecommendedActionTemplate: CreateRecommendedActionTemplatePayload;
  createUser?: Maybe<Scalars["UUID"]>;
  createUserOld?: Maybe<Scalars["UUID"]>;
  createUserToken: UserToken;
  deleteOrganizationUnit: Scalars["Boolean"];
  deleteRecommendedAction: Scalars["Boolean"];
  deleteRecommendedActionByMavId: Scalars["Boolean"];
  deleteRecommendedActionTemplates: Scalars["Boolean"];
  deleteUser: Scalars["Boolean"];
  deleteUserSettings: Scalars["Boolean"];
  deleteUserToken: Scalars["Boolean"];
  forgottenPassword: Scalars["Boolean"];
  resetPassword: Scalars["Boolean"];
  resolveRecommendedAction?: Maybe<RecommendedAction>;
  sendMailToUser: Scalars["Boolean"];
  sendMailToUsersByMavId: Scalars["Boolean"];
  setNewForgottenPassword: Scalars["Boolean"];
  setNewPassword: Scalars["Boolean"];
  setOrganizationUnitId: Scalars["Boolean"];
  unresolveRecommendedAction?: Maybe<RecommendedAction>;
  updateCreatedUser: Scalars["Boolean"];
  updateRecommendedActionTemplate: UpdateRecommendedActionTemplatePayload;
  updateUser: UpdatedUserResult;
  userRegistered?: Maybe<Array<Scalars["Boolean"]>>;
};

export type MutationActivateUserArgs = {
  isActive: Scalars["Boolean"];
  userId: Scalars["UUID"];
};

export type MutationCleanUpUserArgs = {
  organizationUnitName: Scalars["String"];
  userName: Scalars["String"];
};

export type MutationCreateOrUpdateUserSettingsArgs = {
  input: UserSettingsInput;
};

export type MutationCreateOrganizationUnitArgs = {
  input: CreateOrganizationUnitInput;
};

export type MutationCreateRecommendedActionFromTemplateArgs = {
  input: CreateRecommendedActionFromTemplateInput;
};

export type MutationCreateRecommendedActionTemplateArgs = {
  input: CreateRecommendedActionTemplateInput;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationCreateUserOldArgs = {
  token: Scalars["String"];
  user: CreateUserInput;
};

export type MutationCreateUserTokenArgs = {
  daysValid: Scalars["Int"];
  userId: Scalars["UUID"];
};

export type MutationDeleteOrganizationUnitArgs = {
  id: Scalars["UUID"];
};

export type MutationDeleteRecommendedActionArgs = {
  recommendedActionIds: Array<Scalars["UUID"]>;
};

export type MutationDeleteRecommendedActionByMavIdArgs = {
  mavIds: Array<Scalars["UUID"]>;
};

export type MutationDeleteRecommendedActionTemplatesArgs = {
  input: DeleteRecommendedActionTemplateInput;
};

export type MutationDeleteUserArgs = {
  userId: Scalars["UUID"];
};

export type MutationDeleteUserSettingsArgs = {
  userId: Scalars["UUID"];
};

export type MutationDeleteUserTokenArgs = {
  userId: Scalars["UUID"];
};

export type MutationForgottenPasswordArgs = {
  language: Scalars["String"];
  userName: Scalars["String"];
};

export type MutationResolveRecommendedActionArgs = {
  recommendedActionId: Scalars["UUID"];
};

export type MutationSendMailToUserArgs = {
  input: SendMailByIdInput;
};

export type MutationSendMailToUsersByMavIdArgs = {
  input: SendMailByMavIdInput;
};

export type MutationSetNewForgottenPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["UUID"];
};

export type MutationSetNewPasswordArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationSetOrganizationUnitIdArgs = {
  organizationUnitId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

export type MutationUnresolveRecommendedActionArgs = {
  recommendedActionId: Scalars["UUID"];
};

export type MutationUpdateCreatedUserArgs = {
  token: Scalars["UUID"];
  user: UpdateCreatedUserInput;
};

export type MutationUpdateRecommendedActionTemplateArgs = {
  input: UpdateRecommendedActionTemplateInput;
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type MutationUserRegisteredArgs = {
  userSettings: UserSettingsInput;
};

export type OrganizationUnitWithMetaData = {
  __typename?: "OrganizationUnitWithMetaData";
  clients?: Maybe<Array<Maybe<Client>>>;
  clientsCount?: Maybe<Scalars["Int"]>;
  clientsWithFilter?: Maybe<Array<Maybe<Client>>>;
  descendantCount: Scalars["Int"];
  id: Scalars["UUID"];
  isLeaf: Scalars["Boolean"];
  left: Scalars["Int"];
  mavId?: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  resellerNumber?: Maybe<Scalars["String"]>;
  right: Scalars["Int"];
  statistics?: Maybe<Statistics>;
  statisticsWithFilter?: Maybe<TenantStatisticData>;
};

export type OrganizationUnitWithMetaDataClientsWithFilterArgs = {
  clientId?: InputMaybe<Array<Scalars["Int"]>>;
};

export type OrganizationUnitWithMetaDataStatisticsWithFilterArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type OrganizationUnitWithMetaDataFilterInput = {
  and?: InputMaybe<Array<OrganizationUnitWithMetaDataFilterInput>>;
  descendantCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isLeaf?: InputMaybe<BooleanOperationFilterInput>;
  left?: InputMaybe<ComparableInt32OperationFilterInput>;
  mavId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  name?: InputMaybe<CustomStringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationUnitWithMetaDataFilterInput>>;
  resellerNumber?: InputMaybe<CustomStringOperationFilterInput>;
  right?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export enum PackageType {
  Linux = "LINUX",
  Mac = "MAC",
  Windows = "WINDOWS",
}

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type PriorityOperationFilterInput = {
  eq?: InputMaybe<Priority>;
  in?: InputMaybe<Array<Priority>>;
  neq?: InputMaybe<Priority>;
  nin?: InputMaybe<Array<Priority>>;
};

export type ProtectionStatistics = {
  __typename?: "ProtectionStatistics";
  blockedWebsites: Scalars["Int"];
  infectedFiles: Scalars["Int"];
  malwareMails: Scalars["Int"];
};

export type ProtectionStatisticsSortInput = {
  blockedWebsites?: InputMaybe<SortEnumType>;
  infectedFiles?: InputMaybe<SortEnumType>;
  malwareMails?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: "Query";
  allClients?: Maybe<Array<ClientTenantData>>;
  allClientsWithPaging?: Maybe<ClientTenantDataCollectionSegment>;
  allOrganizationUnits: Array<OrganizationUnitWithMetaData>;
  allStatistics?: Maybe<Statistics>;
  allTenants?: Maybe<Array<MmsTenant>>;
  login?: Maybe<TokenResult>;
  organizationUnitAscendants: Array<OrganizationUnitWithMetaData>;
  organizationUnitWithDescendants: Array<OrganizationUnitWithMetaData>;
  organizationUnitWithDescendantsById: Array<OrganizationUnitWithMetaData>;
  recommendedAction?: Maybe<RecommendedAction>;
  recommendedActionTemplate: RecommendedActionTemplate;
  recommendedActionTemplatesWithPaging?: Maybe<RecommendedActionTemplateCollectionSegment>;
  recommendedActionsWithPaging?: Maybe<RecommendedActionCollectionSegment>;
  resolveTenantsFromRegnumber?: Maybe<Array<Tenant>>;
  /** Returns the security status for the given tenants. */
  securityStatus?: Maybe<SecurityStatus>;
  selfUser: User;
  user?: Maybe<User>;
  userByName?: Maybe<User>;
  userExists: Scalars["Boolean"];
  userRoles: Array<Roles>;
  userSettings: UserSettings;
  userTokenFromToken: UserToken;
  users?: Maybe<Array<User>>;
  validateToken?: Maybe<User>;
};

export type QueryAllClientsArgs = {
  clientIds?: InputMaybe<Array<Scalars["Int"]>>;
  mavId?: InputMaybe<Scalars["UUID"]>;
  order?: InputMaybe<Array<ClientTenantDataSortInput>>;
  where?: InputMaybe<ClientTenantDataFilterInput>;
};

export type QueryAllClientsWithPagingArgs = {
  clientIds?: InputMaybe<Array<Scalars["Int"]>>;
  mavId?: InputMaybe<Scalars["UUID"]>;
  order?: InputMaybe<Array<ClientTenantDataSortInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ClientTenantDataFilterInput>;
};

export type QueryAllOrganizationUnitsArgs = {
  where?: InputMaybe<OrganizationUnitWithMetaDataFilterInput>;
};

export type QueryAllStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  mavIds?: InputMaybe<Array<Scalars["UUID"]>>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type QueryAllTenantsArgs = {
  order?: InputMaybe<Array<MmsTenantSortInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MmsTenantFilterInput>;
};

export type QueryLoginArgs = {
  credentials: CredentialsInput;
};

export type QueryOrganizationUnitAscendantsArgs = {
  where?: InputMaybe<OrganizationUnitWithMetaDataFilterInput>;
};

export type QueryOrganizationUnitWithDescendantsArgs = {
  where?: InputMaybe<OrganizationUnitWithMetaDataFilterInput>;
};

export type QueryOrganizationUnitWithDescendantsByIdArgs = {
  orgId: Scalars["UUID"];
  where?: InputMaybe<OrganizationUnitWithMetaDataFilterInput>;
};

export type QueryRecommendedActionArgs = {
  id: Scalars["UUID"];
  language?: Language;
  order?: InputMaybe<Array<RecommendedActionSortInput>>;
  where?: InputMaybe<RecommendedActionFilterInput>;
};

export type QueryRecommendedActionTemplateArgs = {
  id: Scalars["UUID"];
};

export type QueryRecommendedActionTemplatesWithPagingArgs = {
  order?: InputMaybe<Array<RecommendedActionTemplateSortInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RecommendedActionTemplateFilterInput>;
};

export type QueryRecommendedActionsWithPagingArgs = {
  language?: Language;
  mavIds?: InputMaybe<Array<Scalars["UUID"]>>;
  order?: InputMaybe<Array<RecommendedActionSortInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RecommendedActionFilterInput>;
};

export type QueryResolveTenantsFromRegnumberArgs = {
  regnumbers?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySecurityStatusArgs = {
  mavIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryUserArgs = {
  userId: Scalars["UUID"];
};

export type QueryUserByNameArgs = {
  username: Scalars["String"];
};

export type QueryUserExistsArgs = {
  username: Scalars["String"];
};

export type QueryUserRolesArgs = {
  userId: Scalars["UUID"];
};

export type QueryUserSettingsArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
  issuingAuthority?: InputMaybe<Scalars["String"]>;
};

export type QueryUserTokenFromTokenArgs = {
  userToken: Scalars["UUID"];
};

export type QueryValidateTokenArgs = {
  token: Scalars["String"];
};

export type RecommendedAction = {
  __typename?: "RecommendedAction";
  clientId: Scalars["Int"];
  clientName: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdByUserFullName: Scalars["String"];
  createdByUserId: Scalars["UUID"];
  id: Scalars["UUID"];
  mavId: Scalars["UUID"];
  priority: Priority;
  recommendedActionCultureSpecifics: Array<RecommendedActionCultureSpecific>;
  resolvedAt?: Maybe<Scalars["DateTime"]>;
  resolvedByUserFullName?: Maybe<Scalars["String"]>;
  resolvedByUserId?: Maybe<Scalars["UUID"]>;
  state: RecommendedActionState;
  tag: Tag;
  tenantName: Scalars["String"];
};

export type RecommendedActionCollectionSegment = {
  __typename?: "RecommendedActionCollectionSegment";
  items?: Maybe<Array<RecommendedAction>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars["Int"];
};

export type RecommendedActionCultureSpecific = {
  __typename?: "RecommendedActionCultureSpecific";
  description: Scalars["String"];
  language: Language;
  summary: Scalars["String"];
};

export type RecommendedActionCultureSpecificFilterInput = {
  and?: InputMaybe<Array<RecommendedActionCultureSpecificFilterInput>>;
  description?: InputMaybe<CustomStringOperationFilterInput>;
  language?: InputMaybe<LanguageOperationFilterInput>;
  or?: InputMaybe<Array<RecommendedActionCultureSpecificFilterInput>>;
  recommendedActionId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  summary?: InputMaybe<CustomStringOperationFilterInput>;
};

export type RecommendedActionFilterInput = {
  and?: InputMaybe<Array<RecommendedActionFilterInput>>;
  clientId?: InputMaybe<ComparableInt32OperationFilterInput>;
  clientName?: InputMaybe<CustomStringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  createdByUserFullName?: InputMaybe<CustomStringOperationFilterInput>;
  createdByUserId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  mavId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<RecommendedActionFilterInput>>;
  priority?: InputMaybe<PriorityOperationFilterInput>;
  recommendedActionCultureSpecifics?: InputMaybe<ListFilterInputTypeOfRecommendedActionCultureSpecificFilterInput>;
  resolvedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  resolvedByUserFullName?: InputMaybe<CustomStringOperationFilterInput>;
  resolvedByUserId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: InputMaybe<RecommendedActionStateOperationFilterInput>;
  tag?: InputMaybe<TagOperationFilterInput>;
  tenantName?: InputMaybe<CustomStringOperationFilterInput>;
};

export type RecommendedActionSortInput = {
  clientId?: InputMaybe<SortEnumType>;
  clientName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdByUserFullName?: InputMaybe<SortEnumType>;
  createdByUserId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  mavId?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  resolvedAt?: InputMaybe<SortEnumType>;
  resolvedByUserFullName?: InputMaybe<SortEnumType>;
  resolvedByUserId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  tag?: InputMaybe<SortEnumType>;
  tenantName?: InputMaybe<SortEnumType>;
};

export enum RecommendedActionState {
  None = "NONE",
  Solved = "SOLVED",
  Unsolved = "UNSOLVED",
}

export type RecommendedActionStateOperationFilterInput = {
  eq?: InputMaybe<RecommendedActionState>;
  in?: InputMaybe<Array<RecommendedActionState>>;
  neq?: InputMaybe<RecommendedActionState>;
  nin?: InputMaybe<Array<RecommendedActionState>>;
};

export type RecommendedActionTemplate = {
  __typename?: "RecommendedActionTemplate";
  accessRights: AccessRights;
  cultureSpecifics: Array<RecommendedActionTemplateCultureSpecifics>;
  id: Scalars["UUID"];
  isPublic: Scalars["Boolean"];
  name: Scalars["String"];
  priority: Priority;
  tag: Tag;
  type: TemplateRecommendedActionType;
};

export type RecommendedActionTemplateCollectionSegment = {
  __typename?: "RecommendedActionTemplateCollectionSegment";
  items?: Maybe<Array<RecommendedActionTemplate>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars["Int"];
};

export type RecommendedActionTemplateCultureSpecifics = {
  __typename?: "RecommendedActionTemplateCultureSpecifics";
  description: Scalars["String"];
  language: Language;
  summary: Scalars["String"];
};

export type RecommendedActionTemplateCultureSpecificsFilterInput = {
  and?: InputMaybe<Array<RecommendedActionTemplateCultureSpecificsFilterInput>>;
  description?: InputMaybe<CustomStringOperationFilterInput>;
  language?: InputMaybe<LanguageOperationFilterInput>;
  or?: InputMaybe<Array<RecommendedActionTemplateCultureSpecificsFilterInput>>;
  summary?: InputMaybe<CustomStringOperationFilterInput>;
};

export type RecommendedActionTemplateCultureSpecificsInput = {
  description: Scalars["String"];
  language: Language;
  summary: Scalars["String"];
};

export type RecommendedActionTemplateFilterInput = {
  and?: InputMaybe<Array<RecommendedActionTemplateFilterInput>>;
  cultureSpecifics?: InputMaybe<ListFilterInputTypeOfRecommendedActionTemplateCultureSpecificsFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<CustomStringOperationFilterInput>;
  or?: InputMaybe<Array<RecommendedActionTemplateFilterInput>>;
  organizationUnitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  priority?: InputMaybe<PriorityOperationFilterInput>;
  tag?: InputMaybe<TagOperationFilterInput>;
  type?: InputMaybe<TemplateRecommendedActionTypeOperationFilterInput>;
};

export type RecommendedActionTemplateSortInput = {
  id?: InputMaybe<SortEnumType>;
  isPublic?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organizationUnitId?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  tag?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum Roles {
  Analyst = "ANALYST",
  AuthenticatedUser = "AUTHENTICATED_USER",
  Developer = "DEVELOPER",
  Reseller = "RESELLER",
}

export type SecurityStatus = {
  __typename?: "SecurityStatus";
  recommendedActionsHigh: Scalars["Int"];
  recommendedActionsLow: Scalars["Int"];
  recommendedActionsMedium: Scalars["Int"];
};

export type SendMailByIdInput = {
  type: EmailType;
  userId: Scalars["UUID"];
  variables: Scalars["String"];
};

export type SendMailByMavIdInput = {
  mavId: Scalars["UUID"];
  type: EmailType;
  variables: Scalars["String"];
};

export type Signature = {
  __typename?: "Signature";
  releaseDate?: Maybe<Scalars["DateTime"]>;
  type: ClientSignatureType;
  upToDate: Scalars["Boolean"];
  version?: Maybe<Scalars["String"]>;
};

export type SignatureFilterInput = {
  and?: InputMaybe<Array<SignatureFilterInput>>;
  or?: InputMaybe<Array<SignatureFilterInput>>;
  releaseDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  type?: InputMaybe<ClientSignatureTypeOperationFilterInput>;
  upToDate?: InputMaybe<BooleanOperationFilterInput>;
  version?: InputMaybe<CustomStringOperationFilterInput>;
};

export enum SortEnumType {
  Asc = "ASC",
  Desc = "DESC",
}

export type Statistics = {
  __typename?: "Statistics";
  clientCount: Scalars["Int"];
  expertActivities: Scalars["Int"];
  from: Scalars["DateTime"];
  protectionStatistics: ProtectionStatistics;
  to: Scalars["DateTime"];
  updateStatistics: UpdateStatistics;
};

export type StatisticsSortInput = {
  clientCount?: InputMaybe<SortEnumType>;
  expertActivities?: InputMaybe<SortEnumType>;
  from?: InputMaybe<SortEnumType>;
  protectionStatistics?: InputMaybe<ProtectionStatisticsSortInput>;
  to?: InputMaybe<SortEnumType>;
  updateStatistics?: InputMaybe<UpdateStatisticsSortInput>;
};

export enum Tag {
  General = "GENERAL",
  None = "NONE",
  Welcome = "WELCOME",
}

export type TagOperationFilterInput = {
  eq?: InputMaybe<Tag>;
  in?: InputMaybe<Array<Tag>>;
  neq?: InputMaybe<Tag>;
  nin?: InputMaybe<Array<Tag>>;
};

export enum TemplateRecommendedActionType {
  None = "NONE",
  UserRegistered = "USER_REGISTERED",
}

export type TemplateRecommendedActionTypeOperationFilterInput = {
  eq?: InputMaybe<TemplateRecommendedActionType>;
  in?: InputMaybe<Array<TemplateRecommendedActionType>>;
  neq?: InputMaybe<TemplateRecommendedActionType>;
  nin?: InputMaybe<Array<TemplateRecommendedActionType>>;
};

export type Tenant = {
  __typename?: "Tenant";
  company: Scalars["String"];
  installPackages: Array<InstallPackage>;
  isLimited: Scalars["Boolean"];
  limit?: Maybe<Scalars["DateTime"]>;
  login: Scalars["String"];
  mavId: Scalars["UUID"];
  mmsHost: Scalars["String"];
  regnumber: Scalars["String"];
};

export type TenantClientData = {
  __typename?: "TenantClientData";
  clients?: Maybe<Array<Client>>;
  clientsCount: Scalars["Int"];
  mavId?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TenantClientDataSortInput = {
  clientsCount?: InputMaybe<SortEnumType>;
  mavId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type TenantStatisticData = {
  __typename?: "TenantStatisticData";
  clientsCount: Scalars["Int"];
  mavId?: Maybe<Scalars["UUID"]>;
  name?: Maybe<Scalars["String"]>;
  statistics?: Maybe<Statistics>;
};

export type TenantStatisticDataSortInput = {
  clientsCount?: InputMaybe<SortEnumType>;
  mavId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  statistics?: InputMaybe<StatisticsSortInput>;
};

export type TokenResult = {
  __typename?: "TokenResult";
  accessToken?: Maybe<Scalars["String"]>;
  expiresIn: Scalars["Int"];
  notBeforePolicy: Scalars["Int"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
  sessionState?: Maybe<Scalars["String"]>;
  tokenType?: Maybe<Scalars["String"]>;
};

export type UpdateCreatedUserInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type UpdateRecommendedActionTemplateInput = {
  cultureSpecifics: Array<RecommendedActionTemplateCultureSpecificsInput>;
  id: Scalars["UUID"];
  isPublic: Scalars["Boolean"];
  name: Scalars["String"];
  priority: Priority;
  tag: Tag;
  type: TemplateRecommendedActionType;
};

export type UpdateRecommendedActionTemplatePayload = {
  __typename?: "UpdateRecommendedActionTemplatePayload";
  recommendedActionTemplate?: Maybe<RecommendedActionTemplate>;
};

export type UpdateStatistics = {
  __typename?: "UpdateStatistics";
  clientUpdateCount: Scalars["Int"];
  signaturesUpdateCount: Scalars["Int"];
};

export type UpdateStatisticsSortInput = {
  clientUpdateCount?: InputMaybe<SortEnumType>;
  signaturesUpdateCount?: InputMaybe<SortEnumType>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type UpdatedUserResult = {
  __typename?: "UpdatedUserResult";
  isUpdated: Scalars["Boolean"];
  user: User;
};

export type User = {
  __typename?: "User";
  company?: Maybe<Company>;
  eMail?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  ident?: Maybe<Scalars["String"]>;
  issuingAuthority?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  organizationUnitId?: Maybe<Scalars["UUID"]>;
  phone?: Maybe<Scalars["String"]>;
  regnumbers?: Maybe<Array<Scalars["String"]>>;
  settings?: Maybe<UserSettings>;
  tenants?: Maybe<Array<Tenant>>;
  userName?: Maybe<Scalars["String"]>;
};

export type UserSettings = {
  __typename?: "UserSettings";
  issuingAuthority: Scalars["String"];
  language: Language;
  sendSecurityReports: Scalars["Boolean"];
  userId: Scalars["String"];
};

export type UserSettingsInput = {
  issuingAuthority?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Language>;
  mavIdNameTuple?: InputMaybe<Array<MavIdNameDataInput>>;
  sendSecurityReports?: InputMaybe<Scalars["Boolean"]>;
  userId: Scalars["UUID"];
};

export type UserToken = {
  __typename?: "UserToken";
  token: Scalars["UUID"];
  userId: Scalars["UUID"];
  validUntil: Scalars["DateTime"];
};

export type AllTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTenantsQuery = {
  __typename?: "Query";
  allTenants?: Array<{ __typename?: "MmsTenant"; mavId?: any | null; name?: string | null }> | null;
};

export type ClientFragment = {
  __typename?: "Client";
  name?: string | null;
  clientState?: {
    __typename?: "ClientState";
    osName?: string | null;
    clientVersion?: string | null;
    signatures: Array<{
      __typename?: "Signature";
      type: ClientSignatureType;
      version?: string | null;
      releaseDate?: any | null;
      upToDate: boolean;
    }>;
  } | null;
};

export type ClientTenantDataFragment = {
  __typename?: "ClientTenantData";
  name: string;
  type: ClientType;
  tenantName: string;
  clientState?: {
    __typename?: "ClientState";
    osName?: string | null;
    clientVersion?: string | null;
    signatures: Array<{
      __typename?: "Signature";
      type: ClientSignatureType;
      version?: string | null;
      releaseDate?: any | null;
      upToDate: boolean;
    }>;
  } | null;
};

export type ClientsByTenantQueryVariables = Exact<{
  input: Scalars["UUID"];
}>;

export type ClientsByTenantQuery = {
  __typename?: "Query";
  allClients?: Array<{ __typename?: "ClientTenantData"; id: number; name: string; tenantName: string }> | null;
};

export type ClientsWithPagingQueryVariables = Exact<{
  mavId?: InputMaybe<Scalars["UUID"]>;
  clientIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ClientTenantDataFilterInput>;
  order?: InputMaybe<Array<ClientTenantDataSortInput> | ClientTenantDataSortInput>;
}>;

export type ClientsWithPagingQuery = {
  __typename?: "Query";
  allClientsWithPaging?: {
    __typename?: "ClientTenantDataCollectionSegment";
    items?: Array<{
      __typename?: "ClientTenantData";
      name: string;
      type: ClientType;
      tenantName: string;
      clientState?: {
        __typename?: "ClientState";
        osName?: string | null;
        clientVersion?: string | null;
        signatures: Array<{
          __typename?: "Signature";
          type: ClientSignatureType;
          version?: string | null;
          releaseDate?: any | null;
          upToDate: boolean;
        }>;
      } | null;
    }> | null;
  } | null;
};

export type ClientsWithPagingCountQueryVariables = Exact<{
  mavId?: InputMaybe<Scalars["UUID"]>;
  clientIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ClientTenantDataFilterInput>;
  order?: InputMaybe<Array<ClientTenantDataSortInput> | ClientTenantDataSortInput>;
}>;

export type ClientsWithPagingCountQuery = {
  __typename?: "Query";
  allClientsWithPaging?: { __typename?: "ClientTenantDataCollectionSegment"; totalCount: number } | null;
};

export type CreateOrUpdateUserSettingsMutationVariables = Exact<{
  input: UserSettingsInput;
}>;

export type CreateOrUpdateUserSettingsMutation = {
  __typename?: "Mutation";
  createOrUpdateUserSettings: {
    __typename?: "UserSettings";
    userId: string;
    issuingAuthority: string;
    sendSecurityReports: boolean;
    language: Language;
  };
};

export type CreateRecommendedActionFromTemplateMutationVariables = Exact<{
  input: CreateRecommendedActionFromTemplateInput;
}>;

export type CreateRecommendedActionFromTemplateMutation = {
  __typename?: "Mutation";
  createRecommendedActionFromTemplate: boolean;
};

export type CreateRecommendedActionTemplateMutationVariables = Exact<{
  input: CreateRecommendedActionTemplateInput;
}>;

export type CreateRecommendedActionTemplateMutation = {
  __typename?: "Mutation";
  createRecommendedActionTemplate: {
    __typename?: "CreateRecommendedActionTemplatePayload";
    recommendedActionTemplate?: {
      __typename?: "RecommendedActionTemplate";
      id: any;
      priority: Priority;
      isPublic: boolean;
      tag: Tag;
      type: TemplateRecommendedActionType;
      name: string;
      cultureSpecifics: Array<{
        __typename?: "RecommendedActionTemplateCultureSpecifics";
        description: string;
        language: Language;
        summary: string;
      }>;
      accessRights: { __typename?: "AccessRights"; canEdit: boolean; canDelete: boolean };
    } | null;
  };
};

export type DeleteRecommendedActionMutationVariables = Exact<{
  input: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type DeleteRecommendedActionMutation = { __typename?: "Mutation"; deleteRecommendedAction: boolean };

export type DeleteRecommendedActionTemplatesMutationVariables = Exact<{
  input: DeleteRecommendedActionTemplateInput;
}>;

export type DeleteRecommendedActionTemplatesMutation = {
  __typename?: "Mutation";
  deleteRecommendedActionTemplates: boolean;
};

export type GetCompanyNameFromRegnumberQueryVariables = Exact<{
  input: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetCompanyNameFromRegnumberQuery = {
  __typename?: "Query";
  resolveTenantsFromRegnumber?: Array<{ __typename?: "Tenant"; company: string }> | null;
};

export type GetDashboardDataQueryVariables = Exact<{
  mavIds?: InputMaybe<Array<Scalars["UUID"]> | Scalars["UUID"]>;
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
}>;

export type GetDashboardDataQuery = {
  __typename?: "Query";
  allStatistics?: {
    __typename?: "Statistics";
    clientCount: number;
    expertActivities: number;
    protectionStatistics: {
      __typename?: "ProtectionStatistics";
      blockedWebsites: number;
      infectedFiles: number;
      malwareMails: number;
    };
    updateStatistics: { __typename?: "UpdateStatistics"; clientUpdateCount: number; signaturesUpdateCount: number };
  } | null;
  securityStatus?: {
    __typename?: "SecurityStatus";
    recommendedActionsHigh: number;
    recommendedActionsMedium: number;
    recommendedActionsLow: number;
  } | null;
};

export type GetInstallPackagesFromRegnumbersQueryVariables = Exact<{ [key: string]: never }>;

export type GetInstallPackagesFromRegnumbersQuery = {
  __typename?: "Query";
  resolveTenantsFromRegnumber?: Array<{
    __typename?: "Tenant";
    installPackages: Array<{ __typename?: "InstallPackage"; type: PackageType; downloadUrl: string }>;
  }> | null;
};

export type GetMavIdFromRegnumberQueryVariables = Exact<{
  input: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetMavIdFromRegnumberQuery = {
  __typename?: "Query";
  resolveTenantsFromRegnumber?: Array<{ __typename?: "Tenant"; mavId: any }> | null;
};

export type GetOwnUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetOwnUserQuery = {
  __typename?: "Query";
  selfUser: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    eMail?: string | null;
    phone?: string | null;
    enabled: boolean;
    company?: { __typename?: "Company"; name: string } | null;
    settings?: {
      __typename?: "UserSettings";
      userId: string;
      issuingAuthority: string;
      sendSecurityReports: boolean;
      language: Language;
    } | null;
  };
};

export type RecommendedActionBaseFragment = {
  __typename?: "RecommendedAction";
  id: any;
  mavId: any;
  tenantName: string;
  clientId: number;
  clientName: string;
  priority: Priority;
  state: RecommendedActionState;
  tag: Tag;
  createdAt: any;
  recommendedActionCultureSpecifics: Array<{
    __typename?: "RecommendedActionCultureSpecific";
    language: Language;
    summary: string;
    description: string;
  }>;
};

export type RecommendedActionExtendedFragment = {
  __typename?: "RecommendedAction";
  resolvedByUserFullName?: string | null;
  createdByUserFullName: string;
  resolvedAt?: any | null;
  id: any;
  mavId: any;
  tenantName: string;
  clientId: number;
  clientName: string;
  priority: Priority;
  state: RecommendedActionState;
  tag: Tag;
  createdAt: any;
  recommendedActionCultureSpecifics: Array<{
    __typename?: "RecommendedActionCultureSpecific";
    language: Language;
    summary: string;
    description: string;
  }>;
};

export type RecommendedActionTemplateQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type RecommendedActionTemplateQuery = {
  __typename?: "Query";
  recommendedActionTemplate: {
    __typename?: "RecommendedActionTemplate";
    id: any;
    priority: Priority;
    isPublic: boolean;
    tag: Tag;
    type: TemplateRecommendedActionType;
    name: string;
    cultureSpecifics: Array<{
      __typename?: "RecommendedActionTemplateCultureSpecifics";
      description: string;
      language: Language;
      summary: string;
    }>;
    accessRights: { __typename?: "AccessRights"; canEdit: boolean; canDelete: boolean };
  };
};

export type RecommendedActionTemplateFragment = {
  __typename?: "RecommendedActionTemplate";
  id: any;
  priority: Priority;
  isPublic: boolean;
  tag: Tag;
  type: TemplateRecommendedActionType;
  name: string;
  cultureSpecifics: Array<{
    __typename?: "RecommendedActionTemplateCultureSpecifics";
    description: string;
    language: Language;
    summary: string;
  }>;
  accessRights: { __typename?: "AccessRights"; canEdit: boolean; canDelete: boolean };
};

export type RecommendedActionTemplatesCountQueryVariables = Exact<{
  where?: InputMaybe<RecommendedActionTemplateFilterInput>;
  order?: InputMaybe<Array<RecommendedActionTemplateSortInput> | RecommendedActionTemplateSortInput>;
}>;

export type RecommendedActionTemplatesCountQuery = {
  __typename?: "Query";
  recommendedActionTemplatesWithPaging?: {
    __typename?: "RecommendedActionTemplateCollectionSegment";
    totalCount: number;
  } | null;
};

export type RecommendedActionTemplatesWithPagingQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RecommendedActionTemplateFilterInput>;
  order?: InputMaybe<Array<RecommendedActionTemplateSortInput> | RecommendedActionTemplateSortInput>;
}>;

export type RecommendedActionTemplatesWithPagingQuery = {
  __typename?: "Query";
  recommendedActionTemplatesWithPaging?: {
    __typename?: "RecommendedActionTemplateCollectionSegment";
    items?: Array<{
      __typename?: "RecommendedActionTemplate";
      id: any;
      priority: Priority;
      isPublic: boolean;
      tag: Tag;
      type: TemplateRecommendedActionType;
      name: string;
      cultureSpecifics: Array<{
        __typename?: "RecommendedActionTemplateCultureSpecifics";
        description: string;
        language: Language;
        summary: string;
      }>;
      accessRights: { __typename?: "AccessRights"; canEdit: boolean; canDelete: boolean };
    }> | null;
  } | null;
};

export type RecommendedActionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RecommendedActionFilterInput>;
  order?: InputMaybe<Array<RecommendedActionSortInput> | RecommendedActionSortInput>;
}>;

export type RecommendedActionsQuery = {
  __typename?: "Query";
  recommendedActionsWithPaging?: {
    __typename?: "RecommendedActionCollectionSegment";
    items?: Array<{
      __typename?: "RecommendedAction";
      id: any;
      mavId: any;
      tenantName: string;
      clientId: number;
      clientName: string;
      priority: Priority;
      state: RecommendedActionState;
      tag: Tag;
      createdAt: any;
      recommendedActionCultureSpecifics: Array<{
        __typename?: "RecommendedActionCultureSpecific";
        language: Language;
        summary: string;
        description: string;
      }>;
    }> | null;
  } | null;
};

export type RecommendedActionsAnalystQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RecommendedActionFilterInput>;
  order?: InputMaybe<Array<RecommendedActionSortInput> | RecommendedActionSortInput>;
}>;

export type RecommendedActionsAnalystQuery = {
  __typename?: "Query";
  recommendedActionsWithPaging?: {
    __typename?: "RecommendedActionCollectionSegment";
    items?: Array<{
      __typename?: "RecommendedAction";
      resolvedByUserFullName?: string | null;
      createdByUserFullName: string;
      resolvedAt?: any | null;
      id: any;
      mavId: any;
      tenantName: string;
      clientId: number;
      clientName: string;
      priority: Priority;
      state: RecommendedActionState;
      tag: Tag;
      createdAt: any;
      recommendedActionCultureSpecifics: Array<{
        __typename?: "RecommendedActionCultureSpecific";
        language: Language;
        summary: string;
        description: string;
      }>;
    }> | null;
  } | null;
};

export type RecommendedActionsCountQueryVariables = Exact<{
  where?: InputMaybe<RecommendedActionFilterInput>;
  order?: InputMaybe<Array<RecommendedActionSortInput> | RecommendedActionSortInput>;
}>;

export type RecommendedActionsCountQuery = {
  __typename?: "Query";
  recommendedActionsWithPaging?: { __typename?: "RecommendedActionCollectionSegment"; totalCount: number } | null;
};

export type ResolveRecommendedActionMutationVariables = Exact<{
  recommendedActionId: Scalars["UUID"];
}>;

export type ResolveRecommendedActionMutation = {
  __typename?: "Mutation";
  resolveRecommendedAction?: {
    __typename?: "RecommendedAction";
    id: any;
    mavId: any;
    tenantName: string;
    clientId: number;
    clientName: string;
    priority: Priority;
    state: RecommendedActionState;
    tag: Tag;
    createdAt: any;
    recommendedActionCultureSpecifics: Array<{
      __typename?: "RecommendedActionCultureSpecific";
      language: Language;
      summary: string;
      description: string;
    }>;
  } | null;
};

export type UnresolveRecommendedActionMutationVariables = Exact<{
  recommendedActionId: Scalars["UUID"];
}>;

export type UnresolveRecommendedActionMutation = {
  __typename?: "Mutation";
  unresolveRecommendedAction?: {
    __typename?: "RecommendedAction";
    id: any;
    mavId: any;
    tenantName: string;
    clientId: number;
    clientName: string;
    priority: Priority;
    state: RecommendedActionState;
    tag: Tag;
    createdAt: any;
    recommendedActionCultureSpecifics: Array<{
      __typename?: "RecommendedActionCultureSpecific";
      language: Language;
      summary: string;
      description: string;
    }>;
  } | null;
};

export type UpdateOwnPasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type UpdateOwnPasswordMutation = { __typename?: "Mutation"; setNewPassword: boolean };

export type UpdateOwnUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;

export type UpdateOwnUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "UpdatedUserResult";
    isUpdated: boolean;
    user: {
      __typename?: "User";
      id: any;
      eMail?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      enabled: boolean;
      company?: { __typename?: "Company"; name: string } | null;
    };
  };
};

export type UpdateRecommendedActionTemplateMutationVariables = Exact<{
  input: UpdateRecommendedActionTemplateInput;
}>;

export type UpdateRecommendedActionTemplateMutation = {
  __typename?: "Mutation";
  updateRecommendedActionTemplate: {
    __typename?: "UpdateRecommendedActionTemplatePayload";
    recommendedActionTemplate?: {
      __typename?: "RecommendedActionTemplate";
      id: any;
      priority: Priority;
      isPublic: boolean;
      tag: Tag;
      type: TemplateRecommendedActionType;
      name: string;
      cultureSpecifics: Array<{
        __typename?: "RecommendedActionTemplateCultureSpecifics";
        description: string;
        language: Language;
        summary: string;
      }>;
      accessRights: { __typename?: "AccessRights"; canEdit: boolean; canDelete: boolean };
    } | null;
  };
};

export type UserSettingQueryVariables = Exact<{ [key: string]: never }>;

export type UserSettingQuery = {
  __typename?: "Query";
  userSettings: {
    __typename?: "UserSettings";
    userId: string;
    issuingAuthority: string;
    sendSecurityReports: boolean;
    language: Language;
  };
};

export type UserSettingsFragment = {
  __typename?: "UserSettings";
  userId: string;
  issuingAuthority: string;
  sendSecurityReports: boolean;
  language: Language;
};

export const ClientFragmentDoc = `
    fragment client on Client {
  name
  clientState {
    osName
    clientVersion
    signatures {
      type
      version
      releaseDate
      upToDate
    }
  }
}
    `;
export const ClientTenantDataFragmentDoc = `
    fragment clientTenantData on ClientTenantData {
  name
  type
  tenantName
  clientState {
    osName
    clientVersion
    signatures {
      type
      version
      releaseDate
      upToDate
    }
  }
}
    `;
export const RecommendedActionBaseFragmentDoc = `
    fragment recommendedActionBase on RecommendedAction {
  id
  mavId
  tenantName
  clientId
  clientName
  priority
  state
  tag
  createdAt
  recommendedActionCultureSpecifics {
    language
    summary
    description
  }
}
    `;
export const RecommendedActionExtendedFragmentDoc = `
    fragment recommendedActionExtended on RecommendedAction {
  ...recommendedActionBase
  resolvedByUserFullName
  createdByUserFullName
  resolvedAt
}
    ${RecommendedActionBaseFragmentDoc}`;
export const RecommendedActionTemplateFragmentDoc = `
    fragment recommendedActionTemplate on RecommendedActionTemplate {
  id
  priority
  isPublic
  tag
  type
  name
  cultureSpecifics {
    description
    language
    summary
  }
  accessRights {
    canEdit
    canDelete
  }
}
    `;
export const UserSettingsFragmentDoc = `
    fragment userSettings on UserSettings {
  userId
  issuingAuthority
  sendSecurityReports
  language
}
    `;
export const AllTenantsDocument = `
    query AllTenants {
  allTenants(order: {name: ASC}) {
    mavId
    name
  }
}
    `;
export const useAllTenantsQuery = <TData = AllTenantsQuery, TError = unknown>(
  variables?: AllTenantsQueryVariables,
  options?: UseQueryOptions<AllTenantsQuery, TError, TData>
) =>
  useQuery<AllTenantsQuery, TError, TData>(
    variables === undefined ? ["AllTenants"] : ["AllTenants", variables],
    useFetcher<AllTenantsQuery, AllTenantsQueryVariables>(AllTenantsDocument).bind(null, variables),
    options
  );

useAllTenantsQuery.getKey = (variables?: AllTenantsQueryVariables) =>
  variables === undefined ? ["AllTenants"] : ["AllTenants", variables];
export const ClientsByTenantDocument = `
    query ClientsByTenant($input: UUID!) {
  allClients(mavId: $input, order: {name: ASC}) {
    id
    name
    tenantName
  }
}
    `;
export const useClientsByTenantQuery = <TData = ClientsByTenantQuery, TError = unknown>(
  variables: ClientsByTenantQueryVariables,
  options?: UseQueryOptions<ClientsByTenantQuery, TError, TData>
) =>
  useQuery<ClientsByTenantQuery, TError, TData>(
    ["ClientsByTenant", variables],
    useFetcher<ClientsByTenantQuery, ClientsByTenantQueryVariables>(ClientsByTenantDocument).bind(null, variables),
    options
  );

useClientsByTenantQuery.getKey = (variables: ClientsByTenantQueryVariables) => ["ClientsByTenant", variables];
export const ClientsWithPagingDocument = `
    query ClientsWithPaging($mavId: UUID, $clientIds: [Int!], $skip: Int, $take: Int, $where: ClientTenantDataFilterInput, $order: [ClientTenantDataSortInput!]) {
  allClientsWithPaging(
    mavId: $mavId
    clientIds: $clientIds
    where: $where
    skip: $skip
    take: $take
    order: $order
  ) {
    items {
      ...clientTenantData
    }
  }
}
    ${ClientTenantDataFragmentDoc}`;
export const useClientsWithPagingQuery = <TData = ClientsWithPagingQuery, TError = unknown>(
  variables?: ClientsWithPagingQueryVariables,
  options?: UseQueryOptions<ClientsWithPagingQuery, TError, TData>
) =>
  useQuery<ClientsWithPagingQuery, TError, TData>(
    variables === undefined ? ["ClientsWithPaging"] : ["ClientsWithPaging", variables],
    useFetcher<ClientsWithPagingQuery, ClientsWithPagingQueryVariables>(ClientsWithPagingDocument).bind(
      null,
      variables
    ),
    options
  );

useClientsWithPagingQuery.getKey = (variables?: ClientsWithPagingQueryVariables) =>
  variables === undefined ? ["ClientsWithPaging"] : ["ClientsWithPaging", variables];
export const ClientsWithPagingCountDocument = `
    query ClientsWithPagingCount($mavId: UUID, $clientIds: [Int!], $skip: Int, $take: Int, $where: ClientTenantDataFilterInput, $order: [ClientTenantDataSortInput!]) {
  allClientsWithPaging(
    mavId: $mavId
    clientIds: $clientIds
    where: $where
    skip: $skip
    take: $take
    order: $order
  ) {
    totalCount
  }
}
    `;
export const useClientsWithPagingCountQuery = <TData = ClientsWithPagingCountQuery, TError = unknown>(
  variables?: ClientsWithPagingCountQueryVariables,
  options?: UseQueryOptions<ClientsWithPagingCountQuery, TError, TData>
) =>
  useQuery<ClientsWithPagingCountQuery, TError, TData>(
    variables === undefined ? ["ClientsWithPagingCount"] : ["ClientsWithPagingCount", variables],
    useFetcher<ClientsWithPagingCountQuery, ClientsWithPagingCountQueryVariables>(ClientsWithPagingCountDocument).bind(
      null,
      variables
    ),
    options
  );

useClientsWithPagingCountQuery.getKey = (variables?: ClientsWithPagingCountQueryVariables) =>
  variables === undefined ? ["ClientsWithPagingCount"] : ["ClientsWithPagingCount", variables];
export const CreateOrUpdateUserSettingsDocument = `
    mutation CreateOrUpdateUserSettings($input: UserSettingsInput!) {
  createOrUpdateUserSettings(input: $input) {
    ...userSettings
  }
}
    ${UserSettingsFragmentDoc}`;
export const useCreateOrUpdateUserSettingsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateOrUpdateUserSettingsMutation,
    TError,
    CreateOrUpdateUserSettingsMutationVariables,
    TContext
  >
) =>
  useMutation<CreateOrUpdateUserSettingsMutation, TError, CreateOrUpdateUserSettingsMutationVariables, TContext>(
    ["CreateOrUpdateUserSettings"],
    useFetcher<CreateOrUpdateUserSettingsMutation, CreateOrUpdateUserSettingsMutationVariables>(
      CreateOrUpdateUserSettingsDocument
    ),
    options
  );
export const CreateRecommendedActionFromTemplateDocument = `
    mutation createRecommendedActionFromTemplate($input: CreateRecommendedActionFromTemplateInput!) {
  createRecommendedActionFromTemplate(input: $input)
}
    `;
export const useCreateRecommendedActionFromTemplateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateRecommendedActionFromTemplateMutation,
    TError,
    CreateRecommendedActionFromTemplateMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateRecommendedActionFromTemplateMutation,
    TError,
    CreateRecommendedActionFromTemplateMutationVariables,
    TContext
  >(
    ["createRecommendedActionFromTemplate"],
    useFetcher<CreateRecommendedActionFromTemplateMutation, CreateRecommendedActionFromTemplateMutationVariables>(
      CreateRecommendedActionFromTemplateDocument
    ),
    options
  );
export const CreateRecommendedActionTemplateDocument = `
    mutation CreateRecommendedActionTemplate($input: CreateRecommendedActionTemplateInput!) {
  createRecommendedActionTemplate(input: $input) {
    recommendedActionTemplate {
      ...recommendedActionTemplate
    }
  }
}
    ${RecommendedActionTemplateFragmentDoc}`;
export const useCreateRecommendedActionTemplateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateRecommendedActionTemplateMutation,
    TError,
    CreateRecommendedActionTemplateMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateRecommendedActionTemplateMutation,
    TError,
    CreateRecommendedActionTemplateMutationVariables,
    TContext
  >(
    ["CreateRecommendedActionTemplate"],
    useFetcher<CreateRecommendedActionTemplateMutation, CreateRecommendedActionTemplateMutationVariables>(
      CreateRecommendedActionTemplateDocument
    ),
    options
  );
export const DeleteRecommendedActionDocument = `
    mutation DeleteRecommendedAction($input: [UUID!]!) {
  deleteRecommendedAction(recommendedActionIds: $input)
}
    `;
export const useDeleteRecommendedActionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteRecommendedActionMutation,
    TError,
    DeleteRecommendedActionMutationVariables,
    TContext
  >
) =>
  useMutation<DeleteRecommendedActionMutation, TError, DeleteRecommendedActionMutationVariables, TContext>(
    ["DeleteRecommendedAction"],
    useFetcher<DeleteRecommendedActionMutation, DeleteRecommendedActionMutationVariables>(
      DeleteRecommendedActionDocument
    ),
    options
  );
export const DeleteRecommendedActionTemplatesDocument = `
    mutation deleteRecommendedActionTemplates($input: DeleteRecommendedActionTemplateInput!) {
  deleteRecommendedActionTemplates(input: $input)
}
    `;
export const useDeleteRecommendedActionTemplatesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteRecommendedActionTemplatesMutation,
    TError,
    DeleteRecommendedActionTemplatesMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteRecommendedActionTemplatesMutation,
    TError,
    DeleteRecommendedActionTemplatesMutationVariables,
    TContext
  >(
    ["deleteRecommendedActionTemplates"],
    useFetcher<DeleteRecommendedActionTemplatesMutation, DeleteRecommendedActionTemplatesMutationVariables>(
      DeleteRecommendedActionTemplatesDocument
    ),
    options
  );
export const GetCompanyNameFromRegnumberDocument = `
    query GetCompanyNameFromRegnumber($input: [String!]!) {
  resolveTenantsFromRegnumber(regnumbers: $input) {
    company
  }
}
    `;
export const useGetCompanyNameFromRegnumberQuery = <TData = GetCompanyNameFromRegnumberQuery, TError = unknown>(
  variables: GetCompanyNameFromRegnumberQueryVariables,
  options?: UseQueryOptions<GetCompanyNameFromRegnumberQuery, TError, TData>
) =>
  useQuery<GetCompanyNameFromRegnumberQuery, TError, TData>(
    ["GetCompanyNameFromRegnumber", variables],
    useFetcher<GetCompanyNameFromRegnumberQuery, GetCompanyNameFromRegnumberQueryVariables>(
      GetCompanyNameFromRegnumberDocument
    ).bind(null, variables),
    options
  );

useGetCompanyNameFromRegnumberQuery.getKey = (variables: GetCompanyNameFromRegnumberQueryVariables) => [
  "GetCompanyNameFromRegnumber",
  variables,
];
export const GetDashboardDataDocument = `
    query GetDashboardData($mavIds: [UUID!], $from: DateTime, $to: DateTime) {
  allStatistics(mavIds: $mavIds, from: $from, to: $to) {
    clientCount
    expertActivities
    protectionStatistics {
      blockedWebsites
      infectedFiles
      malwareMails
    }
    updateStatistics {
      clientUpdateCount
      signaturesUpdateCount
    }
  }
  securityStatus(mavIds: $mavIds) {
    recommendedActionsHigh
    recommendedActionsMedium
    recommendedActionsLow
  }
}
    `;
export const useGetDashboardDataQuery = <TData = GetDashboardDataQuery, TError = unknown>(
  variables?: GetDashboardDataQueryVariables,
  options?: UseQueryOptions<GetDashboardDataQuery, TError, TData>
) =>
  useQuery<GetDashboardDataQuery, TError, TData>(
    variables === undefined ? ["GetDashboardData"] : ["GetDashboardData", variables],
    useFetcher<GetDashboardDataQuery, GetDashboardDataQueryVariables>(GetDashboardDataDocument).bind(null, variables),
    options
  );

useGetDashboardDataQuery.getKey = (variables?: GetDashboardDataQueryVariables) =>
  variables === undefined ? ["GetDashboardData"] : ["GetDashboardData", variables];
export const GetInstallPackagesFromRegnumbersDocument = `
    query GetInstallPackagesFromRegnumbers {
  resolveTenantsFromRegnumber {
    installPackages {
      type
      downloadUrl
    }
  }
}
    `;
export const useGetInstallPackagesFromRegnumbersQuery = <
  TData = GetInstallPackagesFromRegnumbersQuery,
  TError = unknown
>(
  variables?: GetInstallPackagesFromRegnumbersQueryVariables,
  options?: UseQueryOptions<GetInstallPackagesFromRegnumbersQuery, TError, TData>
) =>
  useQuery<GetInstallPackagesFromRegnumbersQuery, TError, TData>(
    variables === undefined ? ["GetInstallPackagesFromRegnumbers"] : ["GetInstallPackagesFromRegnumbers", variables],
    useFetcher<GetInstallPackagesFromRegnumbersQuery, GetInstallPackagesFromRegnumbersQueryVariables>(
      GetInstallPackagesFromRegnumbersDocument
    ).bind(null, variables),
    options
  );

useGetInstallPackagesFromRegnumbersQuery.getKey = (variables?: GetInstallPackagesFromRegnumbersQueryVariables) =>
  variables === undefined ? ["GetInstallPackagesFromRegnumbers"] : ["GetInstallPackagesFromRegnumbers", variables];
export const GetMavIdFromRegnumberDocument = `
    query GetMavIdFromRegnumber($input: [String!]!) {
  resolveTenantsFromRegnumber(regnumbers: $input) {
    mavId
  }
}
    `;
export const useGetMavIdFromRegnumberQuery = <TData = GetMavIdFromRegnumberQuery, TError = unknown>(
  variables: GetMavIdFromRegnumberQueryVariables,
  options?: UseQueryOptions<GetMavIdFromRegnumberQuery, TError, TData>
) =>
  useQuery<GetMavIdFromRegnumberQuery, TError, TData>(
    ["GetMavIdFromRegnumber", variables],
    useFetcher<GetMavIdFromRegnumberQuery, GetMavIdFromRegnumberQueryVariables>(GetMavIdFromRegnumberDocument).bind(
      null,
      variables
    ),
    options
  );

useGetMavIdFromRegnumberQuery.getKey = (variables: GetMavIdFromRegnumberQueryVariables) => [
  "GetMavIdFromRegnumber",
  variables,
];
export const GetOwnUserDocument = `
    query GetOwnUser {
  selfUser {
    id
    firstName
    lastName
    eMail
    phone
    enabled
    company {
      name
    }
    settings {
      ...userSettings
    }
  }
}
    ${UserSettingsFragmentDoc}`;
export const useGetOwnUserQuery = <TData = GetOwnUserQuery, TError = unknown>(
  variables?: GetOwnUserQueryVariables,
  options?: UseQueryOptions<GetOwnUserQuery, TError, TData>
) =>
  useQuery<GetOwnUserQuery, TError, TData>(
    variables === undefined ? ["GetOwnUser"] : ["GetOwnUser", variables],
    useFetcher<GetOwnUserQuery, GetOwnUserQueryVariables>(GetOwnUserDocument).bind(null, variables),
    options
  );

useGetOwnUserQuery.getKey = (variables?: GetOwnUserQueryVariables) =>
  variables === undefined ? ["GetOwnUser"] : ["GetOwnUser", variables];
export const RecommendedActionTemplateDocument = `
    query RecommendedActionTemplate($id: UUID!) {
  recommendedActionTemplate(id: $id) {
    ...recommendedActionTemplate
  }
}
    ${RecommendedActionTemplateFragmentDoc}`;
export const useRecommendedActionTemplateQuery = <TData = RecommendedActionTemplateQuery, TError = unknown>(
  variables: RecommendedActionTemplateQueryVariables,
  options?: UseQueryOptions<RecommendedActionTemplateQuery, TError, TData>
) =>
  useQuery<RecommendedActionTemplateQuery, TError, TData>(
    ["RecommendedActionTemplate", variables],
    useFetcher<RecommendedActionTemplateQuery, RecommendedActionTemplateQueryVariables>(
      RecommendedActionTemplateDocument
    ).bind(null, variables),
    options
  );

useRecommendedActionTemplateQuery.getKey = (variables: RecommendedActionTemplateQueryVariables) => [
  "RecommendedActionTemplate",
  variables,
];
export const RecommendedActionTemplatesCountDocument = `
    query RecommendedActionTemplatesCount($where: RecommendedActionTemplateFilterInput, $order: [RecommendedActionTemplateSortInput!]) {
  recommendedActionTemplatesWithPaging(where: $where, order: $order) {
    totalCount
  }
}
    `;
export const useRecommendedActionTemplatesCountQuery = <TData = RecommendedActionTemplatesCountQuery, TError = unknown>(
  variables?: RecommendedActionTemplatesCountQueryVariables,
  options?: UseQueryOptions<RecommendedActionTemplatesCountQuery, TError, TData>
) =>
  useQuery<RecommendedActionTemplatesCountQuery, TError, TData>(
    variables === undefined ? ["RecommendedActionTemplatesCount"] : ["RecommendedActionTemplatesCount", variables],
    useFetcher<RecommendedActionTemplatesCountQuery, RecommendedActionTemplatesCountQueryVariables>(
      RecommendedActionTemplatesCountDocument
    ).bind(null, variables),
    options
  );

useRecommendedActionTemplatesCountQuery.getKey = (variables?: RecommendedActionTemplatesCountQueryVariables) =>
  variables === undefined ? ["RecommendedActionTemplatesCount"] : ["RecommendedActionTemplatesCount", variables];
export const RecommendedActionTemplatesWithPagingDocument = `
    query RecommendedActionTemplatesWithPaging($skip: Int, $take: Int, $where: RecommendedActionTemplateFilterInput, $order: [RecommendedActionTemplateSortInput!]) {
  recommendedActionTemplatesWithPaging(
    skip: $skip
    take: $take
    where: $where
    order: $order
  ) {
    items {
      ...recommendedActionTemplate
    }
  }
}
    ${RecommendedActionTemplateFragmentDoc}`;
export const useRecommendedActionTemplatesWithPagingQuery = <
  TData = RecommendedActionTemplatesWithPagingQuery,
  TError = unknown
>(
  variables?: RecommendedActionTemplatesWithPagingQueryVariables,
  options?: UseQueryOptions<RecommendedActionTemplatesWithPagingQuery, TError, TData>
) =>
  useQuery<RecommendedActionTemplatesWithPagingQuery, TError, TData>(
    variables === undefined
      ? ["RecommendedActionTemplatesWithPaging"]
      : ["RecommendedActionTemplatesWithPaging", variables],
    useFetcher<RecommendedActionTemplatesWithPagingQuery, RecommendedActionTemplatesWithPagingQueryVariables>(
      RecommendedActionTemplatesWithPagingDocument
    ).bind(null, variables),
    options
  );

useRecommendedActionTemplatesWithPagingQuery.getKey = (
  variables?: RecommendedActionTemplatesWithPagingQueryVariables
) =>
  variables === undefined
    ? ["RecommendedActionTemplatesWithPaging"]
    : ["RecommendedActionTemplatesWithPaging", variables];
export const RecommendedActionsDocument = `
    query RecommendedActions($skip: Int, $take: Int, $where: RecommendedActionFilterInput, $order: [RecommendedActionSortInput!]) {
  recommendedActionsWithPaging(
    skip: $skip
    take: $take
    where: $where
    order: $order
  ) {
    items {
      ...recommendedActionBase
    }
  }
}
    ${RecommendedActionBaseFragmentDoc}`;
export const useRecommendedActionsQuery = <TData = RecommendedActionsQuery, TError = unknown>(
  variables?: RecommendedActionsQueryVariables,
  options?: UseQueryOptions<RecommendedActionsQuery, TError, TData>
) =>
  useQuery<RecommendedActionsQuery, TError, TData>(
    variables === undefined ? ["RecommendedActions"] : ["RecommendedActions", variables],
    useFetcher<RecommendedActionsQuery, RecommendedActionsQueryVariables>(RecommendedActionsDocument).bind(
      null,
      variables
    ),
    options
  );

useRecommendedActionsQuery.getKey = (variables?: RecommendedActionsQueryVariables) =>
  variables === undefined ? ["RecommendedActions"] : ["RecommendedActions", variables];
export const RecommendedActionsAnalystDocument = `
    query RecommendedActionsAnalyst($skip: Int, $take: Int, $where: RecommendedActionFilterInput, $order: [RecommendedActionSortInput!]) {
  recommendedActionsWithPaging(
    skip: $skip
    take: $take
    where: $where
    order: $order
  ) {
    items {
      ...recommendedActionExtended
    }
  }
}
    ${RecommendedActionExtendedFragmentDoc}`;
export const useRecommendedActionsAnalystQuery = <TData = RecommendedActionsAnalystQuery, TError = unknown>(
  variables?: RecommendedActionsAnalystQueryVariables,
  options?: UseQueryOptions<RecommendedActionsAnalystQuery, TError, TData>
) =>
  useQuery<RecommendedActionsAnalystQuery, TError, TData>(
    variables === undefined ? ["RecommendedActionsAnalyst"] : ["RecommendedActionsAnalyst", variables],
    useFetcher<RecommendedActionsAnalystQuery, RecommendedActionsAnalystQueryVariables>(
      RecommendedActionsAnalystDocument
    ).bind(null, variables),
    options
  );

useRecommendedActionsAnalystQuery.getKey = (variables?: RecommendedActionsAnalystQueryVariables) =>
  variables === undefined ? ["RecommendedActionsAnalyst"] : ["RecommendedActionsAnalyst", variables];
export const RecommendedActionsCountDocument = `
    query RecommendedActionsCount($where: RecommendedActionFilterInput, $order: [RecommendedActionSortInput!]) {
  recommendedActionsWithPaging(where: $where, order: $order) {
    totalCount
  }
}
    `;
export const useRecommendedActionsCountQuery = <TData = RecommendedActionsCountQuery, TError = unknown>(
  variables?: RecommendedActionsCountQueryVariables,
  options?: UseQueryOptions<RecommendedActionsCountQuery, TError, TData>
) =>
  useQuery<RecommendedActionsCountQuery, TError, TData>(
    variables === undefined ? ["RecommendedActionsCount"] : ["RecommendedActionsCount", variables],
    useFetcher<RecommendedActionsCountQuery, RecommendedActionsCountQueryVariables>(
      RecommendedActionsCountDocument
    ).bind(null, variables),
    options
  );

useRecommendedActionsCountQuery.getKey = (variables?: RecommendedActionsCountQueryVariables) =>
  variables === undefined ? ["RecommendedActionsCount"] : ["RecommendedActionsCount", variables];
export const ResolveRecommendedActionDocument = `
    mutation ResolveRecommendedAction($recommendedActionId: UUID!) {
  resolveRecommendedAction(recommendedActionId: $recommendedActionId) {
    ...recommendedActionBase
  }
}
    ${RecommendedActionBaseFragmentDoc}`;
export const useResolveRecommendedActionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResolveRecommendedActionMutation,
    TError,
    ResolveRecommendedActionMutationVariables,
    TContext
  >
) =>
  useMutation<ResolveRecommendedActionMutation, TError, ResolveRecommendedActionMutationVariables, TContext>(
    ["ResolveRecommendedAction"],
    useFetcher<ResolveRecommendedActionMutation, ResolveRecommendedActionMutationVariables>(
      ResolveRecommendedActionDocument
    ),
    options
  );
export const UnresolveRecommendedActionDocument = `
    mutation UnresolveRecommendedAction($recommendedActionId: UUID!) {
  unresolveRecommendedAction(recommendedActionId: $recommendedActionId) {
    ...recommendedActionBase
  }
}
    ${RecommendedActionBaseFragmentDoc}`;
export const useUnresolveRecommendedActionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnresolveRecommendedActionMutation,
    TError,
    UnresolveRecommendedActionMutationVariables,
    TContext
  >
) =>
  useMutation<UnresolveRecommendedActionMutation, TError, UnresolveRecommendedActionMutationVariables, TContext>(
    ["UnresolveRecommendedAction"],
    useFetcher<UnresolveRecommendedActionMutation, UnresolveRecommendedActionMutationVariables>(
      UnresolveRecommendedActionDocument
    ),
    options
  );
export const UpdateOwnPasswordDocument = `
    mutation UpdateOwnPassword($oldPassword: String!, $newPassword: String!) {
  setNewPassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export const useUpdateOwnPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateOwnPasswordMutation, TError, UpdateOwnPasswordMutationVariables, TContext>
) =>
  useMutation<UpdateOwnPasswordMutation, TError, UpdateOwnPasswordMutationVariables, TContext>(
    ["UpdateOwnPassword"],
    useFetcher<UpdateOwnPasswordMutation, UpdateOwnPasswordMutationVariables>(UpdateOwnPasswordDocument),
    options
  );
export const UpdateOwnUserDocument = `
    mutation UpdateOwnUser($user: UpdateUserInput!) {
  updateUser(user: $user) {
    isUpdated
    user {
      id
      eMail
      firstName
      lastName
      phone
      enabled
      company {
        name
      }
    }
  }
}
    `;
export const useUpdateOwnUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateOwnUserMutation, TError, UpdateOwnUserMutationVariables, TContext>
) =>
  useMutation<UpdateOwnUserMutation, TError, UpdateOwnUserMutationVariables, TContext>(
    ["UpdateOwnUser"],
    useFetcher<UpdateOwnUserMutation, UpdateOwnUserMutationVariables>(UpdateOwnUserDocument),
    options
  );
export const UpdateRecommendedActionTemplateDocument = `
    mutation UpdateRecommendedActionTemplate($input: UpdateRecommendedActionTemplateInput!) {
  updateRecommendedActionTemplate(input: $input) {
    recommendedActionTemplate {
      ...recommendedActionTemplate
    }
  }
}
    ${RecommendedActionTemplateFragmentDoc}`;
export const useUpdateRecommendedActionTemplateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateRecommendedActionTemplateMutation,
    TError,
    UpdateRecommendedActionTemplateMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateRecommendedActionTemplateMutation,
    TError,
    UpdateRecommendedActionTemplateMutationVariables,
    TContext
  >(
    ["UpdateRecommendedActionTemplate"],
    useFetcher<UpdateRecommendedActionTemplateMutation, UpdateRecommendedActionTemplateMutationVariables>(
      UpdateRecommendedActionTemplateDocument
    ),
    options
  );
export const UserSettingDocument = `
    query UserSetting {
  userSettings {
    ...userSettings
  }
}
    ${UserSettingsFragmentDoc}`;
export const useUserSettingQuery = <TData = UserSettingQuery, TError = unknown>(
  variables?: UserSettingQueryVariables,
  options?: UseQueryOptions<UserSettingQuery, TError, TData>
) =>
  useQuery<UserSettingQuery, TError, TData>(
    variables === undefined ? ["UserSetting"] : ["UserSetting", variables],
    useFetcher<UserSettingQuery, UserSettingQueryVariables>(UserSettingDocument).bind(null, variables),
    options
  );

useUserSettingQuery.getKey = (variables?: UserSettingQueryVariables) =>
  variables === undefined ? ["UserSetting"] : ["UserSetting", variables];
