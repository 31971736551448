import { TFunction } from "i18next";
import * as Yup from "yup";
import { SchemaOf } from "yup";

export type RegisterFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  phone: string;
};

export const getValidationSchema = (t: TFunction): SchemaOf<RegisterFormValues> => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t("Forms.ValidEmail", "Please enter a valid e-mail address."))
      .max(
        255,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.UsernameOrMail", "Username"),
        })
      ),
    firstName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.FirstName", "First name"),
        })
      ),
    lastName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.LastName", "Last name"),
        })
      ),
    phone: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          defaultValue: "Can be max. {{maxLength}} characters long.",
          maxLength: "50",
        })
      )
      .notRequired()
      .default(""),
    password: Yup.string()
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.Password"),
        })
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W+]).{8,255}$/,
        t(
          "Forms.PasswordInvalid",
          "Must be at least 8 characters long and contain one lowercase, one uppercase letter, one special character and one number."
        )
      ),
    passwordConfirm: Yup.string()
      .required(t("Forms.PasswordsNotEqual"))
      .oneOf([Yup.ref("password")], t("Forms.PasswordsNotEqual")),
  });
};
