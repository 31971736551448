import type { FC } from "react";
import { Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title, subtitle }) => {
  if (subtitle) {
    return (
      <>
        <Typography color="textPrimary" variant="overline" height=".875rem" display="inline-block">
          {subtitle}
        </Typography>
        <Typography color="textPrimary" component="h2" variant="h4" mb={4}>
          {title}
        </Typography>
      </>
    );
  }
  return (
    <Typography color="textPrimary" component="h2" variant="h4" mb={4}>
      {title}
    </Typography>
  );
};

export default PageTitle;
