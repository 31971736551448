import * as Yup from "yup";
import { SchemaOf } from "yup";
import { fallbackLocale, mapSupportedLocaleToLanguage } from "../../../locales";

const getValidationSchema = (): SchemaOf<unknown> => {
  return Yup.object()
    .shape({
      name: Yup.string().trim().required(),
      cultureSpecifics: Yup.array().of(
        Yup.object()
          .shape({
            summary: Yup.string()
              .trim()
              .when("language", {
                is: mapSupportedLocaleToLanguage(fallbackLocale),
                then: Yup.string().trim().required(),
              }),
            description: Yup.string()
              .trim()
              .when("language", {
                is: mapSupportedLocaleToLanguage(fallbackLocale),
                then: Yup.string().trim().required(),
              }),
          })
          .noUnknown(false)
      ),
    })
    .noUnknown(false);
};

export default getValidationSchema;
