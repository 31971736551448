import { FC } from "react";
import { useTranslation } from "react-i18next";
import DashboardWidget from "../components/DashboardWidget";
import { StyledRecommendedActionsTableRoot } from "../../recommendedActions/components/RecommendedActionsTableStyles";
import RecommendedActionsTable from "../../recommendedActions/components/RecommendedActionsTable";
import {
  RecommendedActionSortInput,
  SortEnumType,
  RecommendedActionState,
} from "../../../graphql/generated/generatedGraphQL";
import useTableFilter, {
  initialRecommendedActionFilterState,
  IRecommendedActionTableFilterState,
} from "../../recommendedActionsAnalyst/hooks/useTableFilter";
import AllRecommendedActionsSolved from "../components/AllRecommendedActionsSolved";
import { recommendedActionTableColumns } from "../components/RecommendedActionWidgetColumns";
import useTablePagination from "../../../components/table/useTablePagination";
import useRecommendedActionsQuery from "../../recommendedActions/hooks/useRecommendedActionsQuery";
import InRowMenuItems from "../components/InRowMenuItems";
const defaultSortOrder: RecommendedActionSortInput[] = [
  { state: SortEnumType.Asc },
  { priority: SortEnumType.Desc },
  { createdAt: SortEnumType.Desc },
];

const defaultFilterState: IRecommendedActionTableFilterState = {
  ...initialRecommendedActionFilterState,
  whereState: {
    [RecommendedActionState.None]: false,
    [RecommendedActionState.Unsolved]: true,
    [RecommendedActionState.Solved]: false,
  },
};

/**
 * Displays the 9 most urgent recommendations.
 */
const RecommendationsWidget: FC = () => {
  const { t } = useTranslation();
  const filter = useTableFilter(defaultFilterState);
  const pageSize = 10;
  const pagination = useTablePagination(pageSize, pageSize);
  const queryResult = useRecommendedActionsQuery({
    skip: pagination.pageIndex * pageSize,
    take: pageSize,
    where: filter.graphQLFilterInput,
    order: defaultSortOrder,
  });
  return (
    <DashboardWidget
      size="2x2"
      mobileRowNumber={2}
      title={t("Sidebar.RecommendedActions")}
      subtitle={t("RecommendedActions.RecommendedActionsDescription", "Urgent messages that require your involvement.")}
      link={{
        title: t("Dashboard.ShowAllRecommendedActions", "Show all"),
        to: "/recommendedActions",
      }}
      noContentCentering
    >
      <StyledRecommendedActionsTableRoot>
        <RecommendedActionsTable
          onSortByChanged={() => {}}
          filter={filter}
          order={defaultSortOrder}
          columns={recommendedActionTableColumns}
          InRowMenuItems={InRowMenuItems}
          NoResultComponent={AllRecommendedActionsSolved}
          queryResult={queryResult}
          pagination={pagination}
        />
      </StyledRecommendedActionsTableRoot>
    </DashboardWidget>
  );
};

export default RecommendationsWidget;
