import { useMemo } from "react";
import { Column } from "react-table";
import useComponentBehavior from "../../../hooks/useComponentBehavior";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import {
  priorityColumn,
  clientColumn,
  recommendedActionColumn,
  expanderColumn,
  createdAtColumn,
  tenantColumn,
} from "../components/recommendedActionTableColumns";

export default function useRecommendedActionsTableColumns(): Column<RecommendedActionViewModel>[] {
  const behavior = useComponentBehavior();
  return useMemo(() => {
    const columns = [priorityColumn];
    if (behavior.pages.recommendedActions.showTenantColumn) {
      columns.push(tenantColumn);
    }
    columns.push(clientColumn, recommendedActionColumn, expanderColumn, createdAtColumn);
    return columns;
  }, [behavior.pages.recommendedActions.showTenantColumn]);
}
