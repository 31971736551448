import { StrictMode } from "react";
import ReactDOM from "react-dom";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import "./applicationInsights";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LocalSettingsContainer from "./hooks/LocalSettingsContainer";
import BrowserRouter from "./components/BrowserRouter";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // default is 5min, set to 0 for testing
      // cacheTime: 5 * 60 * 1000,
      // our default is 60s, set to 0 for testing
      staleTime: 60 * 1000,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LocalSettingsContainer.Provider>
            <App />
          </LocalSettingsContainer.Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
