import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MacGconIcon } from "../../../icons/GconIcon";
import { ClientDownloadCardClasses } from "./ClientDownloadCard";
import RequestClientDownloadCard from "./RequestClientDownloadCard";

const MacClientDownloadCard: FC<{ downloadUrl?: string }> = ({ downloadUrl }) => {
  const { t } = useTranslation();
  // TODO: replace with ClientDownloadCard component
  return (
    <RequestClientDownloadCard
      title="Mac"
      subtitle={t("Clients.Mac.DownloadSubtitle")}
      icon={<MacGconIcon className={ClientDownloadCardClasses.icon} />}
      description={t("Clients.Mac.DownloadDescription")}
      downloadUrl={downloadUrl}
    />
  );
};

export default MacClientDownloadCard;
