import { Collapse, styled, Typography } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Column, Row } from "react-table";
import { RecommendedActionViewModel } from "../../types/RecommendedAction";
import {
  collapsedHeight,
  PriorityIcon,
  recommendedActionsTableClasses,
} from "../recommendedActions/components/RecommendedActionsTableStyles";
import {
  clientColumn,
  DateCell,
  expanderColumn,
  RecommendedActionHeader,
  tenantColumn,
} from "../recommendedActions/components/recommendedActionTableColumns";
import setCanExpand from "../recommendedActions/components/setCanExpand";

const RecommendedActionAnalystCellStyle = styled("div", { name: "RecommendedActionCell" })(() => ({
  display: "flex",
  alignItems: "baseline",
}));

const RecommendedActionAnalystPriorityIconStyle = styled("div", { name: "PriorityIcon" })(() => ({
  marginRight: "10px",
  alignSelf: "flex-start",
}));

const NoWrap = styled("div", { target: "NoWrap" })({ whiteSpace: "nowrap" });

const CreatedHeader: VFC = () => {
  const { t } = useTranslation();
  return t("RecommendedActions.Created", "Created");
};

const CreatedCell = ({ row }: { row: Row<RecommendedActionViewModel> }) => {
  const createdAt = row.original.createdAt;
  if (!createdAt) {
    return null;
  }
  return (
    <>
      <NoWrap>
        <DateCell value={createdAt} />
      </NoWrap>
      {row.original.createdByUserFullName}
    </>
  );
};

const createdColumn: Column<RecommendedActionViewModel> = {
  Header: <CreatedHeader />,
  accessor: (original) => original,
  id: "createdAt",
  Cell: CreatedCell,
};

const ResolvedHeader: VFC = () => {
  const { t } = useTranslation();
  return t("RecommendedActions.Resolved", "Closed");
};

const ResolvedCell = ({ row }: { row: Row<RecommendedActionViewModel> }) => {
  const resolvedAt = row.original.resolvedAt;
  if (!resolvedAt) {
    return null;
  }
  return (
    <>
      <NoWrap>
        <DateCell value={resolvedAt} />
      </NoWrap>
      {row.original.resolvedByUserFullName}
    </>
  );
};

const resolvedColumn: Column<RecommendedActionViewModel> = {
  Header: <ResolvedHeader />,
  accessor: (original) => original,
  id: "resolvedAt",
  Cell: ResolvedCell,
  style: { minWidth: "150px" },
};

const RecommendedActionCell = ({ row }: { row: Row<RecommendedActionViewModel> }): JSX.Element => {
  const value = row.original;
  return (
    <RecommendedActionAnalystCellStyle>
      <RecommendedActionAnalystPriorityIconStyle>
        <PriorityIcon value={value.priority} />
      </RecommendedActionAnalystPriorityIconStyle>
      <Collapse
        in={row.isExpanded}
        collapsedSize={`${collapsedHeight}px`}
        className={recommendedActionsTableClasses.collapse}
      >
        <div
          ref={(el) => {
            if (!el) return;
            setCanExpand(row, el.scrollHeight > collapsedHeight);
          }}
        >
          <Typography variant="body2">{value.summary}</Typography>
          <ReactMarkdown
            className={recommendedActionsTableClasses.markdown}
            components={{
              a: ({ children, ...rest }) => (
                <a
                  target="_blank"
                  {...rest}
                  onClick={(e) => e.stopPropagation()}
                  className={recommendedActionsTableClasses.link}
                >
                  {children}
                </a>
              ),
            }}
          >
            {value.description || ""}
          </ReactMarkdown>
        </div>
      </Collapse>
    </RecommendedActionAnalystCellStyle>
  );
};

const recommendedActionColumn: Column<RecommendedActionViewModel> = {
  Header: <RecommendedActionHeader />,
  id: "recommendedAction",
  Cell: RecommendedActionCell,
  disableSortBy: true,
  style: { width: "100%" },
};

export const RecommendedActionsForAnalystsTableColumns: Column<RecommendedActionViewModel>[] = [
  tenantColumn,
  clientColumn,
  recommendedActionColumn,
  expanderColumn,
  createdColumn,
  resolvedColumn,
];
