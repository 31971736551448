import { Container } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledFlexPaper,
  StyledTableDefaultMessageSubtitle,
  StyledTableDefaultMessageTitle,
} from "../theme/sharedStyles";

const Maintainance: VFC = () => {
  const { t } = useTranslation();

  return (
    <StyledFlexPaper>
      <Container>
        <StyledTableDefaultMessageTitle variant="h3">{t("Maintainance.Title")}</StyledTableDefaultMessageTitle>
        <StyledTableDefaultMessageSubtitle variant="h4">{t("Maintainance.SubTitle")}</StyledTableDefaultMessageSubtitle>
      </Container>
    </StyledFlexPaper>
  );
};

export default Maintainance;
