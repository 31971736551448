// Based on https://github.com/jamiebuilds/unstated-next

import React from "react";

const EMPTY: unique symbol = Symbol("EMPTY");

export interface ContainerProviderProps<Value = void> {
  value: Value;
  children: React.ReactNode;
}

export interface Container<Value> {
  Provider: React.ComponentType<ContainerProviderProps<Value>>;
  useContainer: () => Value;
}

export function createContainer<Value>(): Container<Value> {
  const context = React.createContext<Value | typeof EMPTY>(EMPTY);

  function Provider({ value, children }: ContainerProviderProps<Value>) {
    return <context.Provider value={value}>{children}</context.Provider>;
  }

  function useContainer(): Value {
    const value = React.useContext(context);
    if (value === EMPTY) {
      throw new Error("Component must be wrapped with <Container.Provider>");
    }
    return value;
  }

  return { Provider, useContainer };
}
