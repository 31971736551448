import { useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import { getLanguage, SupportedLocale } from "../locales";
import { styled } from "@mui/material/styles";

type LanguageOption = {
  icon: string;
  label: string;
};

export const languageOptions: Record<SupportedLocale, LanguageOption> = {
  en: {
    icon: "/static/icons/uk_flag.svg",
    label: "English",
  },
  de: {
    icon: "/static/icons/de_flag.svg",
    label: "Deutsch",
  },
  pl: {
    icon: "static/icons/pl_flag.svg",
    label: "Polish",
  },
};

const StyledBox = styled(Box)(() => ({
  display: "flex",
  height: 20,
  width: 20,
  "& img": {
    width: "100%",
  },
}));

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  const selectedOption = languageOptions[getLanguage(i18n)];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <StyledBox>
          <img alt={selectedOption.label} src={selectedOption.icon} />
        </StyledBox>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {(Object.keys(languageOptions) as SupportedLocale[]).map((language) => (
          <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
            <ListItemIcon>
              <StyledBox>
                <img alt={languageOptions[language].label} src={languageOptions[language].icon} />
              </StyledBox>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
