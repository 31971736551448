import { Button, Card, CardActions, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import GetAppIcon from "@mui/icons-material/GetApp";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

const PREFIX = "ClientDownloadCard";
export const ClientDownloadCardClasses = {
  icon: `${PREFIX}-icon`,
  card: `${PREFIX}-card`,
};
export const StyledClientDownloadCard = styled(Card)(() => ({
  maxWidth: "256px",
  minWidth: "200px",
  display: "flex",
  flexDirection: "column",
  "& .MuiCardActions-root a": {
    margin: "0 auto",
    width: "192px",
    height: "42px",
  },
  [`& .${ClientDownloadCardClasses.icon}`]: {
    display: "block",
    fontSize: "100px",
    textAlign: "center",
    margin: "0 auto",
  },
}));

const DownloadButton: FC<{ url?: string }> = ({ url }) => {
  const { t } = useTranslation();
  return (
    <>
      {url ? (
        <Button startIcon={<GetAppIcon />} variant="contained" href={`${url}`}>
          {t("Clients.Download")}
        </Button>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Tooltip title={t("Clients.PackagePreparation")!}>
          <span>
            <Button disabled startIcon={<GetAppIcon />} variant="contained" href={`${url}`}>
              {t("Clients.Download")}
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

interface ClientDownloadCardProps {
  title: string;
  subtitle: string;
  icon: ReactNode;
  description: string;
  downloadUrl: string | undefined;
}

const ClientDownloadCard: FC<ClientDownloadCardProps> = ({ title, subtitle, icon, description, downloadUrl }) => {
  return (
    <StyledClientDownloadCard>
      <CardHeader sx={{ pt: 2, pb: 0 }} title={title} titleTypographyProps={{ variant: "h3", align: "center" }} />
      <CardContent sx={{ p: 4, pt: 1 }}>
        {icon}
        <Typography align="center" variant="body2" fontWeight={700} sx={{ pt: 4 }}>
          {subtitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ pt: 2 }} style={{ whiteSpace: "pre-line" }}>
          {description}
        </Typography>
      </CardContent>
      <div style={{ flexGrow: 1 }} />
      <CardActions sx={{ p: 4, pt: 0 }}>
        <DownloadButton url={downloadUrl} />
      </CardActions>
    </StyledClientDownloadCard>
  );
};

export default ClientDownloadCard;
