import type { FC } from "react";
import { Box, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  "& .MuiListItem-root": {
    padding: 0,
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  "& .MuiListItemText-root": {
    margin: "2px 0",
  },
  "& svg": {
    fontSize: ".875rem",
  },
  "& .MuiLink-root": {
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: 300,
  },
}));

const ContactBox: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <Typography variant="body1">{t("Sidebar.Your365Team")}</Typography>
      <List disablePadding dense>
        <ListItem disableGutters key="ds-1">
          <ListItemIcon>
            <CallIcon />
          </ListItemIcon>
          <ListItemText primary={<Link href={t("Footer.PhoneNumberHref")}>{t("Footer.PhoneNumber")}</Link>} />
        </ListItem>
        <ListItem disableGutters key="ds-2">
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={<Link href={t("Footer.MailHref")}>{t("Footer.Mail")}</Link>} />
        </ListItem>
      </List>
    </StyledBox>
  );
};

export default ContactBox;
