import { FC } from "react";
import { IContentProps } from "../../../components/Page";
import { GetDashboardDataQuery } from "../../../graphql/generated/generatedGraphQL";
import DeviceWidget from "../widgets/DeviceWidget";
import ProtectionStatisticsWidget from "../widgets/ProtectionStatisticsWidget";
import RecommendationsWidget from "../widgets/RecommendationsWidget";
import SecurityStatusWidget from "../widgets/SecurityStatusWidget";
import ServiceOverviewWidget from "../widgets/ServiceOverviewWidget";
import DashboardGrid from "./DashboardGrid";

const DashboardGridContent: FC<IContentProps<GetDashboardDataQuery>> = ({ data }) => {
  return (
    <>
      {" "}
      <DashboardGrid>
        <SecurityStatusWidget data={data} />
        <DeviceWidget clientsCount={data.allStatistics?.clientCount} />
        <ProtectionStatisticsWidget protectionStatistics={data.allStatistics?.protectionStatistics} />
        <RecommendationsWidget />
        <ServiceOverviewWidget data={data} />
      </DashboardGrid>
    </>
  );
};

export default DashboardGridContent;
