import type { FC, VFC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import PageTitle from "../../components/PageTitle";
import { StyledRecommendedActionsTableRoot } from "./components/RecommendedActionsTableStyles";
import { StyledPaper } from "../../theme/sharedStyles";
import useTableFilter from "../recommendedActionsAnalyst/hooks/useTableFilter";
import useTableSortBy from "../../components/table/useTableSortBy";
import { columnsToGraphQLFields } from "../recommendedActionsAnalyst/components/RecommendedActionsForAnalystsIndexContent";
import { RecommendedActionSortInput, SortEnumType } from "../../graphql/generated/generatedGraphQL";
import useRecommendedActionsCountQuery from "./hooks/useRecommendedActionsCountQuery";
import useRecommendedActionsQuery from "./hooks/useRecommendedActionsQuery";
import useRecommendedActionsTableColumns from "./hooks/useRecommendedActionsTableColumns";
import NoRecommendedActions from "./components/NoRecommendedActions";
import RecommendedActionsTable from "./components/RecommendedActionsTable";
import useTablePagination from "../../components/table/useTablePagination";
import InRowMenuItems from "./components/InRowMenuItems";

const Title: FC = () => {
  const { t } = useTranslation();

  return <PageTitle title={`${t("Sidebar.MyOverview")} > ${t("Sidebar.RecommendedActions")}`} />;
};

export const pageSize = 25;

const defaultSortOrder: RecommendedActionSortInput[] = [
  { state: SortEnumType.Asc },
  { priority: SortEnumType.Desc },
  { createdAt: SortEnumType.Desc },
];

const Content: VFC = () => {
  const filter = useTableFilter();
  const { order, onSortByChanged } = useTableSortBy<RecommendedActionSortInput>(
    columnsToGraphQLFields,
    defaultSortOrder
  );
  const countResult = useRecommendedActionsCountQuery({
    where: filter.graphQLFilterInput,
    order,
  });
  const pagination = useTablePagination(countResult.data?.recommendedActionsWithPaging?.totalCount || 0, pageSize);
  const queryResult = useRecommendedActionsQuery({
    skip: pagination.pageIndex * pageSize,
    take: pageSize,
    where: filter.graphQLFilterInput,
    order,
  });
  const columns = useRecommendedActionsTableColumns();
  return (
    <StyledPaper>
      <RecommendedActionsTable
        onSortByChanged={onSortByChanged}
        NoResultComponent={NoRecommendedActions}
        order={order}
        columns={columns}
        filter={filter}
        InRowMenuItems={InRowMenuItems}
        queryResult={queryResult}
        pagination={pagination}
      />
    </StyledPaper>
  );
};

const RecommendedActionsOverview: FC = () => {
  return (
    <StyledRecommendedActionsTableRoot>
      <Page Title={Title} Content={Content} queryResults={{}} />
    </StyledRecommendedActionsTableRoot>
  );
};

export default RecommendedActionsOverview;
