import { MenuItem } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionTemplateViewModel } from "../../../types/RecommendedActionTemplate";

type EditProps = ActionMenuItemsProps<RecommendedActionTemplateViewModel>;

const Edit = ({ selectedFlatRows, onClose }: EditProps): ReturnType<VFC> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const disabled = selectedFlatRows.length !== 1 || !selectedFlatRows[0].original.accessRights.canEdit;
  return (
    <MenuItem
      data-testid="recommendedActionTemplates-EditButton"
      key={"EnhancedTable.Edit"}
      disabled={disabled}
      onClick={() => {
        onClose();
        if (selectedFlatRows.length) {
          navigate(selectedFlatRows[0].original.id);
        }
      }}
    >
      {t("EnhancedTable.Edit")}
    </MenuItem>
  );
};

export default Edit;
