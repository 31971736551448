import type { FC } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "9px",
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.divider,
  "& span": {
    backgroundColor: theme.palette.error.main,
  },
  "&[data-strength^='6'], &[data-strength^='7'], &[data-strength^='8']": {
    "& span": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  "&[data-strength^='9'], &[data-strength='100']": {
    "& span": {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

interface PasswordStrengthMeterProps {
  password: string;
}

function checkPasswordStrength(password: string) {
  let strength = 0;
  // has number
  if (/[0-9]/.test(password)) {
    strength += 20;
  }
  // has lowercase
  if (/[a-z]/.test(password)) {
    strength += 20;
  }
  // has uppercase
  if (/[A-Z]/.test(password)) {
    strength += 20;
  }
  // has special char
  if (/[\W+]/.test(password)) {
    strength += 20;
  }
  if (password.length > 7) {
    strength += 10;
  }
  if (password.length > 11) {
    strength += 10;
  }
  return strength;
}

/**
  Colored progress bar that shows the password strength. It is not about "real"
  password strength, but about whether the password meets our requirement.
  Minimum requirements reached = 90% and green.
 */
const PasswordStrengthMeter: FC<PasswordStrengthMeterProps> = ({ password }) => {
  const { t } = useTranslation();
  const passwordStrength = checkPasswordStrength(password);

  return (
    <Box pb={2} pt={2}>
      <Typography color="textSecondary" fontSize="0.75rem" align="center" mb={2}>
        {t(
          "Forms.PasswordRules",
          "A secure password should be at least eight characters long and contain upper & lower case letters, numbers as well as special characters."
        )}
      </Typography>
      <StyledLinearProgress data-strength={passwordStrength} variant="determinate" value={passwordStrength} />
    </Box>
  );
};

export default PasswordStrengthMeter;
