import { MenuItem } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ActionMenuItemsProps } from "../../../components/table/TableActionMenu";
import { RecommendedActionViewModel } from "../../../types/RecommendedAction";
import useDeleteRecommendedActions from "../hooks/useDeleteRecommendedActions";

type ResolveProps = ActionMenuItemsProps<RecommendedActionViewModel>;

const Resolve = ({ selectedFlatRows, onClose }: ResolveProps): ReturnType<VFC> => {
  const { t } = useTranslation();
  const deleteRecommendedActions = useDeleteRecommendedActions();
  const disabled = selectedFlatRows.length === 0;
  return (
    <MenuItem
      data-testid="recommendedActions-DeleteButton"
      key={"EnhancedTable.Delete"}
      disabled={disabled}
      onClick={() => {
        onClose();
        if (selectedFlatRows.length) {
          deleteRecommendedActions(selectedFlatRows.map((r) => r.original.id));
        }
      }}
    >
      {t("EnhancedTable.Delete")}
    </MenuItem>
  );
};

export default Resolve;
