import { FC, useMemo, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Divider, Drawer, styled } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlarmIcon from "@mui/icons-material/Alarm";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { FindInPage, FolderOpen, Group, ContentPaste } from "@mui/icons-material";
import Logo from "../../Logo";
import NavSection from "../../NavSection";
import Scrollbar from "../../Scrollbar";
import { AppTitleWithPlan } from "../../AppTitle";
import ContactBox from "./ContactBox";
import useComponentBehavior from "../../../hooks/useComponentBehavior";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

function useSections() {
  const { t } = useTranslation();
  const behavior = useComponentBehavior();

  return useMemo(() => {
    const overviewGroup = [
      {
        title: t("Sidebar.SecurityDashboard"),
        path: "/dashboard",
        icon: <DashboardIcon />,
        dataTestid: "DashboardSidebar-securityDashboard",
      },
    ];
    if (behavior.DashboardSidebar.showClients) {
      overviewGroup.push({
        title: t("Sidebar.DeviceManagement"),
        path: "/clients",
        icon: <BusinessIcon />,
        dataTestid: "DashboardSidebar-deviceManagement",
      });
    }
    if (behavior.DashboardSidebar.showRecommendedActions) {
      overviewGroup.push({
        title: t("Sidebar.RecommendedActions"),
        path: "/recommendedactions",
        icon: <AlarmIcon />,
        dataTestid: "DashboardSidebar-recommendedActions",
      });
    }
    const sections = [
      {
        title: t("Sidebar.MyOverview"),
        icon: <HomeIcon />,
        items: overviewGroup,
      },
    ];
    if (behavior.DashboardSidebar.showAnalysisGroup) {
      sections.push({
        title: t("Sidebar.AnalysisAndAdministration"),
        icon: <FindInPage />,
        items: [
          {
            title: t("Sidebar.RecommendedActionsForAnalysts"),
            path: "/analysis/recommendedActions",
            icon: <Group />,
            dataTestid: "DashboardSidebar-recommendedActionsAnalyst",
          },
          {
            title: t("Sidebar.ReportsOverview", "Reports"),
            path: "/analysis/findings",
            icon: <ContentPaste />,
            dataTestid: "DashboardSidebar-findingsOverview",
          },
          {
            title: t("Sidebar.TemplatesOverview"),
            path: "/analysis/recommendedActionTemplates",
            icon: <FolderOpen />,
            dataTestid: "DashboardSidebar-templatesOverview",
          },
        ],
      });
    }
    if (behavior.DashboardSidebar.showSettingsGroup) {
      sections.push({
        title: t("Sidebar.Settings"),
        icon: <SettingsIcon />,
        items: [
          {
            title: t("Sidebar.Account"),
            path: "/settings/account",
            icon: <AccountCircleIcon />,
            dataTestid: "DashboardSidebar-account",
          },
          {
            title: t("Sidebar.Security"),
            path: "/settings/security",
            icon: <LockOutlinedIcon />,
            dataTestid: "DashboardSidebar-security",
          },
        ],
      });
    }
    return sections;
  }, [t, behavior]);
}

const DesktopSidebarBox = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));

const MobileSidebarBox = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("xs")]: {
    display: "block",
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const AppTitleContentBox = styled(Box)(({ theme }) => ({
  padding: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const NavSectionBox = styled(Box)(() => ({
  padding: 12,
  paddingTop: 26,
}));

const MobileNavSectionBox = styled(Box)(() => ({
  overflowX: "auto",
  position: "fixed",
  bottom: 90,
  height: "calc(100% - 90px) !important",
  width: 280,
}));

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    onMobileClose();
  }, [location.pathname, onMobileClose]);

  const sections = useSections();
  const behavior = useComponentBehavior();
  const content = (
    <ContentBox>
      <AppTitleContentBox>
        <RouterLink to="/">
          <Logo width="30px" />
        </RouterLink>
        <AppTitleWithPlan typography={{ align: "center" }} />
      </AppTitleContentBox>
      <Divider />
      <NavSectionBox>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </NavSectionBox>
    </ContentBox>
  );

  return (
    <>
      <MobileSidebarBox>
        <Drawer
          anchor="left"
          onClick={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          <MobileNavSectionBox sx={{ paddingTop: behavior.ResellerHeader.showResellerHeader ? "48px" : "0px" }}>
            <Scrollbar>{content}</Scrollbar>
          </MobileNavSectionBox>
          <Box sx={{ position: "fixed", bottom: 0, width: 280 }}>
            <Divider />
            <ContactBox />
          </Box>
        </Drawer>
      </MobileSidebarBox>
      <DesktopSidebarBox>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 256,
              paddingTop: behavior.ResellerHeader.showResellerHeader ? "48px" : "0px",
            },
          }}
          variant="persistent"
        >
          <ContactBox />
          <Scrollbar>{content}</Scrollbar>
        </Drawer>
      </DesktopSidebarBox>
    </>
  );
};

export default DashboardSidebar;
